<template>
  <v-dialog v-model="show" max-width="568">
    <v-card>
      <v-card-title class="headline">Notifications Denied</v-card-title>

      <v-card-text align="start">
        You have denied notification permissions. To receive updates, please
        enable notifications in your browser settings
        <ul style="list-style-type: decimal;">
          <li>Go to your browser settings.</li>
          <li>
            Navigate to Privacy and Security > Site Settings > Notifications.
          </li>
          <li>Find this site and allow notifications.</li>
        </ul>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click.stop="show = false">
          CLOSE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
