import Vue from "vue"
import VueRouter from "vue-router"

import Setting from "../views/Setting/Setting.vue"
import Dashboard from "../views/Dashboard.vue"

import OrderManagement from "../views/Order/OrderTableViewForm.vue"
import OrderViewForm from "../views/Order/OrderViewForm.vue"
import EditOrderStatusForm from "../views/Order/EditOrderStatusForm.vue"

import ProductManagement from "../views/Product/ProductTableViewForm.vue"
import ProductViewForm from "../views/Product/ProductViewForm.vue"
import ProductCreateForm from "../views/Product/ProductCreateForm.vue"
import ProductEditForm from "../views/Product/ProductEditForm.vue"

import PostTableViewForm from "../views/Post/PostTableViewForm.vue"
import PostViewForm from "../views/Post/PostViewForm.vue"

import VoucherManagement from "../views/Voucher/VoucherTableViewForm.vue"
import VoucherViewForm from "../views/Voucher/VoucherViewForm.vue"
import VoucherCreateForm from "../views/Voucher/VoucherCreateForm.vue"
import VoucherEditForm from "../views/Voucher/VoucherEditForm.vue"
import VoucherCustomer from ".././components/Customer/VoucherCustomer.vue"

import NewsManagement from "../views/News/NewsTableViewForm.vue"
import NewsViewForm from "../views/News/NewsViewForm.vue"
import NewsCreateForm from "../views/News/NewsCreateForm.vue"
import NewsEditForm from "../views/News/NewsEditForm.vue"
import RewardManagement from "../views/Reward/RewardTableViewForm.vue"

import CustomerRewardAccountManagement from "../views/Customer/CustomerRewardTableViewForm.vue"

import SettingInterface from "../views/Setting/Setting.vue"
import SettingEditForm from "../views/Setting/SettingEditForm.vue"

import NotFound from "../views/NotFound.vue"
import XeroAuthorizeSuccess from "../views/XeroAuthorizeSuccess.vue"

import { Trans } from "@/plugins/Translation"
import MainCategory from "../components/Category/MainCategory.vue"
import CategoryDetail from "../components/Category/Detail.vue"
import CreateCategory from "../components/Category/Create.vue"
import SubCategory from "../components/Category/SubCategory.vue"
import EditCategory from "../components/Category/Edit.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    redirect() {
      return Trans.defaultLocale
    },
  },
  {
    path: "/:locale",
    component: {
      template: "<router-view></router-view>",
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/:locale/:userType/setting",
        name: "Setting",
        component: Setting,
      },
      // ---------------------- Order Management ----------------------------
      // --------------------------------------------------------------------
      {
        path: "/:locale/:userType/order",
        name: "Show Order Table",
        component: OrderManagement,
      },
      {
        path: "/:locale/:userType/order/view/:orderId",
        name: "View Order",
        component: OrderViewForm,
      },
      {
        path: "/:locale/:userType/order/edit/:orderId",
        name: "Edit Order Status",
        component: EditOrderStatusForm,
      },
      // ---------------------- Product Management --------------------------
      // --------------------------------------------------------------------
      {
        path: "/:locale/:userType/product",
        name: "Show Product Table",
        component: ProductManagement,
      },
      {
        path: "/:locale/:userType/product/view/:productId",
        name: "View Product",
        component: ProductViewForm,
      },
      {
        path: "/:locale/:userType/product/create",
        name: "Create Product",
        component: ProductCreateForm,
      },
      {
        path: "/:locale/:userType/product/edit/:productId",
        name: "Edit Product",
        component: ProductEditForm,
      },
      // ---------------------- Post Management --------------------------
      // --------------------------------------------------------------------
      {
        path: "/:locale/:userType/post",
        name: "Show Post Table",
        component: PostTableViewForm,
      },
      {
        path: "/:locale/:userType/post/view/:postId",
        name: "View Post",
        component: PostViewForm,
      },
      // ---------------------- News Management --------------------------
      // --------------------------------------------------------------------
      {
        path: "/:locale/:userType/news",
        name: "Show News Table",
        component: NewsManagement,
      },
      {
        path: "/:locale/:userType/news/view/:newsId",
        name: "View News",
        component: NewsViewForm,
      },
      {
        path: "/:locale/:userType/news/create",
        name: "Create News",
        component: NewsCreateForm,
      },
      {
        path: "/:locale/:userType/news/edit/:newsId",
        name: "Edit News",
        component: NewsEditForm,
      },
      // ---------------------- Voucher Management --------------------------
      // --------------------------------------------------------------------
      {
        path: "/:locale/:userType/voucher",
        name: "Show Voucher Table",
        component: VoucherManagement,
      },
      {
        path: "/:locale/:userType/voucher/view/:voucherId",
        name: "View Voucher",
        component: VoucherViewForm,
      },
      {
        path: "/:locale/:userType/voucher/create",
        name: "Create Voucher",
        component: VoucherCreateForm,
      },
      {
        path: "/:locale/:userType/voucher/edit/:voucherId",
        name: "Edit Voucher",
        component: VoucherEditForm,
      },
      {
        path: "/:locale/:userType/customer-voucher",
        name: "Customer Voucher",
        component: VoucherCustomer,
      },
      // ---------------------- Loyalty Management --------------------------
      // --------------------------------------------------------------------
      {
        path: "/:locale/:userType/reward",
        name: "Show Reward Table",
        component: RewardManagement,
      },
      // ---------------------- Customer Management --------------------------
      // --------------------------------------------------------------------
      {
        path: "/:locale/:userType/customer/rewardAccount",
        name: "Show Customer Reward Account Table",
        component: CustomerRewardAccountManagement,
      },
      {
        path: "/:locale/:userType/customer/profileAccounts",
        name: "Show Customer Profile Account Table",
        component: CustomerRewardAccountManagement,
      },
      // ---------------------- Setting Management --------------------------
      // --------------------------------------------------------------------
      {
        path: "/:locale/:userType/setting",
        name: "Show Setting",
        component: SettingInterface,
      },
      {
        path: "/:locale/:userType/setting/edit",
        name: "Edit Setting",
        component: SettingEditForm,
      },
      {
        path: "/:locale/:userType/mainCategory",
        name: "Main Category",
        component: MainCategory,
      },
      {
        path: "/:locale/:userType/categoryDetail",
        name: "categoryDetail",
        component: CategoryDetail,
      },
      {
        path: "/:locale/:userType/createCategory",
        name: "createCategory",
        component: CreateCategory,
      },
      {
        path: "/:locale/:userType/subCategory",
        name: "subCategory",
        component: SubCategory,
      },
      {
        path: "/:locale/:userType/subCategory/:id",
        name: "Edit Category",
        component: EditCategory,
      },
      // ---------------------- Others Management --------------------------
      // --------------------------------------------------------------------
      {
        path: "*",
        name: "Not Found",
        component: NotFound,
      },
      {
        path: "xero/exchangeToken",
        name: "Exchange Token With Xero",
        component: XeroAuthorizeSuccess,
        // redirect: myApi.exchangeTokenWithXero
      },
    ],
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
