import Vue from "vue";
import VueI18n from "vue-i18n";
import en from '@/locales/en.json'
import zh from '@/locales/zh.json'

Vue.use(VueI18n);

// function loadLocaleMessages() {
//   const locales = require.context(
//     "./locales",
//     true,
//     /[A-Za-z0-9-_,\s]+\.json$/i
//   );
//   const messages = {};
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key);
//     }
//   });
//   return messages;
// }
function checkLocale() {
  let currentURL = window.location.href
  if (currentURL.includes("en")) {
    return process.env.VUE_APP_I18N_LOCALE || "en"
  }
  if (currentURL.includes("zh")) {
    return process.env.VUE_APP_I18N_FALLBACK_LOCALE || "zh"
  }
}

export const i18n = new VueI18n({
  locale: checkLocale(),
  // locale: process.env.VUE_APP_I18N_LOCALE || "en" ,
  // locale: "zh",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "zh",
  messages: { en, zh }
});
