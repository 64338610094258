<template>
  <div>
    <ProductCreate />
  </div>
</template>

<script>
// @ is an alias to /src
import ProductCreate from "@/components/Product/ProductCreate.vue";

export default {
  name: "CreateProductForm",
  components: {
    ProductCreate,
  },
  data() {
    return {};
  },
};
</script>
 