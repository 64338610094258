<template>
  <div>
    <div>
      <PostTable />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PostTable from "@/components/Post/PostTableComponent.vue";

export default {
  name: "ViewPostList",
  components: {
    PostTable,
  },
  data() {
    return {};
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
  },
};
</script>
