<template>
  <v-card>
    <v-card-title>
      <b>{{ $t("order.listingTable.title") }}</b>

      <!-- Enabled when confirmed -->
      <!-- <v-row justify="end" class="mr-8">
        <v-btn color="primary" dark @click="showOrderQrDialog = true">
          {{ 'Scan QR Code' }}
          <v-icon dark class="ml-1"> mdi-qr </v-icon>
        </v-btn>
      </v-row> -->

      <!-- <v-row justify="end">
        <v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer><v-spacer></v-spacer>
        <v-select
          v-model="searchParam"
          :items="searchList"
          :label="$t('order.listingTable.searchList')"
          required
          :deletable-chips="true"
        ></v-select>
      </v-row> -->
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="orderTableData"
        :items-per-page="20"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :expanded.sync="expanded"
        style="cursor: pointer"
        :single-expand="singleExpand"
        item-key="id"
        :loading="runLoading"
        loading-text="Loading... Please wait"
        :search="search"
        :custom-filter="filterText"
        show-group-by
        group-desc
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50, -1],
        }"
        class="elevation-1 my-data-table"
        @click:row="myRowClickHandler"
      >
      <template v-slot:item.type="{ item }">
        <!-- <v-chip :color="getColor(item.type)"> -->
          {{ item.type }}
        <!-- </v-chip> -->
      </template>
      <template v-slot:item.status="{ item }">
        <span
        :style="{
        color: getStatusColor(item.status).color,
        background: getStatusColor(item.status).background,
        padding: '4px 8px',
        borderRadius: '5px'
      }"
    >
      {{ item.status }}
    </span>
      </template>

        <template v-slot:top>
          <v-text-field
            append-icon="mdi-magnify"
            v-model="search"
            :label="$t('order.listingTable.search')"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:no-data> {{ $t("common.msg.emtpyTable") }} </template>

        <template v-slot:no-results>
          {{ $t("common.msg.Empty Table") }}
        </template>
      </v-data-table>
    </v-card-text>

    <LatestOrderConfirmDialog
      v-model="showLatestOrderDialog"
      :latestOrderAmount="latestOrderAmount"
      @submitevent="reloadOrderTableForLatest"
    />

    <!-- QR Code Dialog -->
    <v-dialog v-model="showOrderQrDialog" max-width="500" persistent>
      <v-flex>
        <v-card>
          <v-card-title class="ml-3" align="left">
            <b :style="{ color: ' #d61515' }">Scan Order QR Code</b>
          </v-card-title>

          <v-card-text class="black--text h2">
            <QRScanner @result="onOrderCodeScan" />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="darken-1" text @click="showOrderQrDialog = false">
              {{ "Cancel" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-dialog>
    <!-- Notification Dialog -->
    <NotificationDialog v-model="notificationDialog" @handleAllow="requestNotificationPermission"/>
    <NotificationAlert v-model="isAlertNotification" :notification="notification"/>
    <NotificationDenied v-model="isDeniedNotification"/>
  </v-card>
</template>

<script>
import myApi from "../../api/api.js";
import enJson from "../../locales/en.json";
import zhJson from "../../locales/zh.json";
import moment from "moment";
import LatestOrderConfirmDialog from "../Dialog/LatestOrderConfirmDialog.vue";
import QRScanner from "../QRScanner.vue";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { DeviceUUID } from "device-uuid";
import NotificationDialog from "../NotificationDialog.vue";
import NotificationAlert from "../NotificationAlert.vue";
import NotificationDenied from "../NotificationDenied.vue";
export default {
  components: {
    LatestOrderConfirmDialog,
    QRScanner,
    NotificationDialog,
    NotificationAlert,
    NotificationDenied
  },
  props: {},
  data() {
    return {
      search: "",
      orderTableData: [],
      itemHistory: [],
      orderItemKvPair: {
        key: null,
        value: null,
      },
      headers: [],
      sortBy: "id",
      sortDesc: true,
      expanded: [],
      singleExpand: true,
      runLoading: true,
      searchParam: "",
      showLatestOrderDialog: false,
      showOrderQrDialog: false,
      previousOrderID: Number,
      currentOrderID: Number,
      latestOrderAmount: 0,
      timer: null,
      searchList: [
        "N/A",
        "Order ID",
        "Order Type",
        "Payment Method",
        "Order Status",
      ],
      fToken: "",
      isToken: false,
      notificationDialog: false,
      isAlertNotification: false,
      notification:{},
      isDeniedNotification: false
    };
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
  },
  async mounted() {
    this.getColumnHeader();
    await this.getAllOrders();
    this.getLatestOrders();
    await this.getNotification()
  },
  methods: {
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 5000,
        variant: variant,
        solid: true
      })
    },
    async requestNotificationPermission() {
    if ('Notification' in window) {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          // Handle further logic for allowing notifications
        } else {
          this.notificationDialog = false
          this.isDeniedNotification = true
        }
      } catch (error) {
        console.error('Error requesting notification permission', error);
      }
    }
  },
    async getNotification () {
      const isNotShowingAgain = localStorage.getItem("NOT_SHOW_NOTIFICATION_DIALOG");
      // Check if the browser supports notifications
      if ('Notification' in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            // If permission is granted, show notifications
          } else {
            if (isNotShowingAgain) return;
            this.notificationDialog = true;
          }
        });
      } else {
        console.log('This browser does not support notifications.');
        return;
      }

      const firebaseConfig = {
      apiKey: "AIzaSyDc7Qz04XjJwVxZuCkOiV7csqDtTdtPjhQ",
      authDomain: "nukcafe-4bb02.firebaseapp.com",
      projectId: "nukcafe-4bb02",
      storageBucket: "nukcafe-4bb02.appspot.com",
      messagingSenderId: "812237804125",
      appId: "1:812237804125:web:a5d9cec4aa41a53eb9b3b4",
      measurementId: "G-YRG3P8M117",
    };

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    await getToken(messaging, {
      vapidKey:
        "BIgZFc5srvP9LbxcHttjmYutbMPnKi0XwNtxRc0pxh63_m5ypeniQcgVWEQMTujiRbkaPEeMcWZBe06kluzP-Q0",
    })
      .then((currentToken) => {
        if (currentToken) {
          this.notificationSmg(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });

    onMessage(messaging, (payload) => {
      this.notification = payload.notification
      this.isAlertNotification = true
      this.getAllOrders();
    });
    },
    getColumnHeader() {
      if (this.$root.$i18n.locale === "en") {
        this.headers = [
          {
            text: enJson.order.listingTable.column.headers.id,
            value: "id",
            groupable: false,
          },
          {
            text: enJson.order.listingTable.column.headers.orderType,
            value: "type",
          },
          {
            text: enJson.order.listingTable.column.headers.priceAndCurrency,
            value: "finalPrice",
            groupable: false,
          },
          {
            text: enJson.order.listingTable.column.headers.paymentMethod,
            value: "paymentMethod",
          },
          {
            text: enJson.order.listingTable.column.headers.orderStatus,
            value: "status",
          },
          {
            text: enJson.order.listingTable.column.headers.paymentStatus,
            value: "paymentStatus",
          },
          {
            text: enJson.order.listingTable.column.headers.createdDate,
            value: "createdDate",
            groupable: false,
          },
          {
            text: enJson.order.listingTable.column.headers.customerPhone,
            value: "customerPhone",
            groupable: false,
          },
          {
            text: enJson.order.listingTable.column.headers.customerName,
            value: "customerName",
            groupable: false,
          },
        ];
      }

      if (this.$root.$i18n.locale === "zh") {
        this.headers = [
          { text: zhJson.order.listingTable.column.headers.id, value: "id" },
          {
            text: zhJson.order.listingTable.column.headers.orderType,
            value: "type",
          },
          {
            text: zhJson.order.listingTable.column.headers.priceAndCurrency,
            value: "finalPrice",
          },
          {
            text: zhJson.order.listingTable.column.headers.paymentMethod,
            value: "paymentMethod",
          },
          {
            text: zhJson.order.listingTable.column.headers.orderStatus,
            value: "status",
          },
          {
            text: zhJson.order.listingTable.column.headers.createdDate,
            value: "createdDate",
          },
        ];
      }
    },
    myRowClickHandler(record) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      // console.log(index, record); // This will be the item data for the row
      this.$router.push({
        name: "View Order",
        params: {
          orderId: record.id,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    getAllOrders: async function() {
      await myApi
        .getAllOrders()
        .then((response) => {
          this.ordersResponseDataList = response.data.data;
          this.orderTableData = response.data.data.map(order => {
          return {
            ...order,
            createdDate: moment(order.createdDate).format("YYYY-MM-DD HH:mm:ss")
          };
        });

          // Calculate the max Order ID For previousOrderID + currentOrderID the first time
          let orderIDList = this.ordersResponseDataList.map(
            (order) => order.id
          );
          // console.log("Order ID List = ", orderIDList);
          let maxOrderID = Math.max(...orderIDList);
          // console.log("maxOrderID = ", maxOrderID);
          this.previousOrderID = maxOrderID;
          this.currentOrderID = maxOrderID;
          // console.log("latestOrderID = ", this.previousOrderID);
          this.runLoading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.runLoading = false));
    },
    getLatestOrders() {
      console.log("Enter Get Latest Orders");
      this.timer = setInterval(() => {
        myApi
          .getAllOrders()
          .then((response) => {
            this.ordersResponseDataList = response.data.data;
            let realTimeOrderIDList = this.ordersResponseDataList.map(
              (order) => order.id
            );
            this.currentOrderID = Math.max(...realTimeOrderIDList);
            // Only Pop the Order Dialog when Current Max Order ID > Previous Max Order ID
            if (this.currentOrderID > this.previousOrderID) {
              let diff = this.currentOrderID - this.previousOrderID;
              // console.log("this.latestOrderAmount = ", diff);
              this.previousOrderID = this.currentOrderID;
              if (this.showLatestOrderDialog === false) {
                this.latestOrderAmount = diff;
                this.showLatestOrderDialog = true;
              } else {
                this.latestOrderAmount = this.latestOrderAmount + diff;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 30000);
    },
    async onOrderCodeScan(decodedText, decodedResult) {
      this.showOrderQrDialog = false;
      console.log(decodedText, decodedResult);
      const [label, hash, orderId] = decodedText.split("|");
      const verifyRes = await myApi.verifyPickupCode(decodedText);
      const verifiedOrderId = String(verifyRes.data.data);

      if (label === "pickup" && orderId === verifiedOrderId && hash != "") {
        this.$router.push({
          name: "View Order",
          params: {
            orderId,
            locale: this.$root.$i18n.locale,
            userType: this.userType,
          },
        });
      } else {
        alert("Cannot verify QR Code");
      }
    },
    filterText(value, search, item) {
      console.log({ value, search, item });
      return (
        value !== null &&
        search !== null &&
        item !== null &&
        typeof value === "number" &&
        item.id.toString().includes(search) &&
        value.toString().indexOf(search) !== -1
      );
    },
    getColor(orderType) {
      if (orderType === "PICKUP") return "primary";
      else return "green";
    },
    getStatusColor(status) {
      switch (status) {
        case "CANCELLED":
          return {
            color: "#DA3724",
            background:"#FBEBE9"
          }
          case "FAIL":
          return {
            color: "#DA3724",
            background:"#FBEBE9"
          }
          case "MAKING":
          return {
            color: "#F88922",
            background:"#FFF4E2"
          }
          case "COMPLETED":
          return {
            color: "#62D067",
            background:"#E5FFE2"
          }   
        default:
        return {
            color: "#1976D2",
            background:"#CCE6FF"
          }   
        }
    },
    reloadOrderTableForLatest() {
      this.showLatestOrderDialog = false;
      this.$router.go();
    },
    notificationSmg: async function(token) {
      let uuid = new DeviceUUID().get();
      const data = {
        type: "ADMIN",
        token: token,
        uuid: uuid,
      };
      await myApi.registerStaffDeviceUsingPOST(data);
    },
  },
  beforeDestroy() {
    console.log("Destorying the timer for latest orders !");
    clearInterval(this.timer);
  },
};
</script>
