var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('b',[_vm._v(_vm._s(_vm.$t("rewardHistory.listingTable.title")))])]),_c('v-card-actions',{staticClass:"mx-2"},[_vm._v(" Last Update Date: "+_vm._s(_vm.dateTime)+" ")]),_c('div',[_c('v-card-actions',[_c('v-spacer'),(_vm.isProgress == false)?_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary","disabled":_vm.userType === 'USER'},on:{"click":function($event){return _vm.refreshRewardHistoryTable()}}},[_vm._v(" Refresh ")]):_c('v-progress-circular',{staticClass:"mx-14",attrs:{"model-value":"20","indeterminate":""}})],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 my-data-table",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.rewardHistoryTableData,"items-per-page":20,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"expanded":_vm.expanded,"single-expand":_vm.singleExpand,"show-expand":"","item-key":"transactionId","loading":_vm.runLoading,"loading-text":"Loading... Please wait","search":_vm.search,"custom-filter":_vm.filterText,"show-group-by":"","group-desc":"","footer-props":{
        'items-per-page-options': [10, 20, 30, 40, 50, -1],
      }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('rewardHistory.listingTable.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.senderDisplayName",fn:function(ref){
      var item = ref.item;
return [(item.senderDisplayName === null)?_c('tr',[_vm._v(" NukCafe ")]):_vm._e(),_c('tr',[_vm._v(" "+_vm._s(item.senderDisplayName)+" ")])]}},{key:"item.receiverDisplayName",fn:function(ref){
      var item = ref.item;
return [(item.receiverDisplayName === null)?_c('tr',[_vm._v(" NukCafe ")]):_vm._e(),_c('tr',[_vm._v(" "+_vm._s(item.receiverDisplayName)+" ")])]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{style:({ background: ' #E0F2F1' }),attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t( "rewardHistory.listingTable.column.details.senderAccountRef" ))+": "+_vm._s(item.senderAccountRef)+" ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm.$t( "rewardHistory.listingTable.column.details.receiverAccountRef" ))+": "+_vm._s(item.receiverAccountRef)+" ")])],1)],1)]}},{key:"item.transactionDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.createdDate(item.transactionDate))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("common.msg.emtpyTable"))+" ")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("common.msg.noDataFound"))+" ")]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }