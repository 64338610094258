<template>
  <div>
    <v-app-bar app color="#E67D6C" dense dark clipped-left>
      <!-- <v-app-bar-nav-icon class="grey--text" @click=""> </v-app-bar-nav-icon> -->

      <v-img
        class="mx-2"
        :src="renderLogo"
        max-height="60"
        max-width="60"
        contain
        style="cursor: pointer"
        @click="gotoHome"
      ></v-img>

      <v-toolbar-title style="cursor: pointer" @click="gotoHome">
        NukCafe
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <span> {{ display }}</span> -->

      <!-- <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list v-if="isLogin == true" color="#afbaab" dense dark>
          <v-list-item @click="signout">
            <v-list-item-title>{{ $t("nav.signOut") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "SideBarNavigation",
  data() {
    return {};
  },
  methods: {
    gotoHome() {
      if (this.$store.state.userInfo.isLogin) {
        this.$router
          .push({ path: `/${this.$root.$i18n.locale}/` })
          .catch(() => {});
      } else {
        this.$router.push(`/${this.$root.$i18n.locale}/`).catch(() => {});
      }
    },
    signout() {
      window.location.href = "/ttfish/api/v1/oauth2/logout";
    },
  },
  computed: {
    display() {
      return this.$store.state.userInfo.display;
    },
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
    isLogin() {
      return this.$store.state.userInfo.isLogin;
    },
    renderLogo() {
      var images = require.context("../assets/", false, /\.png$/);
      return images("./nukcafe_icon.png");
    },
  },
};
</script>

<style lang="scss">
.userLogo_sm {
  max-width: 60px !important;
  margin-right: 10px;
}

.userLogo_salasusu {
  max-width: 100px;
  margin-right: 10px;
}
</style>
