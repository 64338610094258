<template>
  <div>
    <VoucherCreate />
  </div>
</template>

<script>
// @ is an alias to /src
import VoucherCreate from "@/components/Voucher/VoucherCreate.vue";

export default {
  name: "CreateVoucherForm",
  components: {
    VoucherCreate,
  },
  data() {
    return {};
  },
};
</script>
 