<template>
  <div class="processor">
    <SettingEdit />
  </div>
</template>

<script>
// @ is an alias to /src
import SettingEdit from "@/components/Setting/SettingEdit.vue";

export default {
  props: {},
  components: {
    SettingEdit,
  },
  data() {
    return {};
  },
};
</script>
