import Vue from "vue";
import router from "./router";
import store from "./store";
import App from "./App.vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueCookies from "vue-cookies";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

import moment from "moment";
import { i18n } from "./i18n";
import { Trans } from './plugins/Translation';

import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

// Register plugins
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueCookies);
Vue.use(VueSidebarMenu);

// Set default config for VueCookies
Vue.$cookies.config("7d");

// Set global cookies
Vue.$cookies.set("theme", "default");
Vue.$cookies.set("hover-time", "1s");

// Add global properties
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);
Vue.prototype.$admin = 'admin';
Vue.prototype.$kitchen = 'kitchen';
Vue.prototype.$delivery = 'delivery';

Vue.config.productionTip = false;

// Vue Filters
Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString().toLowerCase();
  if (value.toLowerCase() === "id") {
    return "ID";
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("toLocalTime", function(value) {
  return moment(value).format("YYYY-MM-DD HH:mm:ss");
});

Vue.filter("numberWithCommas", function(value) {
  let internationalNumberFormat = new Intl.NumberFormat("en-US");
  return internationalNumberFormat.format(value);
});

// Initialize Vue instance
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
