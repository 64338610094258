<template>
  <v-container>
    <b
      ><h3>
        {{ $t("product.view.title") }} :
        <u>{{ this.$route.params.productId }}</u>
      </h3></b
    >

    <br />

    <v-row justify="end" class="mr-8">
      <v-btn
        color="primary"
        outlined
        class="mr-4"
        dark
        @click="goToShowProductTablePage"
      >
        {{ $t("common.btn.back") }}
      </v-btn>

      <v-btn
        class="mr-4"
        dark
        @click.stop="submitDialog = true"
        :color="getColor(product.active)"
        v-if="product.active"
      >
        {{ $t("product.listingTable.column.btn.productSwitchInactive") }}
        <v-icon dark class="ml-1"> mdi-power-plug-off </v-icon>
      </v-btn>

      <v-btn
        class="mr-4"
        dark
        @click.stop="submitDialog = true"
        :color="getColor(product.active)"
        v-if="!product.active"
      >
        {{ $t("product.listingTable.column.btn.productSwitchActive") }}
        <v-icon dark class="ml-1"> mdi-power-plug </v-icon>
      </v-btn>

      <v-btn
        v-if="showAdminVision"
        color="primary"
        class="mr-4"
        dark
        @click="goToEditProductDetailPage"
      >
        {{ $t("common.btn.edit") }}
        <v-icon dark class="ml-1"> mdi-pencil </v-icon>
      </v-btn>

      <v-btn
        v-if="showAdminVision"
        color=""
        class="red"
        dark
        @click.stop="deleteDialog = true"
      >
        {{ $t("common.btn.delete") }}
        <v-icon dark class="ml-1"> mdi-delete </v-icon>
      </v-btn>

      <ProductStatusConfirmSubmit
        v-model="submitDialog"
        :productName="product.name"
        :productStatus="product.active"
        @submitevent="changeProductStatus"
      />

      <ConfirmDeleteDialog
        :deleteType="$t('common.msg.confirmDeleteDialog.type.product')"
        v-model="deleteDialog"
        @submitevent="onDelete"
      />
    </v-row>

    <br />
    <br />
    <v-card color="#CFD8DC">
      <v-card-title>
        <u
          ><b>{{ $t("product.view.content.main.title") }}</b></u
        >
      </v-card-title>
      <v-card-text class="ml-4">
        <v-row class="h5 black--text mt-3">
          {{ $t("product.view.content.main.name") }} : {{ product.name }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("product.view.content.main.category") }} :
          {{ product.categoryId }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("product.view.content.main.description") }} :
          {{ product.description }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("product.view.content.main.price") }} : {{ product.price }}
        </v-row>

        <v-row v-if="product.active" class="h5 black--text mt-3">
          {{ $t("product.view.content.main.status") }} : Online
        </v-row>

        <v-row v-if="!product.active" class="h5 black--text mt-3">
          {{ $t("product.view.content.main.status") }} : Offline
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("product.view.content.main.sequence") }} :
          {{ product.sequence }}
        </v-row>
      </v-card-text>
    </v-card>

    <br />

    <v-card color="#CFD8DC">
      <v-card-title>
        <u
          ><b>{{ $t("product.view.content.feature.title") }}</b></u
        >
      </v-card-title>

      <v-card-text class="ml-4">
        <v-col
          align="right"
          v-for="selectedItem in productFeatureList"
          :key="`item-${selectedItem.id}`"
        >
          <v-row v-if="selectedItem.selectedFeatures !== null">
            <li class="h5 black--text mt-3">
              {{ $t("product.view.content.feature.type") }} :
              {{ selectedItem.type }}
            </li>
          </v-row>

          <v-row v-if="selectedItem.selectedFeatures !== null">
            <li class="h5 black--text mt-3">
              {{ $t("product.view.content.feature.value") }} :
              {{ selectedItem.value }}
            </li>
          </v-row>

          <v-row v-if="selectedItem.selectedFeatures !== null">
            <li class="h5 black--text mt-3">
              {{ $t("product.view.content.feature.display") }} :
              {{ selectedItem.display }}
            </li>
          </v-row>

          <v-row v-if="selectedItem.selectedFeatures !== null">
            <li class="h5 black--text mt-3">
              {{ $t("product.view.content.feature.deltaPrice") }} :
              {{ selectedItem.deltaPrice }}
            </li>
          </v-row>

          <v-divider> </v-divider>
          <v-divider> </v-divider>
        </v-col>
        <v-row
          class="h5 black--text mt-3"
          v-if="productFeatureList.length === 0"
        >
          N/A
        </v-row>
      </v-card-text>
    </v-card>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <AfterSubmitDialog
      v-model="afterSubmitDialog"
      :submitSuccess="submitSuccess"
      :errMsg="errMsg"
    />
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";
import AfterSubmitDialog from "../AfterSubmitDialog.vue";
import ProductStatusConfirmSubmit from "../Dialog/ProductStatusConfirmSubmit.vue";
import ConfirmDeleteDialog from "../ConfirmDeleteDialog.vue";

export default {
  components: {
    AfterSubmitDialog,
    ProductStatusConfirmSubmit,
    ConfirmDeleteDialog,
  },
  props: [],
  data() {
    return {
      loadingOverlay: false,
      submitDialog: false,
      submitSuccess: false,
      afterSubmitDialog: false,
      deleteDialog: false,
      product: {},
      showAdminVision: false,
      productFeatureList: [],
      categoryList: [],
      errMsg: this.$t("common.msg.error.edit.msg"),
    };
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
    userRole() {
      return this.$store.state.userInfo.userRole;
    },
  },
  created() {
    if (this.userRole.includes("admin")) {
      this.showAdminVision = true;
    }
  },
  mounted: async function () {
    await this.getAllCategories();
    this.getProductData();
    this.loadingOverlay = true;
  },
  methods: {
    getColor(productStatus) {
      if (productStatus === true) return "#d61515";
      else return "#00C853";
    },
    changeProductStatus() {
      this.loadingOverlay = !this.loadingOverlay;
      this.submitDialog = false;
      this.updateProductStatus();
    },
    updateProductStatus() {
      // console.log("Product Status Before", this.product.active);
      this.product.active = !this.product.active;
      var bodyFormData = new FormData();
      bodyFormData.append("active", this.product.active);
      // console.log("Product Status After", this.product.active);
      myApi
        .toggleProductStatus(this.$route.params.productId, bodyFormData)
        .then(() => {
          this.submitSuccess = true;
          this.afterSubmitDialog = true;
          this.loadingOverlay = false;
        })
        .catch(() => {
          this.afterSubmitDialog = true;
          this.submitSuccess = false;
          this.loadingOverlay = false;
        });
    },
    getAllCategories() {
      console.log("Start Getting Category List");
      myApi
        .getAllCategories()
        .then((response) => {
          // console.log("getAllCateResponse", response);
          this.categoryList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOverlay = false));
    },
    getProductData() {
      console.log("Start Getting Product Data");
      myApi
        .getProductData(this.$route.params.productId)
        .then((response) => {
          this.product = response.data.data;
          this.productFeatureList = this.product.productFeatures;

          console.log("Selected Product: ", this.product);
          // console.log("Selected Cate List: ", this.categoryList);

          // console.log("Selected Product Feature: ", this.productFeatureList);
          if (this.product.category !== null) {
            let category = this.categoryList.find(
              (target) => target.id === this.product.categoryId
            );
            if (category !== undefined) {
              this.product.categoryId = category.name;
            }
          }
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOverlay = false));
    },
    onDelete() {
      console.log("Trigger Delete Product");
      this.submitDialog = false;
      this.deleteProduct();
    },
    deleteProduct() {
      myApi
        .deleteProduct(this.product.id)
        .then(() => {
          this.submitSuccess = true;
          this.afterSubmitDialog = true;
          this.goToShowProductTablePage();
        })
        .catch(() => {
          // console.log(error);
          this.afterSubmitDialog = true;
          this.submitSuccess = false;
        });
    },
    goToShowProductTablePage() {
      console.log("Entered Show Product");
      this.$router.push({
        name: "Show Product Table",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    goToEditProductStatusPage() {
      console.log("Entered Edit Product Status Page");
      console.log(
        "Selected Product ID For Edit: ",
        this.$route.params.productId
      );
      this.$router.push({
        name: "View Product",
        params: {
          productId: this.$route.params.productId,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    goToEditProductDetailPage() {
      console.log("Entered Edit Product Detail Page");
      console.log(
        "Selected Product ID For Edit: ",
        this.$route.params.productId
      );
      this.$router.push({
        name: "Edit Product",
        params: {
          productId: this.$route.params.productId,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
  },
};
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: rgb(32, 27, 27);
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
