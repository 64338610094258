<template>
  <div>
    <PostViewDetail />
  </div>
</template>

<script>
// @ is an alias to /src
import PostViewDetail from "@/components/Post/PostViewDetail.vue";

export default {
  props: {},
  name: "ViewPostForm",
  components: {
    PostViewDetail,
  },
  data() {
    return {};
  },
};
</script>
