<template>
  <div>
    <div>
      <OrderTable />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Inventory from "@/components/Inventory.vue";
import OrderTable from "@/components/Order/OrderTableComponent.vue";

export default {
  name: "NukCafeDashboardView",
  components: {
    // Inventory,
    OrderTable,
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType;
    },
    isLogin() {
      return this.$store.state.userInfo.isLogin;
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
