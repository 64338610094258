<template>
  <v-container>
    <AfterSubmitDialog
      v-model="afterSubmitDialog"
      :titleSuccess="titleSuccess"
      :submitSuccess="submitSuccess"
      :isBtn="'!null'"
    />
    <ConfirmDeleteDialog
      :deleteType="'category'"
      v-model="deleteDialog"
      @submitevent="onDelete"
    />
    <b
      ><h3>
        Category Detail
      </h3></b
    >
    <v-row justify="end" class="mr-1 mb-4">
      <v-btn color="primary" outlined class="mr-4" dark @click="checkBack">
        {{ $t("common.btn.back") }}
      </v-btn>

      <v-btn color="primary" class="mr-4" dark @click="goToEditCategory">
        {{ $t("common.btn.edit") }}
        <v-icon dark class="ml-1"> mdi-pencil </v-icon>
      </v-btn>

      <v-btn color="" class="red" dark @click="checkCategory">
        {{ $t("common.btn.delete") }}
        <v-icon dark class="ml-1"> mdi-delete </v-icon>
      </v-btn>
    </v-row>

    <v-card color="#CFD8DC">
      <h5 class="pt-4">Main Category: {{ mainCategoryDetailBySub.id }}</h5>
      <br />
      <v-card-text class="ml-4">
        <v-row class="h5 black--text">
          Category Name : {{ mainCategoryDetailBySub.name }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Display Name : {{ mainCategoryDetailBySub.displayName }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Display Name (English) : {{ mainCategoryDetailBySub.displayEn }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Display Name (Khmer) : {{ mainCategoryDetailBySub.displayKm }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Display Name (ZH) : {{ mainCategoryDetailBySub.displayZh }}
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ================ -->
    <v-card class="mt-4" color="#CFD8DC" v-if="$route.params.userType == 'Sub'">
      <h5 class="pt-4">Sub Category: {{ categoryDetail.id }}</h5>
      <v-card-text class="ml-4">
        <v-row class="h5 black--text">
          Category Name : {{ categoryDetail.name }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Display Name : {{ categoryDetail.displayName }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Display Name (English): {{ categoryDetail.displayEn }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Display Name (Khmer): : {{ categoryDetail.displayKm }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Display Name (ZH): : {{ categoryDetail.displayZh }}
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import myApi from "../../api/api.js"
import AfterSubmitDialog from "../AfterSubmitDialog.vue"
import ConfirmDeleteDialog from "../ConfirmDeleteDialog.vue"

export default {
  name: "CategoryDetail",
  components: {
    AfterSubmitDialog,
    ConfirmDeleteDialog,
  },
  data() {
    return {
      categoryDetail: {
        id: "",
        name: "",
        displayName: "",
        displayEn: "",
        displayZh: "",
        displayKm: "",
        mainCategoryId: "",
      },
      mainCategoryList: [],
      subCategoryList: [],
      categoryID: "",
      deleteDialog: false,
      afterSubmitDialog: false,
      submitSuccess: true,
      titleSuccess: "Can't delete this category",
      productsResponseDataList: [],
      mainCategoryDetailBySubList: [
        {
          id: "",
          name: "",
          displayName: "",
          displayEn: "",
          displayZh: "",
          displayKm: "",
        },
      ],
      mainCategoryDetailBySub: {
        id: "",
        name: "",
        displayName: "",
        displayEn: "",
        displayZh: "",
        displayKm: "",
      },
    }
  },
  mounted: async function() {
    if (this.$route.params.userType === "Sub") {
      this.categoryID = this.$route.params.categoryId
      await this.getSubCategoryDetail(this.categoryID)
      await this.getAllProducts()
    } else {
      this.categoryID = this.$route.params.categoryId
      this.getCategoryDetail(this.categoryID)
      this.getAllSubCategories()
    }
  },
  methods: {
    getAllMainCategory() {
      myApi
        .getMainCategory()
        .then((response) => {
          this.mainCategoryList = response.data.data
          var newCategory = this.mainCategoryList.filter((item) => {
            return this.categoryDetail?.mainCategoryId == item.id
          })
          this.mainCategoryDetailBySubList = newCategory
          this.mainCategoryDetailBySubList.forEach((item) => {
            this.mainCategoryDetailBySub = item
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => (this.runLoading = false))
    },
    getAllProducts() {
      myApi
        .getAllProducts()
        .then((response) => {
          this.productsResponseDataList = response.data.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => (this.runLoading = false))
    },
    getCategoryDetail(categoryId) {
      myApi
        .getMainCategoryById(categoryId)
        .then((response) => {
          this.mainCategoryDetailBySub = response.data.data
          console.log("this.categoryDetail: ", this.categoryDetail)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => (this.runLoading = false))
    },
    getSubCategoryDetail(categoryId) {
      myApi.getSubCategoryDetail(categoryId).then((response) => {
        this.categoryDetail = response.data.data
        this.getAllMainCategory()
        console.log("Sub category =>: ", this.categoryDetail)
      })
    },
    getAllSubCategories() {
      myApi
        .getAllCategories()
        .then((response) => {
          this.subCategoryList = response.data.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => (this.runLoading = false))
    },
    onDelete() {
      this.submitDialog = false
      this.deleteCategory();
    },
    deleteCategory() {
      if (this.$route.params.userType === "Sub") {
        myApi.deleteSubCategory(this.categoryID).then((response) => {
          console.log("response: ", response);
          if (response.data.success == true) {
            this.checkBack();
          }
        })
      } else {
        myApi.deleteMainCategory(this.categoryID).then((response) => {
          if (response.data.success == true) {
            this.checkBack();
          }
        })
      }
    },
    goToShowProductTablePage() {
      this.$router.push({
        name: "Main Category",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      })
    },
    checkCategory() {
      if (this.$route.params.userType === "Sub") {
        var newPro = this.productsResponseDataList.filter((item) => {
          return this.categoryID == item.categoryId
        });

        if (newPro.length > 0 || this.categoryID == -1) {
          this.afterSubmitDialog = true
        } else {
          this.deleteDialog = true
        }
      } else {
        var newSubCategory = this.subCategoryList.filter((item) => {
          return item.mainCategoryId === this.categoryID
        });

        if (newSubCategory.length > 0 || this.categoryID == -1) {
          this.afterSubmitDialog = true;
          setTimeout(function() {
            this.afterSubmitDialog = false;
          }, 1000)
        } else {
          this.deleteDialog = true;
        }
      }
    },
    checkBack() {
      if (this.$route.params.userType === "Sub") {
        this.$router.push({
          name: "subCategory",
          params: {
            locale: this.$root.$i18n.locale,
            userType: this.userType,
          },
        })
      } else {
        this.goToShowProductTablePage()
      }
    },
    goToEditCategory() {
      if (this.$route.params.userType === "Sub") {
        this.$router.push({
          name: "Edit Category",
          params: {
            locale: this.$root.$i18n.locale,
            userType: "Sub",
            categoryId: this.categoryID,
          },
        })
      } else {
        this.$router.push({
          name: "Edit Category",
          params: {
            locale: this.$root.$i18n.locale,
            userType: "Main",
            categoryId: this.categoryID,
          },
        })
      }
    },
  },
}
</script>
