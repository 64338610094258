<template>
  <v-container>
    <b
      ><h3>
        {{ $t("post.view.title") }} :
        <u>{{ this.$route.params.postId }}</u>
      </h3></b
    >

    <br />

    <v-row justify="end" class="mr-8">
      <v-btn
        color="primary"
        outlined
        class="mr-4"
        dark
        @click="goToShowPostTablePage"
      >
        {{ $t("common.btn.back") }}
      </v-btn>
    </v-row>

    <br />
    <br />
    <v-card color="#BCAAA4">
      <br />
      <v-img
        class="mx-auto"
        contain
        max-width="850"
        max-height="450"
        :src="post.imageURL"
      ></v-img>

      <v-card-text>
        <v-row align="center" justify="center" class="h5 black--text">
          {{ $t("post.view.content.main.caption") }}:
          {{ post.caption }}
        </v-row>

        <v-row align="center" justify="center" class="overline black--text">
          {{ $t("post.view.content.main.approvalStatus") }}:
          <b
            ><u>{{ post.approvalStatus }}</u></b
          >
        </v-row>

        <v-row align="center" justify="center" class="overline black--text">
          {{ $t("post.view.content.main.createdDate") }}:
          {{ post.createdDate }}
        </v-row>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-actions>
        <v-btn
          class="pl-16"
          color="#2E7D32"
          text
          @click="
            submitDialog = true;
            postApprovalStatus = 'APPROVED';
          "
        >
          Approve
          <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="pr-16"
          color="#d61515"
          text
          @click="
            submitDialog = true;
            postApprovalStatus = 'REJECTED';
          "
        >
          Disapprove
          <v-icon dark right> mdi-cancel </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <PostApprovalStatusConfirmSubmit
      v-model="submitDialog"
      :postApprovalStatus="postApprovalStatus"
      @submitevent="updatePostApprovalStatus"
    />
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";
// import AfterSubmitDialog from "../AfterSubmitDialog.vue";
// import ConfirmDeleteDialog from "../ConfirmDeleteDialog.vue";
import PostApprovalStatusConfirmSubmit from "../Dialog/PostApprovalStatusConfirmSubmit.vue";
import moment from "moment";

export default {
  components: {
    // AfterSubmitDialog,
    // ConfirmDeleteDialog,
    PostApprovalStatusConfirmSubmit,
  },
  props: [],
  data() {
    return {
      postApprovalStatus: "",
      loadingOverlay: false,
      submitDialog: false,
      submitSuccess: false,
      afterSubmitDialog: false,
      deleteDialog: false,
      post: {},
      errMsg: this.$t("common.msg.error.edit.msg"),
    };
  },
  computed: {},
  created() {},
  mounted: async function () {
    this.getPostData();
    this.loadingOverlay = true;
  },
  methods: {
    getColor(productStatus) {
      if (productStatus === true) return "#d61515";
      else return "#00C853";
    },
    updatePostApprovalStatus() {
      console.log("postApprovalStatus : ", this.postApprovalStatus);
      this.loadingOverlay = !this.loadingOverlay;
      this.submitDialog = false;
      this.screenPost();
    },
    getPostData() {
      console.log("Start Getting Post Data");
      myApi
        .getPostData(this.$route.params.postId)
        .then((response) => {
          this.post = response.data.data;
          this.post.createdDate = moment(this.post.createdDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOverlay = false));
    },
    screenPost() {
      console.log("Start Screening Post");
      var postFormData = new FormData();
      postFormData.append("approvalStatus", this.postApprovalStatus);
      myApi
        .screenPost(this.$route.params.postId, postFormData)
        .then((response) => {
          this.post = response.data.data;
          // console.log("THis POST : ", this.post);
          this.loadingOverlay = false;
          this.goToShowPostTablePage();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOverlay = false));
    },
    goToShowPostTablePage() {
      console.log("Entered Post Table");
      this.$router.push({
        name: "Show Post Table",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
  },
};
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: rgb(32, 27, 27);
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
