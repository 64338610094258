var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('b',[_vm._v(_vm._s(_vm.$t("order.listingTable.title")))])]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 my-data-table",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.orderTableData,"items-per-page":20,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"expanded":_vm.expanded,"single-expand":_vm.singleExpand,"item-key":"id","loading":_vm.runLoading,"loading-text":"Loading... Please wait","search":_vm.search,"custom-filter":_vm.filterText,"show-group-by":"","group-desc":"","footer-props":{
        'items-per-page-options': [10, 20, 30, 40, 50, -1],
      }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.myRowClickHandler},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('span',{style:({
      color: _vm.getStatusColor(item.status).color,
      background: _vm.getStatusColor(item.status).background,
      padding: '4px 8px',
      borderRadius: '5px'
    })},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('order.listingTable.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("common.msg.emtpyTable"))+" ")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("common.msg.Empty Table"))+" ")]},proxy:true}])})],1),_c('LatestOrderConfirmDialog',{attrs:{"latestOrderAmount":_vm.latestOrderAmount},on:{"submitevent":_vm.reloadOrderTableForLatest},model:{value:(_vm.showLatestOrderDialog),callback:function ($$v) {_vm.showLatestOrderDialog=$$v},expression:"showLatestOrderDialog"}}),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.showOrderQrDialog),callback:function ($$v) {_vm.showOrderQrDialog=$$v},expression:"showOrderQrDialog"}},[_c('v-flex',[_c('v-card',[_c('v-card-title',{staticClass:"ml-3",attrs:{"align":"left"}},[_c('b',{style:({ color: ' #d61515' })},[_vm._v("Scan Order QR Code")])]),_c('v-card-text',{staticClass:"black--text h2"},[_c('QRScanner',{on:{"result":_vm.onOrderCodeScan}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){_vm.showOrderQrDialog = false}}},[_vm._v(" "+_vm._s("Cancel")+" ")])],1)],1)],1)],1),_c('NotificationDialog',{on:{"handleAllow":_vm.requestNotificationPermission},model:{value:(_vm.notificationDialog),callback:function ($$v) {_vm.notificationDialog=$$v},expression:"notificationDialog"}}),_c('NotificationAlert',{attrs:{"notification":_vm.notification},model:{value:(_vm.isAlertNotification),callback:function ($$v) {_vm.isAlertNotification=$$v},expression:"isAlertNotification"}}),_c('NotificationDenied',{model:{value:(_vm.isDeniedNotification),callback:function ($$v) {_vm.isDeniedNotification=$$v},expression:"isDeniedNotification"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }