<template>
  <v-dialog v-model="showDialog" max-width="568">
    <v-card>
      <v-card-title class="headline">Enable Notifications</v-card-title>

      <v-card-text align="start">
        <div>
          Would you like to turn on notifications to receive updates on new
          orders?
        </div>
        <v-checkbox
          v-model="isNotShowingAgain"
          label="Don't show this message again"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="handleAllow">
          Allow
        </v-btn>

        <v-btn color="green darken-1" text @click.stop="handleClose">
          No, Thanks
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isNotShowingAgain: false,
      isDenied: false,
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async handleAllow() {
      if (this.isNotShowingAgain) {
        localStorage.setItem("NOT_SHOW_NOTIFICATION_DIALOG", "true");
      }
      this.$emit("handleAllow");
    },
    handleClose() {
      if (this.isNotShowingAgain) {
        localStorage.setItem("NOT_SHOW_NOTIFICATION_DIALOG", "true");
      }
      this.showDialog = false;
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light .v-label {
  margin: 0;
  font-size: 14px;
}
</style>
