<template>
  <div>
    <div>
      <RewardAccountsTableC />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RewardAccountsTableC from "@/components/Customer/RewardAccountsTableComponent.vue";

export default {
  name: "ViewCustomerRewardAccountList",
  components: {
    RewardAccountsTableC,
  },
  data() {
    return {};
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
  },
};
</script>
