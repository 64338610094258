<template>
  <div>
    <VoucherDetailEdit />
  </div>
</template>

<script>
// @ is an alias to /src
import VoucherDetailEdit from "@/components/Voucher/VoucherDetailEdit.vue";

export default {
  name: "EditVoucherForm",
  components: {
    VoucherDetailEdit,
  },
  data() {
    return {};
  },
};
</script>
 