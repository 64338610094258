<template>
  <v-container>
    <v-dialog v-model="show" width="500">
      <v-card>
        <v-card-title v-if="submitSuccess">
          <div v-if="titleSuccess == '' || titleSuccess == null">
            <v-card-text>
              {{ $t("common.msg.afterSubmitDialog.submitSuccess") }}
            </v-card-text>
          </div>
          <div v-else>
            <v-card-text>
              {{ titleSuccess }}
            </v-card-text>
          </div>
        </v-card-title>
        <v-card-title v-else>
          <div>
            <v-card-title v-if="titleFail == '' || titleFail == null">
              {{ $t("common.msg.afterSubmitDialog.submitFail") }}
            </v-card-title>
            <v-card-title v-else>
              {{ titleFail }}
            </v-card-title>
          </div>
          <v-card-text v-if="errMsg != ''" class="text-h7">
            {{ errMsg }}
          </v-card-text>
        </v-card-title>

        <v-card-actions v-if="isBtn == '' || isBtn == null">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="show = false">
            {{ $t("common.btn.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    value: Boolean,
    submitSuccess: Boolean,
    errMsg: String,
    titleSuccess: String,
    titleFail: String,
    isBtn: String
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
  created() {
    console.log("After Submit")
  },
  method: {
    follow() {
      this.show.set(false)
      this.$router.go()
    },
  },
}
</script>
