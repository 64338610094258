<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-img height="250" :src="product.s3ImageURL"></v-img>

        <v-card-title>
          <b
            >{{ $t("product.edit.title") }}: <u>{{ product.nameEn }}</u>
          </b>
        </v-card-title>

        <v-card-text class="ml-4">
          <v-row justify="end" class="mr-8">
            <v-btn
              color="primary"
              outlined
              class="mr-4"
              dark
              @click="goToProductViewPage"
            >
              {{ $t("common.btn.back") }}
            </v-btn>
          </v-row>

          <br />

          <!-- Starting Content of the Edit Form  -->

          <v-row align="center">
            <v-autocomplete
              v-model="product.categoryId"
              :items="categoryDropDownList"
              :label="$t('product.edit.input.general.category')"
              required
              dense
              :deletable-chips="true"
              :rules="validationRules.category"
              rounded
              clearable
              outlined
            ></v-autocomplete>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.nameEn"
              :label="$t('product.edit.input.general.nameEn')"
              required
              :rules="validationRules.nameEn"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.nameZh"
              :label="$t('product.edit.input.general.nameZh')"
              required
              :rules="validationRules.nameZh"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.nameKm"
              :label="$t('product.edit.input.general.nameKm')"
              required
              :rules="validationRules.nameKm"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.descriptionEn"
              :label="$t('product.edit.input.general.descriptionEn')"
              required
              :rules="validationRules.descriptionEn"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.descriptionZh"
              :label="$t('product.edit.input.general.descriptionZh')"
              required
              :rules="validationRules.descriptionZh"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.descriptionKm"
              :label="$t('product.edit.input.general.descriptionKm')"
              required
              :rules="validationRules.descriptionKm"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.price"
              :label="$t('product.edit.input.general.price')"
              required
              :rules="validationRules.price"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              type="number"
              shaped
              outlined
              v-model="product.sequence"
              :label="$t('product.edit.input.general.sequence')"
              required
              :rules="validationRules.sequence"
            ></v-text-field>
          </v-row>

          <v-file-input
            :label="$t('product.edit.input.general.s3Image')"
            filled
            show-size
            counter
            clearable
            v-model="product.s3Image"
          ></v-file-input>

          <!-- End Content of the Edit Form  -->
          <br />
        </v-card-text>
      </v-card>
      <br />

      <v-checkbox
        :label="$t('product.edit.input.feature.not_required')"
        :value="noProductFeatures"
        @click="toggleNoProductFeatures"
      >
      </v-checkbox>

      <v-card color="#BCAAA4" v-if="!noProductFeatures">
        <v-card-title style="
            flex-direction: column;
            align-items: flex-start;
        ">
          <div><b>{{ $t("product.edit.input.feature.title") }}</b></div>
          <div style="font-size: 1rem;">{{ $t("product.edit.input.feature.description") }}</div>
        </v-card-title>
        
        <v-tabs light v-model="tab" background-color="transparent" grow>
          <v-tab
            v-for="(productFeature, i) in product.productFeatures"
            :key="i"
          >
            {{ productFeature.value }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(productFeature, i) in product.productFeatures"
            :key="i"
          >
            <v-card color="#EFEBE9">
              <v-card-text>
                <v-row align="center">
                  <v-text-field
                    shaped
                    outlined
                    v-model="productFeature.value"
                    :label="$t('product.edit.input.feature.value')"
                    required
                    disabled
                  ></v-text-field>
                </v-row>

                <v-row align="center">
                  <v-text-field
                    shaped
                    outlined
                    v-model="productFeature.deltaPrice"
                    :label="$t('product.edit.input.feature.deltaPrice')"
                    required
                  ></v-text-field>
                </v-row>

                <v-row align="center">
                  <v-text-field
                    shaped
                    outlined
                    v-model="productFeature.sortingOrder"
                    :label="$t('product.edit.input.feature.sortingOrder')"
                    required
                  ></v-text-field>
                </v-row>

              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <br />

      <v-row justify="end" class="mr-8">
        <v-btn color="primary" outlined dark @click="resetAction" class="mx-2">
          {{ $t("common.btn.reset") }}
        </v-btn>
        <v-btn color="primary" dark @click.stop="submitDialog = true">
          {{ $t("common.btn.save") }}
        </v-btn>

        <ConfirmSubmitDialog v-model="submitDialog" @submitevent="submit" />
      </v-row>
    </v-form>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <AfterSubmitDialog
      v-model="afterSubmitDialog"
      :submitSuccess="submitSuccess"
      :errMsg="errMsg"
    />
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";
import AfterSubmitDialog from "../AfterSubmitDialog.vue";
import ConfirmSubmitDialog from "../ConfirmSubmitDialogNonBlockchain.vue";

export default {
  components: {
    AfterSubmitDialog,
    ConfirmSubmitDialog,
  },
  props: [],
  data() {
    return {
      loadingOverlay: false,
      afterSubmitDialog: false,
      submitSuccess: false,
      submitDialog: false,
      noProductFeatures: false,
      product: {},
      categoryList: [],
      categoryDropDownList: [],
      selectedContentType: "",
      tab: null,
      validationRules: {
        nameEn: [(v) => !!v || this.$t("product.edit.error.nameEn")],
        nameZh: [(v) => !!v || this.$t("product.edit.error.nameZh")],
        nameKm: [(v) => !!v || this.$t("product.edit.error.nameKm")],
        descriptionEn: [
          (v) => !!v || this.$t("product.edit.error.descriptionEn"),
        ],
        descriptionZh: [
          (v) => !!v || this.$t("product.edit.error.descriptionZh"),
        ],
        descriptionKm: [
          (v) => !!v || this.$t("product.edit.error.descriptionKm"),
        ],
        category: [(v) => !!v || this.$t("product.edit.error.category")],
        price: [(v) => !!v || this.$t("product.edit.error.price")],
        sequence: [
          (v) =>
            (v !== null && v !== undefined && v.toString().trim() !== "") ||
            this.$t("product.edit.error.sequence"),
        ],        type: [(v) => !!v || this.$t("product.edit.error.type")],
        display: [(v) => !!v || this.$t("product.edit.error.display")],
        s3Image: [(v) => !!v || this.$t("product.edit.error.s3Image")],
        deltaPrice: [(v) => !!v || this.$t("product.edit.error.deltaPrice")],
        value: [(v) => !!v || this.$t("product.edit.error.value")],
        sortingOrder: [
          (v) => !!v || this.$t("product.edit.error.sortingOrder"),
        ],
      },
      errMsg: this.$t("common.msg.error.edit.msg"),
    };
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
  },
  mounted: async function () {
    await this.getAllCategories();
    this.getProductDataForEdit();
    this.loadingOverlay = true;
  },
  methods: {
    toggleNoProductFeatures() {
      this.noProductFeatures = !this.noProductFeatures;
      if (!this.noProductFeatures && !this.product.productFeatures?.length) {
        this.product.productFeatures = [
          {
            type: "SIZE",
            value: "LARGE",
            deltaPrice: "0",
            sortingOrder: "0",
            active: true,
          },
          {
            type: "SIZE",
            value: "MEDIUM",
            deltaPrice: "0",
            sortingOrder: "1",
            active: true,
          },
          {
            type: "SIZE",
            value: "SMALL",
            deltaPrice: "0",
            sortingOrder: "2",
            active: true,
          },
        ];
      }
    },
    isFormValid() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    submit() {
      if (this.isFormValid()) {
        this.loadingOverlay = !this.loadingOverlay;
        this.submitDialog = false;
        this.updateProduct();
      } else {
        this.submitDialog = false;
        this.afterSubmitDialog = true;
      }
    },
    updateProduct() {
      this.product.productFeaturesJSONString = this.noProductFeatures ? "[]" : JSON.stringify(
        this.product.productFeatures
      );
      let category = this.categoryList.find(
        (category) => category.name === this.product.categoryId
      );
      // console.log({ category });
      this.product.categoryId = category.id;

      let productFormData = new FormData();
      productFormData.append("nameEn", this.product.nameEn);
      productFormData.append("nameZh", this.product.nameZh);
      productFormData.append("nameKm", this.product.nameKm);
      productFormData.append("descriptionEn", this.product.descriptionEn);
      productFormData.append("descriptionZh", this.product.descriptionZh);
      productFormData.append("descriptionKm", this.product.descriptionKm);
      productFormData.append("categoryId", this.product.categoryId);
      productFormData.append("price", this.product.price);
      productFormData.append("sequence", this.product.sequence);
      productFormData.append(
        "productFeaturesJSONString",
        this.product.productFeaturesJSONString
      );

      this.selectedContentType = "";
      if (this.product.s3Image !== null) {
        this.selectedContentType =
          "multipart/form-data;boundary = " + new Date().getTime();
        productFormData.append("s3Image", this.product.s3Image);

        myApi
          .updateProductWithPic(
            this.$route.params.productId,
            productFormData,
            this.selectedContentType
          )
          .then(() => {
            this.submitSuccess = true;
            this.afterSubmitDialog = true;
            this.resetValues();
            this.goToShowProductTablePage();
          })
          .catch(() => {
            this.afterSubmitDialog = true;
            this.submitSuccess = false;
            this.loadingOverlay = false;
          });
      } else {
        myApi
          .updateProduct(
            this.$route.params.productId,
            productFormData,
            this.selectedContentType
          )
          .then(() => {
            this.submitSuccess = true;
            this.afterSubmitDialog = true;
            this.resetValues();
            this.goToShowProductTablePage();
          })
          .catch(() => {
            this.afterSubmitDialog = true;
            this.submitSuccess = false;
            this.loadingOverlay = false;
          });
      }
      console.log("this.selectedContentType", this.selectedContentType);
    },
    async getAllCategories() {
     await myApi
        .getAllCategories()
        .then((response) => {
          this.categoryList = response.data.data;
          this.categoryDropDownList = this.categoryList
            .filter((cat) => cat.id !== -1)
            .map((category) => category.name);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOverlay = false));
    },
    getProductDataForEdit() {
      return myApi
        .getProductDataForEdit(this.$route.params.productId)
        .then((response) => {
          this.product = response.data.data;
          this.productFeatureList = this.product.productFeatures;
          if (this.product.category !== null) {
            let category = this.categoryList.find(
              (target) => target.id === this.product.categoryId
            );
            if (category !== undefined) {
              this.product.categoryId = category.name;
            }
          }
          this.loadingOverlay = false;

          this.noProductFeatures = !this.product.productFeatures?.length;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOverlay = false));
    },
    resetAction() {
      this.loadingOverlay = true;
      window.location.reload();
    },
    resetValues() {
      this.loadingOverlay = false;
      this.resetValidation();
    },
    goToShowProductTablePage() {
      console.log("Entered Show Product");
      this.$router.push({
        name: "Show Product Table",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    goToProductViewPage() {
      console.log("Entered View Product Page");
      this.$router.push({
        name: "View Product",
        params: {
          productId: this.$route.params.productId,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
  },
};
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: lightgray;
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
