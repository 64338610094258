<template>
  <div>
    <VoucherViewDetail />
  </div>
</template>

<script>
// @ is an alias to /src
import VoucherViewDetail from "@/components/Voucher/VoucherViewDetail.vue";

export default {
  props: {},
  name: "ViewVoucherForm",
  components: {
    VoucherViewDetail,
  },
  data() {
    return {};
  },
};
</script>
