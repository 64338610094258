<template>
  <center>
    <h1></h1>
    <p>
      <a href="/">Go home?</a>
    </p>
  </center>
</template>

<script>
import myApi from "../api/api.js";

export default {
  name: "XeroAuthorizeSuccess",
  mounted() {
    myApi.exchangeTokenWithXero(this.$route.query.code).then(response => {
      console.log(response);
    });
  }
};
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
</style>
