<template>
  <v-container>
    <b
      ><h3>
        {{ $t("order.view.title") }} : <u>{{ this.$route.params.orderId }}</u>
      </h3></b
    >

    <br />

    <v-row justify="end" class="mr-8">
      <v-btn
        color="primary"
        outlined
        class="mr-4"
        dark
        @click="goToShowOrderTablePage"
      >
        {{ $t("common.btn.back") }}
      </v-btn>
      <v-btn color="primary" class="mr-4" dark @click="goToEditOrderStatusPage">
        Change Status
        <v-icon dark class="ml-1"> mdi-swap-horizontal </v-icon>
      </v-btn>

      <template v-if="order.hasOwnProperty('paymentResult')">
        <v-btn v-if="order.paymentResult && order.paymentResult.status === 'PAID'" color="warning" class="mr-4" dark @click="showManualRefundConfirm = true">
        Refund
        <!-- <v-icon dark class="ml-1"> mdi-swap-horizontal </v-icon> -->
      </v-btn>
      <ConfirmSubmitDialog v-model="showManualRefundConfirm" @submitevent="manualRefund"
        :note="'Please ensure the customer has already received the refund!'"
        :title="'Are you sure to confirm refund?'"
      />

      <v-btn v-if="order.paymentResult && order.paymentResult.status === 'ACK'" color="info" class="mr-4" dark @click="showManualPayConfirm = true">
        Confirm Paid
        <!-- <v-icon dark class="ml-1"> mdi-swap-horizontal </v-icon> -->
      </v-btn>
      </template>
      <ConfirmSubmitDialog v-model="showManualPayConfirm" @submitevent="manualPay"
        :note="'Please ensure the customer has already paid for the order!'"
        :title="'Are you sure to confirm paid?'"
      />
    </v-row>

    <br />
    <v-card color="#CFD8DC">
      <v-card-title>
        <b>{{ $t("order.view.content.status.title") }}</b>
      </v-card-title>
      <v-card-text class="ml-4" color="black">
        <v-row>
          <ul>
            <li class="black--text subtitle-1">{{ order.status }}</li>
          </ul>
        </v-row>
      </v-card-text>
    </v-card>

    <br />
    <v-card color="#CFD8DC">
      <v-card-title>
        <b>{{ $t("order.view.content.address.title") }}</b>
      </v-card-title>
      <v-card-text class="ml-4">
        <v-row v-if="order.address !== null">
          <ul>
            <li class="black--text subtitle-1">{{ order.address }}</li>
          </ul>
        </v-row>
        <v-row v-if="order.address === null">
          <div align="center" class="black--text subtitle-1">N/A</div>
        </v-row>
      </v-card-text>
    </v-card>

    <br />

    <v-card color="#CFD8DC">
      <v-card-title>
        <b
          >{{ $t("order.view.content.selectedItemList.title") }} ({{
            numberOfSelectedItems
          }})</b
        >
      </v-card-title>

      <v-card-text class="ml-4">
        <v-col
          align="right"
          v-for="selectedItem in order.orderDetailList"
          :key="`item-${selectedItem.id}`"
        >
          <v-row>
            <li class="black--text subtitle-1">
              {{ $t("order.view.content.selectedItemList.productName") }} :
              {{ selectedItem.productName }}
            </li>
          </v-row>

          <v-row v-if="selectedItem.selectedFeatures !== null">
            <div
              align="right"
              v-for="selectedItemFeature in selectedItem.selectedFeatures"
              :key="`item-${selectedItemFeature.id}`"
            >
              <div v-if="selectedItemFeature.type === 'SIZE'">
                <li class="black--text subtitle-1">
                  {{ $t("order.view.content.selectedItemList.productSize") }}
                  :
                  {{ selectedItemFeature.value }}
                </li>
              </div>
            </div>
          </v-row>

          <v-row v-if="selectedItem.selectedFeatures !== null">
            <div
              align="right"
              v-for="selectedItemFeature in selectedItem.selectedFeatures"
              :key="`item-${selectedItemFeature.id}`"
            >
              <div v-if="selectedItemFeature.deltaPrice !== null">
                <li class="black--text subtitle-1">
                  {{
                    $t("order.view.content.selectedItemList.productDeltaPrice")
                  }}
                  :
                  {{ selectedItemFeature.deltaPrice }}
                </li>
              </div>
            </div>
          </v-row>

          <v-row>
            <li class="black--text subtitle-1">
              {{ $t("order.view.content.selectedItemList.productQuantity") }}
              :
              {{ selectedItem.productQuantity }}
            </li>
          </v-row>

          <v-row>
            <li class="black--text subtitle-1">
              {{ $t("order.view.content.selectedItemList.productPrice") }} :
              {{ selectedItem.productPrice }}
            </li>
          </v-row>

          <v-divider> </v-divider>
        </v-col>
      </v-card-text>
    </v-card>

    <br />

    <v-card color="#CFD8DC">
      <v-card-title>
        <b>{{ $t("order.view.content.voucherList.title") }}</b>
      </v-card-title>
      <v-card-text class="ml-4">
        <v-row v-if="order.voucher !== null">
          <li class="black--text subtitle-1">
            {{ $t("order.view.content.voucherList.label") }} :
            {{ voucher.label }}
          </li>
        </v-row>

        <v-row v-if="order.voucher !== null">
          <li class="black--text subtitle-1">
            {{ $t("order.view.content.voucherList.currencyCode") }} :
            {{ voucher.currencyCode }}
          </li>
        </v-row>

        <v-row v-if="order.voucher !== null">
          <li class="black--text subtitle-1">
            {{ $t("order.view.content.voucherList.priceDecreasingType") }} :
            {{ voucher.priceDecreasingType }}
          </li>
        </v-row>

        <v-row v-if="order.voucher !== null">
          <li class="black--text subtitle-1">
            {{ $t("order.view.content.voucherList.priceDecreasedVal") }} :
            {{ voucher.priceDecreasedVal }}
          </li>
        </v-row>

        <v-row v-if="order.voucher !== null">
          <li class="black--text subtitle-1">
            {{ $t("order.view.content.voucherList.expiryDate") }} :
            {{ voucher.expiryDate }}
          </li>
        </v-row>

        <v-row v-if="order.voucher === null">
          <div align="center" class="black--text subtitle-1">N/A</div>
        </v-row>
      </v-card-text>
    </v-card>

    <br />

    <v-card color="#CFD8DC">
      <v-card-title>
        <b>{{ $t("order.view.content.orderSummary.title") }}</b>
      </v-card-title>
      <v-card-text class="ml-4">
        <v-row>
          <li class="black--text subtitle-1">
            {{ $t("order.view.content.orderSummary.subTotal") }} :
            {{ itemsSubTotal }}
          </li>
        </v-row>

        <v-row v-if="order.voucher !== null">
          <li class="black--text subtitle-1">
            {{ $t("order.view.content.orderSummary.voucherTotal") }} : -
            {{ voucher.priceDecreasedVal }}
          </li>
        </v-row>

        <v-row v-if="order.deliveryFee !== null">
          <li class="black--text subtitle-1">
            {{ $t("order.view.content.orderSummary.deliveryFee") }} : +
            {{ order.deliveryFee }}
          </li>
        </v-row>

        <v-row>
          <li class="black--text subtitle-1">
            {{ $t("order.view.content.orderSummary.finalPrice") }} :
            {{ order.finalPrice }}
          </li>
        </v-row>
      </v-card-text>
    </v-card>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";
import ConfirmSubmitDialog from "../ConfirmSubmitDialogNonBlockchain.vue";

export default {
  components: {
    ConfirmSubmitDialog
  },
  props: [],
  data() {
    return {
      loadingOverlay: false,
      order: {},
      voucher: {},
      itemsSubTotal: 0,
      deltaPriceTotal: 0,
      numberOfSelectedItems: Number,
      errMsg: this.$t("common.msg.error.edit.msg"),
      showManualPayConfirm: false,
      showManualRefundConfirm: false,
    };
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType;
    },
    // voucherExpiryDateFormat() {
    //   console.log("Entered Computed");
    //   console.log("Alter Date Format: ", this.order.voucher.expiryDate);
    //   let convertedDate = new Date(this.order.voucher.expiryDate);
    //   return `${convertedDate.toISOString().slice(0, 10)} ${convertedDate
    //     .toISOString()
    //     .slice(11, 19)}`;
    // },
  },
  mounted() {
    console.log("Start to get Order Detailed Data");
    this.getOrderData();
    this.loadingOverlay = true;
  },
  methods: {
    manualPay() {
      this.showManualPayConfirm = false;
      myApi.paymentManualPay(this.order.paymentRef).then(() => {
        return this.getOrderData();
      });
    },
    manualRefund() {
      this.showManualRefundConfirm = false;
      myApi.paymentManualRefund(this.order.paymentRef, this.order.finalPrice, "n/a").then(() => {
        return this.getOrderData();
      });
    },
    getOrderData() {
      myApi
        .getOrderData(this.$route.params.orderId)
        .then((response) => {
          this.order = response.data.data || {};
          // console.log("Selected Order Voucher: ", this.order.voucher);
          this.numberOfSelectedItems = this.order.orderDetailList?.length;

          if (this.order.orderDetailList?.length) {
            this.order.orderDetailList.forEach((orderDetail) => {
              // console.log({ orderDetail });
              if (
                orderDetail.selectedFeatures !== null &&
                orderDetail.selectedFeatures.length !== 0
              ) {
                let selectedDeltaPriceList = orderDetail.selectedFeatures.map(
                  (x) => x.deltaPrice
                );
                this.deltaPriceTotal +=
                  selectedDeltaPriceList[0] * orderDetail.productQuantity;
              }

              this.itemsSubTotal +=
                orderDetail.productQuantity * orderDetail.productPrice;
            });
          }
          if (this.order.voucher !== null) {
            let convertedDate = new Date(this.order.voucher.expiryDate);
            this.order.voucher.expiryDate = `${convertedDate
              .toISOString()
              .slice(0, 10)} ${convertedDate.toISOString().slice(11, 19)}`;
            this.voucher = this.order.voucher;
          }
          this.itemsSubTotal += this.deltaPriceTotal;
          this.loadingOverlay = false;
          // console.log("deltaPriceTotal", this.deltaPriceTotal)
          // console.log(" this.itemsSubTotal", this.itemsSubTotal);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOverlay = false));
    },
    goToShowOrderTablePage() {
      console.log("Entered Show Order");
      this.$router.push({
        name: "Show Order Table",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    goToEditOrderStatusPage() {
      console.log("Entered Edit Order Status Page");
      console.log("Selected Order ID For Edit: ", this.$route.params.orderId);
      this.$router.push({
        name: "Edit Order Status",
        params: {
          orderId: this.$route.params.orderId,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
  },
};
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: rgb(32, 27, 27);
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
