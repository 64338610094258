<template>
  <v-navigation-drawer
    app
    permanent
    expand-on-hover
    :color="color"
    clipped
    :width="340"
    :mini-variant.sync="miniClose"
    @update:mini-variant="log"
  >
    <v-list>
      <br />
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ adjustRole | capitalize }}
          </v-list-item-title>
          <v-list-item-subtitle class="black--text mt-1">
            Staff Number: <u>{{ staffId }}</u></v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider> </v-divider>
    <v-list>
      <v-list-group
        v-for="item in showcaseList"
        :key="item.title"
        :prepend-icon="item.action"
        no-action
        class="mt-3"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold"
              v-text="item.title"
            ></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="child in item.items" :key="child.title" dense>
          <v-list-item-content @click="call(child.clickAction)">
            <v-list-item-title
              style="cursor: pointer"
              class="text-overline"
              v-text="child.title"
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="child.icon"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <v-list-item v-if="showAdminVision">
        <v-list-item-icon>
          <v-icon>{{ individual.action }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title
          style="cursor: pointer"
          class="font-weight-bold mr-8"
          v-text="individual.title"
          @click="call(individual.clickAction)"
        ></v-list-item-title>
      </v-list-item>
    </v-list>

    <template v-slot:append v-if="miniClose === false">
      <div class="pa-2 mb-5">
        <v-btn rounded block @click="signout" color="#BCAAA4">
          {{ supplements.title }}
          <v-icon right dark> {{ supplements.icon }} </v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideBarNavigation",
  data() {
    return {
      showAdminVision: false,
      // showcaseList: [],
      individual: {
        title: this.$t("nav.sideBar.setting.barName"),
        action: "mdi-settings",
        clickAction: "setting",
      },
      sideBarItems: [
        {
          title: this.$t("nav.sideBar.orderManagement.barName"),
          action: "mdi-clipboard-text",
          allowedAccessRoles: ["admin", "kitchen", "delivery"],
          items: [
            {
              title: this.$t("nav.sideBar.orderManagement.childBar.listTable"),
              clickAction: "order",
              icon: "mdi-format-list-bulleted",
            },
          ],
        },
        {
          title: this.$t("nav.sideBar.productManagement.barName"),
          action: "mdi-food-fork-drink",
          allowedAccessRoles: ["admin", "kitchen"],
          items: [
            {
              title: this.$t(
                "nav.sideBar.productManagement.childBar.listTable"
              ),
              clickAction: "product",
              icon: "mdi-format-list-bulleted",
            },
            {
              title: this.$t(
                "nav.sideBar.productManagement.childBar.createProduct"
              ),
              clickAction: "createProduct",
              icon: "mdi-new-box",
            },
          ],
        },
        {
          title: this.$t("nav.sideBar.newsManagement.barName"),
          action: "mdi-newspaper",
          allowedAccessRoles: ["admin"],
          items: [
            {
              title: this.$t("nav.sideBar.newsManagement.childBar.listTable"),
              clickAction: "news",
              icon: "mdi-format-list-bulleted",
            },
            {
              title: this.$t("nav.sideBar.newsManagement.childBar.createNews"),
              clickAction: "createNews",
              icon: "mdi-new-box",
            },
          ],
        },
        {
          title: "Category",
          action: "mdi-shape",
          allowedAccessRoles: ["admin"],
          items: [
            {
              title: "Main Category",
              clickAction: "category",
              icon: "mdi-shape-outline",
            },
            {
              title: "Sub Category",
              clickAction: "subCategory",
              icon: "mdi-new-box",
            },
          ],
        },
        {
          title: this.$t("nav.sideBar.voucherManagement.barName"),
          action: "mdi-gift",
          allowedAccessRoles: ["admin"],
          items: [
            {
              title: this.$t(
                "nav.sideBar.voucherManagement.childBar.listTable"
              ),
              clickAction: "voucher",
              icon: "mdi-format-list-bulleted",
            },
            {
              title: this.$t(
                "nav.sideBar.voucherManagement.childBar.createVoucher"
              ),
              clickAction: "createVoucher",
              icon: "mdi-new-box",
            },
          ],
        },
        {
          title: this.$t("nav.sideBar.postManagement.barName"),
          action: "mdi-comment-processing-outline",
          allowedAccessRoles: ["admin"],
          items: [
            {
              title: this.$t("nav.sideBar.postManagement.childBar.listTable"),
              clickAction: "post",
              icon: "mdi-format-list-bulleted",
            },
          ],
        },
        {
          title: this.$t("nav.sideBar.loyaltyManagement.barName"),
          action: "mdi-star",
          allowedAccessRoles: ["admin"],
          items: [
            {
              title: this.$t(
                "nav.sideBar.loyaltyManagement.childBar.listTable"
              ),
              clickAction: "rewardHistory",
              icon: "mdi-format-list-bulleted",
            },
          ],
        },
        {
          title: this.$t("nav.sideBar.customerManagement.barName"),
          action: "mdi-account-card-details",
          allowedAccessRoles: ["admin"],
          items: [
            {
              title: this.$t(
                "nav.sideBar.customerManagement.childBar.listTable"
              ),
              clickAction: "rewardAccounts",
              icon: "mdi-format-list-bulleted",
            },
            // {
            //   title: this.$t(
            //     "Customer voucher"
            //   ),
            //   clickAction: "customerVoucher",
            //   icon: "mdi-format-list-bulleted",
            // },
            // {
            //   title: this.$t(
            //     "nav.sideBar.customerManagement.childBar.profileTable"
            //   ),
            //   clickAction: "profileAccounts",
            //   icon: "mdi-format-list-bulleted",
            // },
          ],
        },
      ],
      supplements: {
        title: this.$t("nav.sideBar.logout.barName"),
        icon: "mdi-logout",
      },
      color: "#EFEBE9",
      miniClose: undefined,
      log: (v) => console.log(v),
    };
  },
  created() {
    // var uniq = {};
    if (this.userRole.includes("admin")) {
      this.showAdminVision = true;
    }
    // this.sideBarItems.forEach((item) => {
    //   item.allowedAccessRoles.forEach((allowedRole) => {
    //     if (this.userRole.includes(allowedRole)) {
    //       this.showcaseList.push(item);
    //     }
    //   });
    // });
    // this.showcaseList = this.showcaseList.filter(
    //   (obj) => !uniq[obj.title] && (uniq[obj.title] = true)
    // );
    // if (!this.userRole.includes("admin")) {
    //   this.sideBarItems.forEach((item) => {
    //     item.items = item.items.filter(
    //       (subTitle) => !subTitle.clickAction.includes("create")
    //     );
    //   });
    // }
  },
  methods: {
    mini() {
      return !(this.mdAndUp || this.menuOpen);
    },
    call(methodName) {
      console.log("Entered Method Call: ", methodName);
      this[methodName]();
    },
    signout() {
      sessionStorage.clear();
      window.location.href = "/nukcafe/api/v1/oauth2/logout";
    },
    gotoHome() {
      if (this.$store.state.userInfo.isLogin) {
        this.$router
          .push({ path: `/${this.$root.$i18n.locale}/` })
          .catch(() => {});
      } else {
        this.$router.push(`/${this.$root.$i18n.locale}/`).catch(() => {});
      }
    },
    order() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/order`,
        })
        .catch(() => {});
    },
    product() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/product`,
        })
        .catch(() => {});
    },
    createProduct() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/product/create`,
        })
        .catch(() => {});
    },
    post() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/post`,
        })
        .catch(() => {});
    },
    voucher() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/voucher`,
        })
        .catch(() => {});
    },
    customerVoucher() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/customer-voucher`,
        })
        .catch(() => { });
    },
    createVoucher() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/voucher/create`,
        })
        .catch(() => {});
    },
    news() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/news`,
        })
        .catch(() => {});
    },
    createNews() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/news/create`,
        })
        .catch(() => {});
    },
    rewardHistory() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/reward`,
        })
        .catch(() => {});
    },
    rewardAccounts() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/customer/rewardAccount`,
        })
        .catch(() => {});
    },
    profileAccounts() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/customer/profileAccounts`,
        })
        .catch(() => {});
    },
    setting() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/setting`,
        })
        .catch(() => {});
    },
    category() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/mainCategory`,
        })
        .catch(() => { });
    },
    subCategory() {
      this.$router
        .push({
          path: `/${this.$root.$i18n.locale}/${this.$store.state.userInfo.userType}/subCategory`,
        })
        .catch(() => { });
    },
  },
  computed: {
    display() {
      return this.$store.state.userInfo.display;
    },
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
    userRole() {
      return this.$store.state.userInfo.userRole;
    },
    staffId() {
      return this.$store.state.userInfo.staffId;
    },
    isLogin() {
      return this.$store.state.userInfo.isLogin;
    },
    adjustRole() {
      return this.userRole.filter((role) => role !== "staff").join(" / ");
    },
    showcaseList() {
      var uniq = {};
      let _showcaseList = [];

      this.sideBarItems.forEach((item) => {
        item.allowedAccessRoles.forEach((allowedRole) => {
          if (this.userRole.includes(allowedRole)) {
            _showcaseList.push(item);
          }
        });
      });

      _showcaseList = _showcaseList.filter(
        (obj) => !uniq[obj.title] && (uniq[obj.title] = true)
      );

      if (!this.userRole.includes("admin")) {
        this.sideBarItems.forEach((item) => {
          item.items = item.items.filter(
            (subTitle) => !subTitle.clickAction.includes("create")
          );
        });
      }
      return _showcaseList;
    },
  },
};
</script>

<style lang="css" scoped>
</style>
