<template>
  <v-card class="my-10">
    <v-cart-title>
      <b>
        Customer Voucher
      </b>
    </v-cart-title>

    <!-- <pre><code>{{ voucherStatus }}</code></pre> -->

    <v-card color="#CFD8DC" class="mx-10">
      <v-card-text class="ml-4">
        <v-row class="h5 black--text mt-3">
          Customer ID : {{ voucherObj.customerID }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Customer Name : {{ voucherObj.customerFullName }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Phone number : {{ voucherObj.customerPhoneNumber }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Customer Address : {{ voucherObj.customerAddress }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Total voucher : {{ voucherObj.totalVouchers }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          Total available : {{ voucherObj.totalAvailable }}
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mx-10 my-10">
      <v-cart-title class="mx-10">
        <b>
          Voucher List
        </b>
      </v-cart-title>

      <v-data-table
        :headers="headers"
        :items="voucherList"
        :items-per-page="20"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :expanded.sync="expanded"
        style="cursor: pointer"
        :single-expand="singleExpand"
        item-key="id"
        :loading="runLoading"
        loading-text="Loading... Please wait"
        :search="search"
        :custom-filter="filterText"
        show-group-by
        group-desc
        class="elevation-1 my-data-table"
      >
        <template v-slot:top>
          <v-text-field
            append-icon="mdi-magnify"
            v-model="search"
            :label="$t('product.listingTable.search')"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:item.actions="{ item }">
          <tr>
            {{
              item.actions
            }}
              <v-checkbox v-model="checkboxCheckedMap()[item.id]"
              @change="onUpdate(checkboxCheckedMap()[item.id])"
              @click="getVoucherID(item.id)"
              ></v-checkbox>
          </tr>
        </template>

        <template v-slot:no-data> {{ $t("common.msg.emtpyTable") }} </template>
      </v-data-table>
    </v-card>
  </v-card>
</template>

<script>
import myApi from "../../api/api.js"

export default {
  props: [],
  data() {
    return {
      headers: [],
      voucherObj: {
        customerID: "",
        customerFullName: "",
        customerPhoneNumber: "",
        customerAddress: "",
        totalVouchers: "",
        totalAvailable: "",
        voucherList: [],
      },
      voucherList: [],
      voucherStatus: [],
      checkMap: {},
    }
  },
  mounted: async function () {
    this.getCustomerVoucher();
    this.getHeader();
    this.checkboxCheckedMap()
  },
  methods: {
    getHeader() {
      this.headers = [
        {
          text: "ID",
          value: "id",
          groupable: false,
        },
        {
          text: "Label",
          value: "label",
          groupable: false,
        },
        {
          text: "Title",
          value: "title",
          groupable: false,
        },
        {
          text: "Description",
          value: "description",
          groupable: false,
        },
        {
          text: "Currency Code",
          value: "currencyCode",
          groupable: false,
        },
        {
          text: "Price Decreasing Type",
          value: "priceDecreasingType",
          groupable: false,
        },
        {
          text: "Price Decreased Value",
          value: "priceDecreasedVal",
          groupable: false,
        },
        {
          text: "Price Decreased Pct",
          value: "priceDecreasedPct",
          groupable: false,
        },
        {
          text: "Action",
          value: "actions",
          groupable: true,
        },
        {
          text: "Status",
          value: "isVisible",
          groupable: true,
        },
      ]
    },

    onUpdate(value) {
      if(value == true) {
        this.voucherStatus = false
      } else {
        this.voucherStatus = true
      }
    },

    getCustomerVoucher() {
      let id = this.$route.params.customerId
      myApi.voucherCustomer(id).then((res) => {
        this.voucherObj = res.data.data
        this.voucherList = res.data.data.voucherList;
      })
    },

    checkboxCheckedMap() {
      this.voucherList.forEach(element => {
        this.checkMap[element.id] = element.isVisible
      });

      return this.checkMap
    },

    getVoucherID(id) {
      if (this.voucherStatus == true) {
        myApi.enableVoucher(id).then((res) => {
          console.log("res", res.data.data)
          if(res.data.data == true) {
            this.$router.push({
              name: "Show Customer Reward Account Table",
            });
          } else {
            this.$toast.error("Enable voucher failed")
          }
        });
      } else {
        myApi.disableVoucher(id).then((res) => {
          if(res.data.data == true) {
            this.$router.push({
              name: "Show Customer Reward Account Table",
            });
          } else {
            this.$toast.error("Disable voucher failed")
          }
        });
      }
    }
  },
}
</script>