<template>
  <div>
    <ProductDetailEdit />
  </div>
</template>

<script>
// @ is an alias to /src
import ProductDetailEdit from "@/components/Product/ProductDetailEdit.vue";

export default {
  name: "EditProductForm",
  components: {
    ProductDetailEdit,
  },
  data() {
    return {};
  },
};
</script>
 