<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title v-if="checkCategory == 'Sub'">
          <b>Update Sub Category: {{ $route.params.categoryId }}</b>
        </v-card-title>
        <v-card-title v-else>
          <b>Update Main Category: {{ $route.params.categoryId }}</b>
        </v-card-title>
        <v-card-text class="ml-4">
          <v-row justify="end" class="mr-8">
            <v-btn
              color="primary"
              outlined
              class="mr-4"
              dark
              @click="goToShowProductTablePage"
            >
              {{ $t("common.btn.back") }}
            </v-btn>
          </v-row>
          <br />
          <!-- Starting Content of the Create Form  -->
          <v-row align="center" v-if="checkCategory == 'Sub'">
            <v-autocomplete
              dense
              v-model="mainCategoryId"
              :items="mainCategoryList"
              :label="$t('product.create.input.general.category')"
              required
              rounded
              :deletable-chips="true"
              :rules="validationRules.category"
              clearable
              outlined
            ></v-autocomplete>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="category.name"
              label="Category name"
              required
              :rules="validationRules.name"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="category.displayEn"
              label="Category name (English)"
              required
              :rules="validationRules.nameZh"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="category.displayKm"
              label="Category name (Khmer)"
              required
              :rules="validationRules.nameKm"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="category.displayZh"
              label="Category name (Chinese)"
              required
              :rules="validationRules.descriptionEn"
            ></v-text-field>
          </v-row>

          <br />
        </v-card-text>
      </v-card>
      <br />

      <v-row justify="end" class="mr-8">
        <v-btn
          color="primary"
          outlined
          dark
          @click="goToShowProductTablePage"
          class="mx-2"
        >
          {{ $t("common.btn.cancel") }}
        </v-btn>
        <v-btn color="primary" dark @click.stop="submitDialog = true">
          {{ $t("common.btn.save") }}
        </v-btn>

        <ConfirmSubmitDialog v-model="submitDialog" @submitevent="submit" />
      </v-row>
    </v-form>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <AfterSubmitDialog
      v-model="afterSubmitDialog"
      :submitSuccess="submitSuccess"
      :errMsg="errMsg"
    />
  </v-container>
</template>

<script>
import myApi from "../../api/api.js"
import AfterSubmitDialog from "../AfterSubmitDialog.vue"
import ConfirmSubmitDialog from "../ConfirmSubmitDialogNonBlockchain.vue"

export default {
  components: {
    AfterSubmitDialog,
    ConfirmSubmitDialog,
  },
  props: [],
  data() {
    return {
      checkCategory: "",
      mainCategoryId: "",
      mainCategoryList: [],
      mainCategory: [],
      loadingOverlay: false,
      afterSubmitDialog: false,
      submitSuccess: false,
      submitDialog: false,
      category: {
        name: "",
        displayEn: "",
        displayKm: "",
        displayZh: "",
      },
      tab: null,
      validationRules: {
        nameEn: [(v) => !!v || "Category name (English)is required"],
        nameZh: [(v) => !!v || "Category name (Chinese) is required"],
        nameKm: [(v) => !!v || "Category name (Khmer) is required"],
        name: [(v) => !!v || "Category name is required"],
      },
      errMsg: this.$t("common.msg.error.create.msg"),
    }
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType
    },
  },
  mounted: async function() {
    this.loadingOverlay = true
    this.loadingOverlay = false
    await this.getMainCategory()
    this.checkCategory = this.$route.params.userType
    if (this.checkCategory == "Sub") {
      console.log("Sub Category", this.$route.params.categoryId)
      this.getSubCategoryDetail(this.$route.params.categoryId)
    } else {
      this.getCategoryDetail(this.$route.params.categoryId)
    }
  },
  methods: {
    isFormValid() {
      return this.$refs.form.validate()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    submit() {
      if (this.isFormValid()) {
        this.loadingOverlay = !this.loadingOverlay
        this.submitDialog = false
        if (this.checkCategory == "Sub") {
          this.updateSubCategory(this.$route.params.categoryId)
        } else {
          this.updateMainCategory(this.$route.params.categoryId)
        }
      } else {
        this.submitDialog = false
        this.afterSubmitDialog = true
      }
    },
    getAllCategories() {
      console.log("Start Getting Category List")
      myApi
        .getAllCategories()
        .then((response) => {
          this.categoryList = response.data.data
          this.categoryDropDownList = this.categoryList
            .filter((cat) => cat.id !== -1)
            .map((category) => category.name)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => (this.runLoading = false))
    },
    resetValues() {
      this.loadingOverlay = false
      this.resetValidation()
    },
    goToShowProductTablePage() {
      if (this.checkCategory == "Sub") {
        this.$router.push({
          name: "subCategory",
          params: {
            locale: this.$root.$i18n.locale,
            userType: this.userType,
          },
        })
      } else {
        this.$router.push({
          name: "Main Category",
          params: {
            locale: this.$root.$i18n.locale,
            userType: this.userType,
          },
        })
      }
    },
    updateSubCategory(id) {
      var newCategoryID = this.mainCategory.find(
        (category) => category.name == this.mainCategoryId
      )

      const newCategory = {
        displayEn: this.category.displayEn,
        displayKm: this.category.displayKm,
        displayZh: this.category.displayZh,
        mainCategoryId: newCategoryID.id,
        name: this.category.name,
      }

      myApi.updateSubCategory(id, newCategory).then((res) => {
        console.log("createSubCategory: ", res)
        this.submitSuccess = false
        this.loadingOverlay = false
        this.$router.push({
          name: "subCategory",
          params: {
            locale: this.$root.$i18n.locale,
            userType: this.userType,
          },
        })
      })
    },
    updateMainCategory(id) {
      myApi.updateMainCategory(id, this.category).then((res) => {
        console.log("createMainCategory: ", res)
        this.submitSuccess = false
        this.loadingOverlay = false
        this.$router.push({
          name: "Main Category",
          params: {
            locale: this.$root.$i18n.locale,
            userType: this.userType,
          },
        })
      })
    },
    getMainCategory() {
      myApi
        .getMainCategory()
        .then((response) => {
          this.mainCategory = response.data.data
          this.mainCategoryList = this.mainCategory
            .filter((cat) => cat.id !== -1)
            .map((category) => category.name)
          console.log("this.mainCategoryList: ", response.data.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => (this.runLoading = false))
    },
    getSubCategoryDetail(categoryId) {
      myApi.getSubCategoryDetail(categoryId).then((response) => {
        this.category = response.data.data;
        this.mainCategoryId = this.category.mainCategoryName;
        this.getAllMainCategory()
        console.log("Sub category =>: ", this.categoryDetail)
      })
    },
    getCategoryDetail(categoryId) {
      myApi
        .getMainCategoryById(categoryId)
        .then((response) => {
          this.category = response.data.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => (this.runLoading = false))
    },
  },
}
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: lightgray;
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
