<template>
  <v-dialog v-model="show" max-width="400">
    <v-flex>
      <v-card>

        <v-card-title  align="left">
         Alert
        </v-card-title>

        <v-card-text class="black--text" v-if="productStatus"  align="left">
          <div>
            Are you sure to change {{ productName }} to <u><b>Offline</b></u> ?
          </div>
        </v-card-text>

        <v-card-text class="black--text" v-if="!productStatus" align="left">
          <div>
            Are you sure to change {{ productName }} to <u><b>Online</b></u> ?
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="submit">
            {{ $t("common.btn.yes") }}
          </v-btn>

          <v-btn color="green darken-1" text @click.stop="show = false">
            {{ $t("common.btn.no") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    productName: String,
    productStatus: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    submit() {
      this.$emit("submitevent");
    },
  },
};
</script>
