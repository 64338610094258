var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('ConfirmSubmitDialog',{on:{"submitevent":_vm.submit},model:{value:(_vm.submitDialog),callback:function ($$v) {_vm.submitDialog=$$v},expression:"submitDialog"}}),_c('v-card-title',[_c('b',[_vm._v(_vm._s(_vm.$t("voucher.listingTable.title")))]),_c('v-row',{staticClass:"mr-8",attrs:{"justify":"end"}},[_c('button',{staticClass:"mx-4 v-btn v-btn--contained theme--dark v-size--default primary",attrs:{"disabled":!_vm.voucherIds.length,"type":"button"},on:{"click":_vm.isShowSubmitDialog}},[_c('span',{staticClass:"v-btn__content"},[_vm._v(" Remove voucher "),_c('i',{staticClass:"v-icon notranslate ml-1 mdi mdi-delete theme--dark",attrs:{"aria-hidden":"true"}})])]),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.goToCreateVoucherPage}},[_vm._v(" "+_vm._s(_vm.$t("voucher.listingTable.addVoucher"))+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 my-data-table",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.voucherTableData,"items-per-page":20,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"expanded":_vm.expanded,"single-expand":_vm.singleExpand,"item-key":"id","loading":_vm.runLoading,"loading-text":"Loading... Please wait","search":_vm.search,"custom-filter":_vm.filterText,"show-group-by":"","group-desc":"","footer-props":{
        'items-per-page-options': [10, 20, 30, 40, 50, -1],
      }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.myRowClickHandler},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('voucher.listingTable.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("common.msg.emtpyTable"))+" ")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("common.msg.noDataFound"))+" ")]},proxy:true},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.label",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"item.priceDecreasingType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.priceDecreasingType)+" ")]}},{key:"item.validityPeriodDays",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.validityPeriodDays)+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"width":"100%","margin":"0 8px","height":"100%","display":"flex","align-items":"center"},on:{"click":function($event){$event.stopPropagation();return _vm.checkDelete(item.id)}}},[_vm._v(" "+_vm._s(item.actions)+" "),_c('input',{attrs:{"type":"checkbox","name":item.id},domProps:{"value":item.id,"checked":_vm.voucherIds.includes(item.id)}})])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }