<template>
  <v-dialog v-model="showDialog" max-width="500" persistent>
    <v-card class="mx-auto" elevation="2">
      <!-- Close Button (X Icon) -->
      <v-card-title class="d-flex justify-end">
        <v-btn icon @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        <img
          src="@/assets/9422343.png"
          alt="9422343-icon"
          width="80"
        />

        <!-- Success Message -->
        <h6 class="success-text my-3">
          {{ notification.title || "Notification" }}
        </h6>
        <p>{{ notification.body || "You have a notification!" }}</p>

        <!-- Continue Button -->
        <v-btn color="primary" @click="viewOrder">View Order</v-btn>
      </v-card-text>
      <v-progress-linear
        :value="progress"
        height="6"
        color="primary"
      ></v-progress-linear>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    notification: {
      type: Object,
      default: () => ({
        title: "Notification",
        body: "You have a notification!",
      }),
    },
  },
  data() {
    return {
      showDialog: this.value,
      progress: 0,
      intervalId: null,
    };
  },
  watch: {
    value(newValue) {
      this.showDialog = newValue;
      if (newValue) {
        this.startProgress();
        this.autoCloseDialog();
      } else {
        this.resetProgress();
      }
    },
    showDialog(newValue) {
      this.$emit("input", newValue);
      if (!newValue) {
        this.resetProgress();
      }
    },
  },
  methods: {
    viewOrder() {
      const orderId = this.$props.notification.title.match(/\d+/)[0];
      this.$router.push({
        name: "View Order",
        params: {
          orderId: orderId,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
      this.showDialog = false;
    },
    startProgress() {
      this.progress = 0;
      this.intervalId = setInterval(() => {
        if (this.progress < 100) {
          this.progress += 1;
        } else {
          clearInterval(this.intervalId);
        }
      }, 60);
    },
    autoCloseDialog() {
      setTimeout(() => {
        this.showDialog = false;
      }, 6000);
    },
    resetProgress() {
      clearInterval(this.intervalId);
      this.progress = 0;
    },
  },
};
</script>
