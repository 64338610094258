import axios from 'axios'
import { i18n } from '../i18n'

const Trans = {
    
    get defaultLocale() {
        return process.env.VUE_APP_I18N_LOCALE
        // if (this.currentLocale === 'undefined') {
        //     console.log("Current Locale undefined")
        //     return process.env.VUE_APP_I18N_LOCALE
        // } else {
        //     console.log("Current Locale exists")
        //     return this.currentLocale
        // }
    },
    get supportedLocales() {
        return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    },
    get currentLocale() {
        return i18n.locale
    },
    set currentLocale(locale) {
        i18n.locale = locale
    },
    getUserSupportedLocale() {
        const userPreferredLocale = Trans.getUserLocale()

        if (Trans.isLocaleSupported(userPreferredLocale.locale)) {
            return userPreferredLocale.locale
        }

        if (Trans.isLocaleSupported(userPreferredLocale.localeNoISO)) {
            return userPreferredLocale.localeNoISO
        }
        return Trans.defaultlocale
    },
    getUserLocale() {
        const locale = window.navigator.language || window.navigator.userLanguage || Trans.defaultLocale
        return {
            locale: locale,
            localeNoISO: locale.split('-')[0]
        }
    },
    setI18nLocaleInServices(locale) {
        Trans.currentLocale = locale
        axios.defaults.headers.common['Accept-Language'] = locale
        document.querySelector('html').setAttribute('lang', locale)
        return locale
    },
    changeLocale(locale) {
        // console.log("Change Locale To", locale)
        // console.log("Check If Locale is Supported in ", process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','))
        if (!Trans.isLocaleSupported(locale)) return Promise.reject(new Error('Locale not supported'))
        // console.log("Locale Supported!")
        // console.log("i18n.locale", i18n.locale)
        if (i18n.locale === locale) return Promise.resolve(locale)
        return Trans.loadLocaleFile(locale).then(msgs => {
            // console.log("Setting Latest Locale")
            i18n.setLocaleMessage(locale, msgs.default || msgs)
            return Trans.setI18nLocaleInServices(locale)
        })
    },
    loadLocaleFile(locale) {
        return import(`@/locales/${locale}.json`)
    },
    isLocaleSupported(locale) {
        return Trans.supportedLocales.includes(locale)
    },
    routeMiddleware(to, from, next) {
        const locale = to.params.locale
        // console.log("RouteMiddleWare Locale", locale)
        if (!Trans.isLocaleSupported(locale)) return next(Trans.getUserSupportedLocale())
        return Trans.changeLocale(locale).then(() => next())
    },
    i18nRoute(location) {
        // console.log("i18nRoute", location)
        // console.log("i18nRoute this.currentLocale", this.currentLocale)
        if (this.currentLocale === "en") {
            // console.log("i18nRoute To EN")
            location.params.locale = "en"
            location.path.replace(`/zh/`, `/en/`)
        }

        if (this.currentLocale === "zh") {
            // console.log(" i18nRoute To ZH ")
            location.params.locale = "zh"
            location.path.replace(`/en/`, `/zh/`)
        }
        return location;
        // return {
        //   ...to,
        //   params: {
        //     locale: this.currentLocale,
        //     ...to.params
        //   }
        // }
    }
}

export {
    Trans
}