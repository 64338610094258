<template>
  <v-dialog v-model="show" max-width="500" persistent>
    <v-flex>
      <v-card color="#FFECB3">
        <v-card-title class="ml-3" align="left">
          <b :style="{ color: ' #d61515'}">Alert</b>
        </v-card-title>

        <v-card-text class="black--text h2">
          <div class="alertMsg">
            There are [ {{ latestOrderAmount }} ] New Order(s) Arrived !
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="submit">
            {{ $t("common.btn.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    latestOrderAmount: Number,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    submit() {
      this.$emit("submitevent");
    },
  },
};
</script>

<style scoped>
.add-text {
  color: #00c853;
  margin-right: 3px;
}

.alertMsg {
  font-size: 1.25em;
  font-weight: bold;
}
</style>
