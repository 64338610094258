<template>
  <div id="qr-code-full-region"></div>
</template>

<script>
export default {
  props: {
    qrbox: {
      type: Number,
      default: 250
    },
    fps: {
      type: Number,
      default: 10
    },
  },
  html5QrCode: null,
  mounted () {
    const config = {
      fps: this.fps,
      qrbox: this.qrbox,
    };

    const html5QrCode = new window.Html5Qrcode('qr-code-full-region');
    html5QrCode.start({ facingMode: "environment" }, config, this.onScanSuccess);
  },
  beforeDestroy() {
    this.html5QrCode?.stop();
  },
  methods: {
    onScanSuccess (decodedText, decodedResult) {
      this.$emit('result', decodedText, decodedResult);
    }
  }
}
</script>

<style>

</style>