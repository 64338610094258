<template>
  <v-card>
    <v-card-title>
      <b>Main Category</b>
      <!-- <pre><code>{{ mainCategoryList }}</code></pre> -->
      <v-row justify="end" class="mr-8">
        <v-btn color="primary" dark @click="createCategory">
          Create Category
          <v-icon dark class="ml-1"> mdi-plus </v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="mainCategoryList"
        :items-per-page="20"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :expanded.sync="expanded"
        style="cursor: pointer"
        :single-expand="singleExpand"
        item-key="id"
        :loading="runLoading"
        loading-text="Loading... Please wait"
        :search="search"
        :custom-filter="filterText"
        show-group-by
        group-desc
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50, -1],
        }"
        class="elevation-1 my-data-table"
        @click:row="myRowClickHandler"
      >
        <template v-slot:top>
          <v-text-field
            append-icon="mdi-magnify"
            v-model="search"
            :label="'Search Main Category'"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <tr v-if="item.active" :style="{ color: ' #00C853' }">
            {{
              $t("product.listingTable.column.content.productActiveStatus")
            }}
          </tr>
          <tr v-if="!item.active" :style="{ color: ' #d61515' }">
            {{
              $t("product.listingTable.column.content.productInactiveStatus")
            }}
          </tr>
        </template>

        <template v-slot:no-data> {{ $t("common.msg.emtpyTable") }} </template>

        <template v-slot:no-results>
          {{ $t("common.msg.noDataFound") }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import myApi from "../../api/api.js";
import enJson from "../../locales/en.json";
import zhJson from "../../locales/zh.json";

export default {
  props: {},
  data() {
    return {
      headers: [],
      runLoading: true,
      mainCategoryList: [],
      search: ''
    };
  },
  mounted() {
    this.getColumnHeader();
    this.getMainCategory();
  },
  methods: {
    getColumnHeader() {
      if (this.$root.$i18n.locale === "en") {
        this.headers = [
          {
            text: "Category Id",
            value: "id",
            groupable: false,
          },
          {
            text: enJson.product.listingTable.column.headers.name,
            value: "name",
            groupable: false,
          },
          {
            text: "Display Name",
            value: "displayName",
            groupable: true,
          },
          {
            text: "Description",
            value: "description",
            groupable: false,
          },
          {
            text: "Thumbnail",
            value: "thumbnail",
            groupable: true,
          },
        ];
      }

      if (this.$root.$i18n.locale === "zh") {
        this.headers = [
          {
            text: "Category Id",
            value: "id",
            groupable: false,
          },
          {
            text: zhJson.product.listingTable.column.headers.name,
            value: "name",
            groupable: false,
          },
          {
            text: zhJson.product.listingTable.column.headers.category,
            value: "categoryId",
            groupable: true,
          },
          {
            text: zhJson.product.listingTable.column.headers.price,
            value: "price",
            groupable: false,
          },
          {
            text: zhJson.product.listingTable.column.headers.active,
            value: "active",
            groupable: true,
          },
        ];
      }
    },
    myRowClickHandler(record) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      // console.log(index, record); // This will be the item data for the row
      console.log("Entered Edit Product Table");
      if (record.id != -1) {
        this.$router.push({
          name: "categoryDetail",
          params: {
            categoryId: record.id,
            locale: this.$root.$i18n.locale,
            userType: "Main",
          },
        });
      }
    },
    getColor(productType) {
      if (productType === "PICKUP") return "red";
      else return "green";
    },
    getMainCategory() {
      myApi
        .getMainCategory()
        .then((response) => {
          this.mainCategoryList = response.data.data;
          console.log("this.mainCategoryList: ", this.mainCategoryList);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.runLoading = false));
    },
    createCategory() {
      this.$router.push({
        name: "createCategory",
        params: {
          locale: this.$root.$i18n.locale,
          userType: "Main",
        },
      });
    },
    filterText(value, search, item) {
      console.log({ value, search, item });
      return (
        value !== null &&
        search !== null &&
        item !== null &&
        typeof value === "number" &&
        item.id.toString().includes(search) &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
};
</script>
