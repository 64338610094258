<template>
  <v-app v-if="isLogin">
    <SideBar />
    <NavBar />
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <AfterSubmitDialog
      @input="closeErrorAlertDialog"
      :value="errorAlertDialog.open"
      :submitSuccess="false"
      :errMsg="errorAlertDialog.errMsg"
    />
  </v-app>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import SideBar from "@/components/SideBar.vue";
import AfterSubmitDialog from "@/components/AfterSubmitDialog.vue";

import { mapMutations, mapState } from "vuex";

import myApi from "@/api/api.js";
import store from "@/store";

export default {
  name: "NavBarView",
  components: {
    NavBar,
    SideBar,
    AfterSubmitDialog,
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.userInfo.isLogin,
      errorAlertDialog: (state) => state.errorAlertDialog,
    }),
  },
  methods: {
    ...mapMutations(['closeErrorAlertDialog']),
  },
  created: () => {
    // ===================== UAT Dev ===========================
    // ===========================================================
    const userInfo = {
      display: "",
      userName: "",
      userType: "",
      userRole: [],
      staffId: Number,
      isLogin: false,
    };
    myApi.getCurrentUser().then((response) => {
      if (response.data.success) {
        let userDetails = response.data.data;
        if (userDetails.userGroups.includes("STAFF")) {
          console.log("Authorized as STAFF")
          // let userType = userDetails.userGroups.filter(function (item) {
          //   return item !== "STAFF";
          // })[0];
          userInfo.display = userDetails.displayName;
          userInfo.userName = userDetails.username;
          userInfo.userRole = userDetails.userGroups.map(role => role.toLowerCase());
          userInfo.userType = "staff"
          userInfo.staffId = userDetails.staffId;
          userInfo.isLogin = true;
        }
        store.commit("setUserInfo", userInfo);
      } else {
        window.location.replace('/nukcafe/api/v1/oauth2/login');
      }
    });

    // ===================== Local Dev ===========================
    // ===========================================================
    // const userInfo = {
    //   display: "",
    //   userName: "",
    //   userType: "",
    //   userRole: ["admin"],
    //   staffId: 1,
    //   isLogin: false,
    // };
    // userInfo.display = "admin";
    // userInfo.userName = "admin";
    // userInfo.userType = "admin";
    // userInfo.isLogin = true;
    // store.commit("setUserInfo", userInfo);
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      // color: #42b983;
      color: #417cc9;
    }
  }
}

.blockchainIcon {
  max-width: 20px;
}

.userLogo {
  max-width: 40px;
  margin-right: 10px;
}

ul {
  margin: 5px;
  text-align: left;
}

@media only screen and (max-width: 599px) {
  .my-data-table > div > table > tbody {
    display: block !important;
  }
}
</style>
