<template>
  <v-dialog v-model="show" max-width="400">
    <v-card>
      <v-card-title class="headline">
        {{$t("common.msg.confirmDeleteDialog.headline")}}{{ deleteType }} ?
      </v-card-title>

      <v-card-text align="start">
        <!-- Note: The record will be added to the blockchain permanently and cannot be edited later. -->
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="submit">
          {{ $t("common.btn.yes") }}
        </v-btn>

        <v-btn color="green darken-1" text @click.stop="show = false">
          {{ $t("common.btn.no") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    deleteType: String
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    submit() {
      this.$emit("submitevent");
    }
  }
};
</script>
