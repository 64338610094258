<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title>
          <b v-if="checkCategory == 'Sub'">Crate Sub Category</b>
          <b v-else>Crate main Category</b>
        </v-card-title>

        <v-card-text class="ml-4">
          <v-row justify="end" class="mr-8">
            <v-btn
              color="primary"
              outlined
              class="mr-4"
              dark
              @click="goToShowProductTablePage"
            >
              {{ $t("common.btn.back") }}
            </v-btn>
          </v-row>
          <br />
          <!-- Starting Content of the Create Form  -->
          <v-row align="center" v-if="checkCategory =='Sub'">
            <v-autocomplete
              dense
              v-model="mainCategoryId"
              :items="mainCategoryList"
              :label="$t('product.create.input.general.category')"
              required
              rounded
              :deletable-chips="true"
              :rules="validationRules.category"
              clearable
              outlined
            ></v-autocomplete>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="category.name"
              label="Category name"
              required
              :rules="validationRules.name"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="category.displayEn"
              label="Category name (English)"
              required
              :rules="validationRules.nameZh"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="category.displayKm"
              label="Category name (Khmer)"
              required
              :rules="validationRules.nameKm"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="category.displayZh"
              label="Category name (Chinese)"
              required
              :rules="validationRules.descriptionEn"
            ></v-text-field>
          </v-row>

          <br />
        </v-card-text>
      </v-card>
      <br />

      <v-row justify="end" class="mr-8">
        <v-btn
          color="primary"
          outlined
          dark
          @click="goToShowProductTablePage"
          class="mx-2"
        >
          {{ $t("common.btn.cancel") }}
        </v-btn>
        <v-btn color="primary" dark @click.stop="submitDialog = true">
          {{ $t("common.btn.save") }}
        </v-btn>

        <ConfirmSubmitDialog v-model="submitDialog" @submitevent="submit" />
      </v-row>
    </v-form>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <AfterSubmitDialog
      v-model="afterSubmitDialog"
      :submitSuccess="submitSuccess"
      :errMsg="errMsg"
    />
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";
import AfterSubmitDialog from "../AfterSubmitDialog.vue";
import ConfirmSubmitDialog from "../ConfirmSubmitDialogNonBlockchain.vue";

export default {
  components: {
    AfterSubmitDialog,
    ConfirmSubmitDialog,
  },
  props: [],
  data() {
    return {
      checkCategory: "",
      mainCategoryId: "",
      mainCategoryList: [],
      mainCategory: [],
      loadingOverlay: false,
      afterSubmitDialog: false,
      submitSuccess: false,
      submitDialog: false,
      categoryDropDownList: [],
      product: {
        nameEn: "",
        nameZh: "",
        nameKm: "",
        descriptionEn: "",
        descriptionZh: "",
        descriptionKm: "",
        categoryId: "",
        price: "",
        sequence: "",
        s3Image: [],
        productFeaturesJSONString: "",
      },
      category: {
        name: "",
        displayEn: "",
        displayKm: "",
        displayZh: "",
      },
      productFeaturesList: [
        {
          type: "SIZE",
          value: "LARGE",
          deltaPrice: "0",
          sortingOrder: "0",
          active: true,
        },
        {
          type: "SIZE",
          value: "MEDIUM",
          deltaPrice: "0",
          sortingOrder: "1",
          active: true,
        },
        {
          type: "SIZE",
          value: "SMALL",
          deltaPrice: "0",
          sortingOrder: "2",
          active: true,
        },
      ],
      tab: null,
      validationRules: {
        nameEn: [(v) => !!v || "Category name (English)is required"],
        nameZh: [(v) => !!v || "Category name (Chinese) is required"],
        nameKm: [(v) => !!v || "Category name (Khmer) is required"],
        name: [(v) => !!v || "Category name is required"],
      },
      errMsg: this.$t("common.msg.error.create.msg"),
    };
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType;
    },
  },
  mounted: async function () {
    this.loadingOverlay = true;
    await this.getAllCategories();
    this.loadingOverlay = false;
    await this.getMainCategory();
    this.checkCategory = this.$route.params.userType;
  },
  methods: {
    isFormValid() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    submit() {
      if (this.isFormValid()) {
        this.loadingOverlay = !this.loadingOverlay;
        this.submitDialog = false;
        // this.createProduct();
        if (this.checkCategory == "Sub") {
          this.createSubCategory();
        } else {
          this.createMainCategory();
        }
      } else {
        this.submitDialog = false;
        this.afterSubmitDialog = true;
      }
    },
    getAllCategories() {
      console.log("Start Getting Category List");
      myApi
        .getAllCategories()
        .then((response) => {
          // console.log("getAllCateResponse", response);
          this.categoryList = response.data.data;
          this.categoryDropDownList = this.categoryList
            .filter((cat) => cat.id !== -1)
            .map((category) => category.name);
          // console.log("categoryDropDownList", this.categoryDropDownList);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.runLoading = false));
    },
    createProduct() {
      this.product.productFeaturesJSONString = JSON.stringify(
        this.productFeaturesList
      );
      let category = this.categoryList.find(
        (category) => category.name === this.product.categoryId
      );
      this.product.categoryId = category.id;
      // console.log("Product Targted: ", this.product);

      let productFormData = new FormData();
      productFormData.append("nameEn", this.product.nameEn);
      productFormData.append("nameZh", this.product.nameZh);
      productFormData.append("nameKm", this.product.nameKm);
      productFormData.append("descriptionEn", this.product.descriptionEn);
      productFormData.append("descriptionZh", this.product.descriptionZh);
      productFormData.append("descriptionKm", this.product.descriptionKm);
      productFormData.append("categoryId", this.product.categoryId);
      productFormData.append("price", this.product.price);
      productFormData.append("sequence", this.product.sequence);
      productFormData.append("s3Image", this.product.s3Image);
      productFormData.append(
        "productFeaturesJSONString",
        this.product.productFeaturesJSONString
      );

      myApi
        .createProduct(productFormData)
        .then(() => {
          this.submitSuccess = true;
          this.afterSubmitDialog = true;
          this.resetValues();
          this.goToShowProductTablePage();
        })
        .catch(() => {
          this.afterSubmitDialog = true;
          this.submitSuccess = false;
          this.loadingOverlay = false;
        });
    },
    resetValues() {
      this.loadingOverlay = false;
      this.resetValidation();
    },
    goToShowProductTablePage() {
      if (this.checkCategory == "Sub") {
        this.$router.push({
          name: "subCategory",
          params: {
            locale: this.$root.$i18n.locale,
            userType: this.userType,
          },
        });
      } else {
        this.$router.push({
          name: "Main Category",
          params: {
            locale: this.$root.$i18n.locale,
            userType: this.userType,
          },
        });
      }
    },
    createSubCategory() {
      var newCategoryID = this.mainCategory.find(
        (category) => category.name == this.mainCategoryId
      );

      const newCategory = {
        displayEn: this.category.displayEn,
        displayKm: this.category.displayKm,
        displayZh: this.category.displayZh,
        mainCategoryId: newCategoryID.id,
        name: this.category.name,
      }

      myApi.createSubCategory(newCategory).then((res) => {
        console.log("createSubCategory: ", res);
        this.submitSuccess = false;
        this.loadingOverlay = false;
        this.$router.push({
          name: "subCategory",
          params: {
            locale: this.$root.$i18n.locale,
            userType: this.userType,
          },
        });
      });
    },
    createMainCategory() {
      myApi.createMainCategory(this.category).then((res) => {
        console.log("createMainCategory: ", res);
        this.submitSuccess = false;
        this.loadingOverlay = false;
        this.$router.push({
          name: "Main Category",
          params: {
            locale: this.$root.$i18n.locale,
            userType: this.userType,
          },
        });
      });
    },
    getMainCategory() {
      myApi
        .getMainCategory()
        .then((response) => {
          this.mainCategory = response.data.data;
          this.mainCategoryList = this.mainCategory
            .filter((cat) => cat.id !== -1)
            .map((category) => category.name);
          console.log("this.mainCategoryList: ", response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.runLoading = false));
    },
  },
};
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: lightgray;
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
