<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title>
          <b>{{ $t("setting.title") }}</b>
        </v-card-title>

        <v-card-text class="ml-4">
          <v-row justify="end" class="mr-8">
            <v-btn
              color="primary"
              outlined
              class="mr-4"
              dark
              @click="goToSetting"
            >
              {{ $t("common.btn.back") }}
            </v-btn>
            <v-btn color="primary" dark @click.stop="submitDialog = true">
              {{ $t("common.btn.save") }}
            </v-btn>
          </v-row>

          <br />
          <br />

          <v-row justify="start" align="center">
            <v-switch v-model="isAllowedPopUp"></v-switch><span style="font-size: 1.1em;color: black;font-weight: bold;">Loyalty Pop-Up</span>
          </v-row>

          <v-row v-for="(setting, index) in settingList" :key="`item-${index}`">
            <tr v-if="containRewardPoints(setting.path)">
              <v-row class="ratio-slider-text">
                <b>{{ setting.metadata.display }}</b>
              </v-row>
              <v-row>
                <v-slider
                  class="ratio-slider"
                  v-model="setting.value"
                  max="1"
                  min="0"
                  thumb-label="always"
                  thumb-color="#F06292"
                  thumb-size="35"
                  step="0.1"
                  ticks="always"
                  tick-size="0.1"
                  track-color="grey"
                  :hint="$t('setting.edit.hint.loyaltyPointsRatio')"
                  :persistent-hint="true"
                >
                  <template v-slot:prepend>
                    <v-icon color="indigo" @click="decrement(setting, index)">
                      mdi-minus
                    </v-icon>
                  </template>

                  <template v-slot:append>
                    <v-icon color="indigo" @click="increment(setting, index)">
                      mdi-plus
                    </v-icon>
                  </template>
                </v-slider>
              </v-row>
            </tr>

            <tr v-else-if="containOpenningTime(setting.path)">
              <v-row class="ratio-slider-text">
                <b>{{ setting.metadata.display }}</b>
              </v-row>
              <v-time-picker
                class="setting-time-picker"
                v-model="setting.value"
                scrollable
                color="green lighten-1"
              >
              </v-time-picker>
            </tr>

            <tr v-else-if="containClosingTime(setting.path)">
              <v-row class="ratio-slider-text">
                <b>{{ setting.metadata.display }}</b>
              </v-row>
              <v-time-picker
                class="setting-time-picker"
                v-model="setting.value"
                scrollable
                color="red lighten-1"
              >
              </v-time-picker>
            </tr>

            <tr v-else-if="containUnscheduledClosing(setting.path)">
              <v-row class="ratio-slider-text">
                <b>{{ setting.metadata.display }}</b>
              </v-row>

              <!-- <v-checkbox
                :label="$t('product.edit.input.feature.active')"
                v-model="setting.value"
              > -->
              <v-btn-toggle
                class="unscheduled-closing-toggle-btn"
                v-model="setting.value"
                shaped
                color="deep-purple accent-3"
              >
                <v-btn value="true"> TRUE </v-btn>
                <v-btn value="false"> FALSE </v-btn>
              </v-btn-toggle>
              <!-- </v-checkbox> -->
            </tr>

            <v-row class="setting-row" v-else>
              <v-text-field
                v-model="setting.value"
                :label="setting.metadata.display"
              ></v-text-field>
            </v-row>
          </v-row>

          <v-row justify="end" class="mr-8">
            <v-btn
              color="primary"
              outlined
              dark
              @click="cancelAction"
              class="mx-2"
            >
              {{ $t("common.btn.cancel") }}
            </v-btn>
            <v-btn color="primary" dark @click.stop="submitDialog = true">
              {{ $t("common.btn.save") }}
            </v-btn>
            <ConfirmSubmitDialog v-model="submitDialog" @submitevent="submit" />
          </v-row>
        </v-card-text>
      </v-card>
      <br />
    </v-form>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <AfterSubmitDialog
      v-model="afterSubmitDialog"
      :submitSuccess="submitSuccess"
      :errMsg="errMsg"
    />
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";
import AfterSubmitDialog from "../AfterSubmitDialog.vue";
import ConfirmSubmitDialog from "../ConfirmSubmitDialogNonBlockchain.vue";

export default {
  components: {
    AfterSubmitDialog,
    ConfirmSubmitDialog,
  },
  props: [],
  data() {
    return {
      loadingOverlay: false,
      afterSubmitDialog: false,
      submitSuccess: false,
      submitDialog: false,
      settingList: [],
      errMsg: this.$t("common.msg.error.edit.msg"),
      isAllowedPopUp: false
    };
  },
  computed: {},
  mounted() {
    console.log("Start to get Setting");
    this.loadingOverlay = true;
    this.getAllSettings();
    this.getPopUpStatus()
  },
  methods: {
    decrement(ratioSetting, index) {
      console.log("index, ", index);
      console.log("ratioSetting, ", ratioSetting);

      if (ratioSetting.path.includes("nukcafe.order.rewardPointRate")) {
        ratioSetting.value -= 0.1;
        this.settingList.splice(index, 1, ratioSetting);
      }
    },
    increment(ratioSetting, index) {
      if (ratioSetting.path.includes("nukcafe.order.rewardPointRate")) {
        ratioSetting.value += 0.1;
        this.settingList.splice(index, 1, ratioSetting);
      }
    },
    containRewardPoints(target) {
      let result = false;
      if (target.includes("nukcafe.order.rewardPointRate")) {
        result = true;
      }
      return result;
    },
    containClosingTime(target) {
      let result = false;
      if (target.includes("nukcafe.store.closingTime")) {
        result = true;
      }
      return result;
    },
    containOpenningTime(target) {
      let result = false;
      if (target.includes("nukcafe.store.openingTime")) {
        result = true;
      }
      return result;
    },
    containUnscheduledClosing(target) {
      let result = false;
      if (target === "nukcafe.store.unscheduledClosing") {
        result = true;
      }
      return result;
    },
    getAllSettings() {
      myApi
        .getAllSettings()
        .then((response) => {
          this.settingsResponseDataList = response.data.data;
          this.settingList = this.settingsResponseDataList;
          console.log("this.settingList = ", this.settingList);
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOverlay = false));
    },
    submit() {
      this.loadingOverlay = !this.loadingOverlay;
      this.submitDialog = false;
      this.updateSetting();
    },
    updateSetting() {
      let openingTimeIndex = this.settingList.findIndex(
        (setting) => setting.path === "nukcafe.store.openingTime"
      );
      let closingTimeIndex = this.settingList.findIndex(
        (setting) => setting.path === "nukcafe.store.closingTime"
      );

      let openingTimeSetting = this.settingList.find(
        (setting) => setting.path === "nukcafe.store.openingTime"
      );
      if (openingTimeSetting.value.split(":").length === 2) {
        openingTimeSetting.value = openingTimeSetting.value + ":00";
        this.settingList.splice(openingTimeIndex, 1, openingTimeSetting);
      }

      let closingTimeSetting = this.settingList.find(
        (setting) => setting.path === "nukcafe.store.closingTime"
      );
      if (closingTimeSetting.value.split(":").length === 2) {
        closingTimeSetting.value = closingTimeSetting.value + ":00";
        this.settingList.splice(closingTimeIndex, 1, closingTimeSetting);
      }

      console.log("This latest Setting == ", this.settingList);

      myApi
        .updateSetting(this.settingList)
        .then(() => {
          this.submitSuccess = true;
          this.afterSubmitDialog = true;
          this.resetValues();
          this.goToSetting();
        })
        .catch(() => {
          this.afterSubmitDialog = true;
          this.submitSuccess = false;
          this.loadingOverlay = false;
        });
      myApi.updatePopUpStatus(this.isAllowedPopUp)
    },
    resetValues() {
      this.loadingOverlay = false;
    },
    cancelAction() {
      this.loadingOverlay = true;
      window.location.reload();
    },
    goToSetting() {
      console.log("Entered Show Setting");
      this.$router.push({
        name: "Show Setting",
      });
    },
    async getPopUpStatus () {
      const response = await myApi.getPopUpStatus()
      this.isAllowedPopUp = response.data.popUp
    }
  },
};
</script>

<style scoped>
.setting-row {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10%;
}

.setting-row::v-deep .v-text-field .v-label {
  font-size: 1.3em;
  color: black;
  font-weight: bold;
}

.setting-row::v-deep .v-text-field__slot input {
  margin-top: 10px;
  text-align: left;
}

.ratio-slider {
  margin-top: 45px;
  margin-left: 10px;
}

.ratio-slider-text {
  font-size: 1em;
  font-weight: bold;
  color: black;
  margin-top: 30px;
  margin-left: 10px;
}

.setting-time-picker {
  margin-top: 10px;
  margin-left: 100px;
}

.unscheduled-closing-toggle-btn {
  margin-top: 20px;
}
</style>
