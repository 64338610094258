<template>
  <div>
    <NewsDetailEdit />
  </div>
</template>

<script>
// @ is an alias to /src
import NewsDetailEdit from "@/components/News/NewsDetailEdit.vue";

export default {
  name: "EditNewsForm",
  components: {
    NewsDetailEdit,
  },
  data() {
    return {};
  },
};
</script>
 