<template>
  <v-container>
    <b
      ><h3>
        {{ $t("voucher.view.title") }} :
        <u>{{ this.$route.params.voucherId }}</u>
      </h3></b
    >

    <br />

    <v-row justify="end" class="mr-8">
      <v-btn
        color="primary"
        outlined
        class="mr-4"
        dark
        @click="goToShowVoucherTablePage"
      >
        {{ $t("common.btn.back") }}
      </v-btn>

      <v-btn
        v-if="showAdminVision"
        color="primary"
        class="mr-4"
        dark
        @click="goToEditVoucherDetailPage"
      >
        {{ $t("common.btn.edit") }}
        <v-icon dark class="ml-1"> mdi-pencil </v-icon>
      </v-btn>
    </v-row>

    <br />
    <br />
    <v-card color="#CFD8DC">
      <v-img
        v-if="voucher.imageURL !== null"
        height="250"
        :src="voucher.imageURL"
      ></v-img>

      <v-card-text class="ml-4">
        <v-row class="h5 black--text mt-3">
            Voucher Type: {{ voucherType }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.titleEn") }} : {{ voucher.titleEn }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.titleZh") }} : {{ voucher.titleZh }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.titleKm") }} : {{ voucher.titleKm }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.labelEn") }} : {{ voucher.labelEn }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.labelZh") }} : {{ voucher.labelZh }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.labelKm") }} : {{ voucher.labelKm }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.descriptionEn") }} :
          {{ voucher.descriptionEn }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.descriptionZh") }} :
          {{ voucher.descriptionZh }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.descriptionKm") }} :
          {{ voucher.descriptionKm }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.tcEn") }} : {{ voucher.tcEn }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.tcZh") }} : {{ voucher.tcZh }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.tcKm") }} : {{ voucher.tcKm }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.priceDecreasedVal") }} :
          {{ voucher.priceDecreasedVal }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.purchasePoints") }} :
          {{ voucher.purchasePoints }}
        </v-row>

        <v-row class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.validityPeriodDays") }} :
          {{ voucher.validityPeriodDays }}
        </v-row>

        <v-row v-if="voucher.active" class="h5 black--text mt-3">
          {{ $t("voucher.view.content.main.active") }} :
          <tr class="ml-1" :style="{ color: ' #00C853' }">
            <u>Active</u>
          </tr>
        </v-row>

        <v-row
          v-if="!voucher.active"
          :style="{ color: ' #d61515' }"
          class="h5 black--text mt-3"
        >
          {{ $t("voucher.view.content.main.active") }} :
          <tr class="ml-1" :style="{ color: ' #d61515' }">
            <u>Inactive</u>
          </tr>
        </v-row>

        <v-row class="h5 black--text mt-3">
          Redeemable:
          <tr
            class="ml-1"
          >
            {{
              voucher.isRedeemable
            }}
          </tr>
        </v-row>
      </v-card-text>
    </v-card>

    <br />

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import myApi from "../../api/api.js"

export default {
  components: {},
  props: [],
  data() {
    return {
      loadingOverlay: false,
      voucher: {},
      showAdminVision: false,
      errMsg: this.$t("common.msg.error.edit.msg"),
      voucherList: [],
      voucherTypeData: [],
      voucherType: "",
    }
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName
    },
    userType() {
      return this.$store.state.userInfo.userType
    },
    userRole() {
      return this.$store.state.userInfo.userRole
    },
  },
  created() {
    if (this.userRole.includes("admin")) {
      this.showAdminVision = true
    }
  },
  mounted: async function() {
    this.getVoucherData()
    this.loadingOverlay = true;
    this.getVoucherType();
  },
  methods: {
    getColor(voucherStatus) {
      if (voucherStatus === true) return "#d61515"
      else return "#00C853"
    },
    getVoucherData() {
      console.log("Start Getting Voucher Data")
      myApi
        .getVoucherData(this.$route.params.voucherId)
        .then((response) => {
          var voucherId = response.data.data.voucherTypeId;
          this.voucherTypeData.forEach((voucherType) => {
            if (voucherType.id == voucherId) {
              this.voucherType = voucherType.name;
            } else if (voucherId == null || voucherId == '') {
              this.voucherType = "LOYALTY";
            }
          });
          this.voucher = response.data.data
          // console.log("Selected Voucher: ", this.voucher);
          this.loadingOverlay = false
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => (this.loadingOverlay = false))
    },
    goToShowVoucherTablePage() {
      console.log("Entered Show Voucher")
      this.$router.push({
        name: "Show Voucher Table",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      })
    },
    goToEditVoucherDetailPage() {
      console.log("Entered Edit Voucher Detail Page")
      console.log(
        "Selected Voucher ID For Edit: ",
        this.$route.params.voucherId
      )
      this.$router.push({
        name: "Edit Voucher",
        params: {
          voucherId: this.$route.params.voucherId,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      })
    },
    getVoucherType() {
      myApi
        .getVoucherType()
        .then((response) => {
          console.log("Response from getVoucherType =>", response.data.data)
          var voucherTypeList = response.data.data;
          this.voucherTypeData = response.data.data;
          this.voucherList = voucherTypeList.filter(
            (voucher) => voucher.id != ""
          ).map((voucher) => voucher.name);
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: rgb(32, 27, 27);
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
