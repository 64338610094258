<template>
  <div>
    <OrderViewDetail />
  </div>
</template>

<script>
// @ is an alias to /src
import OrderViewDetail from "@/components/Order/OrderViewDetail.vue";

export default {
  props: {},
  name: "EditOrderForm",
  components: {
    OrderViewDetail,
  },
  data() {
    return {};
  },
};
</script>
