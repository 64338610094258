<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title class="card-title">
          <b>{{ $t("setting.title") }}</b>
        </v-card-title>

        <v-card-text class="ml-4">
          <v-row justify="end" class="mr-8">
            <v-btn
              color="primary"
              outlined
              class="mr-4"
              dark
              @click="goToDashboard"
            >
              {{ $t("common.btn.back") }}
            </v-btn>
            <v-btn color="primary" class="mr-4" dark @click="goToEditSetting">
              {{ $t("common.btn.edit") }}
            </v-btn>
          </v-row>

          <br />
          <br />

          <v-row justify="start" align="center">
            <v-switch v-model="isAllowedPopUp" disabled></v-switch><span style="font-size: 1.1em;color: black;font-weight: bold;">Loyalty Pop-Up</span>
          </v-row>

          <v-row v-for="(setting, index) in settingList" :key="`item-${index}`">
            <v-row class="setting-row">
              <v-text-field
                v-model="setting.value"
                :label="setting.metadata.display"
                readonly
              ></v-text-field>
            </v-row>
          </v-row>
        </v-card-text>
      </v-card>
      <br />
    </v-form>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";

export default {
  components: {},
  props: [],
  data() {
    return {
      loadingOverlay: false,
      afterSubmitDialog: false,
      submitSuccess: false,
      submitDialog: false,
      settingList: [],
      errMsg: this.$t("common.msg.error.edit.msg"),
      isAllowedPopUp: false
    };
  },
  computed: {},
  mounted() {
    console.log("Start to get Setting");
    this.loadingOverlay = true;
    this.getAllSettings();
    this.getPopUpStatus()
  },
  methods: {
    getAllSettings() {
      myApi
        .getAllSettings()
        .then((response) => {
          this.settingsResponseDataList = response.data.data;
          this.settingList = this.settingsResponseDataList;
          console.log("this.settingList = ", this.settingList);
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOverlay = false));
    },
    goToDashboard() {
      console.log("Entered Dashboard");
      this.$router.push({
        name: "Dashboard",
      });
    },
    goToEditSetting() {
      console.log("Entered Edit Setting");
      this.$router.push({
        name: "Edit Setting",
      });
    },
    async getPopUpStatus () {
      const response = await myApi.getPopUpStatus()
      this.isAllowedPopUp = response.data.popUp
    }
  },
};
</script>

<style scoped>
.setting-row {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10%;
}

.setting-row::v-deep .v-text-field .v-label {
  font-size: 1.3em;
  color: black;
  font-weight: bold;
}

.setting-row::v-deep .v-text-field__slot input {
  margin-top: 10px;
  text-align: left;
}

.ratio-slider {
  margin-top: 45px;
  margin-left: 10px;
  margin-right: 10%;
}

.ratio-slider-text {
  font-size: 1em;
  font-weight: bold;
  color: black;
  margin-top: 30px;
  margin-left: 10px;
}

.email-address-text {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 80%;
}

.email-address-text::v-deep .v-text-field .v-label {
  font-size: 1.4em;
  color: black;
  font-weight: bold;
}

.email-address-text::v-deep .v-text-field__slot input {
  margin-top: 10px;
  text-align: center;
}

.contact-number-text {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 80%;
}

.contact-number-text::v-deep .v-text-field .v-label {
  margin-bottom: 100px;
  font-size: 1.4em;
  color: black;
  font-weight: bold;
}

.contact-number-text::v-deep .v-text-field__slot input {
  margin-top: 10px;
  text-align: center;
}

.card-title {
    margin-top: 10px;
    font-size: 1.8em;
}
</style>
