<template>
  <div class="processor">
    <Setting />
  </div>
</template>

<script>
// @ is an alias to /src
import Setting from "@/components/Setting/Setting.vue";

export default {
  props: {},
  components: {
    Setting,
  },
  create() {
    console.log("Enter Setting View")
  },
  data() {
    return {};
  },
};
</script>
