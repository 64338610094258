<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-img
          v-if="voucher.imageURL !== null"
          height="250"
          :src="voucher.imageURL"
        ></v-img>

        <v-card-title>
          <b>{{ $t("voucher.edit.title") }} </b>
        </v-card-title>

        <v-card-text class="ml-4">
          <v-row justify="end" class="mr-8">
            <v-btn
              color="primary"
              outlined
              class="mr-4"
              dark
              @click="goToVoucherViewPage"
            >
              {{ $t("common.btn.back") }}
            </v-btn>
          </v-row>

          <br />

          <!-- Starting Content of the Edit Form  -->

          <v-row align="center">
            <v-autocomplete
              dense
              v-model="voucherType"
              :items="voucherList"
              :label="'Voucher Type'"
              required
              rounded
              :deletable-chips="true"
              :rules="validationRules.voucherType"
              clearable
              outlined
            ></v-autocomplete>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.titleEn"
              :label="$t('voucher.edit.input.general.titleEn')"
              required
              :rules="validationRules.titleEn"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.titleZh"
              :label="$t('voucher.edit.input.general.titleZh')"
              required
              :rules="validationRules.titleZh"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.titleKm"
              :label="$t('voucher.edit.input.general.titleKm')"
              required
              :rules="validationRules.titleKm"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.labelEn"
              :label="$t('voucher.edit.input.general.labelEn')"
              required
              :rules="validationRules.labelEn"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.labelZh"
              :label="$t('voucher.edit.input.general.labelZh')"
              required
              :rules="validationRules.labelZh"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.labelKm"
              :label="$t('voucher.edit.input.general.labelKm')"
              required
              :rules="validationRules.labelKm"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.descriptionEn"
              :label="$t('voucher.edit.input.general.descriptionEn')"
              required
              :rules="validationRules.descriptionEn"
            ></v-text-field>
          </v-row>
          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.descriptionZh"
              :label="$t('voucher.edit.input.general.descriptionZh')"
              required
              :rules="validationRules.descriptionZh"
            ></v-text-field>
          </v-row>
          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.descriptionKm"
              :label="$t('voucher.edit.input.general.descriptionKm')"
              required
              :rules="validationRules.descriptionKm"
            ></v-text-field>
          </v-row>
          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.tcEn"
              :label="$t('voucher.edit.input.general.tcEn')"
              required
              :rules="validationRules.tcEn"
            ></v-text-field>
          </v-row>
          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.tcZh"
              :label="$t('voucher.edit.input.general.tcZh')"
              required
              :rules="validationRules.tcZh"
            ></v-text-field>
          </v-row>
          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.tcKm"
              :label="$t('voucher.edit.input.general.tcKm')"
              required
              :rules="validationRules.tcKm"
            ></v-text-field>
          </v-row>
          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.priceDecreasedVal"
              :label="$t('voucher.edit.input.general.priceDecreasedVal')"
              required
              :rules="validationRules.priceDecreasedVal"
            ></v-text-field>
          </v-row>
          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.purchasePoints"
              :label="$t('voucher.edit.input.general.purchasePoints')"
              required
              :rules="validationRules.purchasePoints"
            ></v-text-field>
          </v-row>
          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="voucher.validityPeriodDays"
              :label="$t('voucher.edit.input.general.validityPeriodDays')"
              required
              :rules="validationRules.validityPeriodDays"
            ></v-text-field>
          </v-row>

          <v-file-input
            :label="$t('voucher.edit.input.general.imageURL')"
            filled
            show-size
            counter
            clearable
            v-model="voucher.image"
          ></v-file-input>

          <v-checkbox label="Active" v-model="voucher.active"> </v-checkbox>
          <v-checkbox label="Redeemable" v-model="voucher.isRedeemable"> </v-checkbox>
          <p class="text-start text-sm">* Redeemable means that users can redeem vouchers.</p>
          <!-- End Content of the Edit Form  -->
          <br />
        </v-card-text>
      </v-card>
      <br />

      <br />

      <v-row justify="end" class="mr-8">
        <v-btn color="primary" outlined dark @click="resetAction" class="mx-2">
          {{ $t("common.btn.reset") }}
        </v-btn>
        <v-btn color="primary" dark @click.stop="submitDialog = true">
          {{ $t("common.btn.save") }}
        </v-btn>

        <ConfirmSubmitDialog v-model="submitDialog" @submitevent="submit" />
      </v-row>
    </v-form>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <AfterSubmitDialog
      v-model="afterSubmitDialog"
      :submitSuccess="submitSuccess"
      :errMsg="errMsg"
    />
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";
import AfterSubmitDialog from "../AfterSubmitDialog.vue";
import ConfirmSubmitDialog from "../ConfirmSubmitDialogNonBlockchain.vue";

export default {
  components: {
    AfterSubmitDialog,
    ConfirmSubmitDialog,
  },
  props: [],
  data() {
    return {
      voucherList: [],
      voucherTypeData: [],
      voucherType: "",
      loadingOverlay: false,
      afterSubmitDialog: false,
      submitSuccess: false,
      submitDialog: false,
      voucher: {},
      isRedeemable: true,
      selectedContentType: "",
      validationRules: {
        voucherType: [(v) => !!v || "Voucher Type is required"],
        titleEn: [(v) => !!v || this.$t("voucher.edit.error.titleEn")],
        // titleZh: [(v) => !!v || this.$t("voucher.edit.error.titleZh")],
        // titleKm: [(v) => !!v || this.$t("voucher.edit.error.titleKm")],
        labelEn: [(v) => !!v || this.$t("voucher.edit.error.labelEn")],
        // labelZh: [(v) => !!v || this.$t("voucher.edit.error.labelZh")],
        // labelKm: [(v) => !!v || this.$t("voucher.edit.error.labelKm")],
        descriptionEn: [
          (v) => !!v || this.$t("voucher.edit.error.descriptionEn"),
        ],
        // descriptionZh: [
        //   (v) => !!v || this.$t("voucher.edit.error.descriptionZh"),
        // ],
        // descriptionKm: [
        //   (v) => !!v || this.$t("voucher.edit.error.descriptionKm"),
        // ],
        tcEn: [(v) => !!v || this.$t("voucher.edit.error.tcEn")],
        // tcZh: [(v) => !!v || this.$t("voucher.edit.error.tcZh")],
        // tcKm: [(v) => !!v || this.$t("voucher.edit.error.tcKm")],
        priceDecreasedVal: [
          (v) => !!v || this.$t("voucher.edit.error.priceDecreasedVal"),
        ],
        purchasePoints: [
          (v) => !!v || this.$t("voucher.edit.error.purchasePoints"),
        ],
        validityPeriodDays: [
          (v) => !!v || this.$t("voucher.edit.error.validityPeriodDays"),
        ],
        active: [(v) => !!v || this.$t("voucher.edit.error.active")],
      },
      errMsg: this.$t("common.msg.error.edit.msg"),
    };
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
  },
  mounted: async function () {
    await this.getVoucherType();
    await this.getVoucherDataForEdit();
    this.loadingOverlay = true;
    this.isRedeemable = this.voucher.isRedeemable;
  },
  methods: {
    isFormValid() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    submit() {
      if (this.isFormValid()) {
        this.loadingOverlay = !this.loadingOverlay;
        this.submitDialog = false;
        this.updateVoucher();
      } else {
        this.submitDialog = false;
        this.afterSubmitDialog = true;
      }
    },
    updateVoucher() {
      var voucherTypeId = this.voucherTypeData.filter(
        (voucher) => voucher.name == this.voucherType
      )[0].id;
      
      let voucherFormData = new FormData();
      voucherFormData.append("titleEn", this.voucher.titleEn);
      voucherFormData.append("titleZh", this.voucher.titleZh);
      voucherFormData.append("titleKm", this.voucher.titleKm);
      voucherFormData.append("labelEn", this.voucher.labelEn);
      voucherFormData.append("labelZh", this.voucher.labelZh);
      voucherFormData.append("labelKm", this.voucher.labelKm);
      voucherFormData.append("descriptionEn", this.voucher.descriptionEn);
      voucherFormData.append("descriptionZh", this.voucher.descriptionZh);
      voucherFormData.append("descriptionKm", this.voucher.descriptionKm);
      voucherFormData.append("tcEn", this.voucher.tcEn);
      voucherFormData.append("tcZh", this.voucher.tcZh);
      voucherFormData.append("tcKm", this.voucher.tcKm);
      voucherFormData.append(
        "priceDecreasedVal",
        this.voucher.priceDecreasedVal
      );
      voucherFormData.append("purchasePoints", this.voucher.purchasePoints);
      voucherFormData.append(
        "validityPeriodDays",
        this.voucher.validityPeriodDays
      );
      voucherFormData.append("active", this.voucher.active);
      voucherFormData.append("isRedeemable", this.voucher.isRedeemable);
      voucherFormData.append("voucherTypeId", voucherTypeId);
      if (this.voucher.image != null && this.voucher.image !== undefined) {
        voucherFormData.append("image", this.voucher.image);
      }
      // console.log("Altered this.voucher, ", this.voucher);

      myApi
        .updateVoucher(this.$route.params.voucherId, voucherFormData)
        .then(() => {
          this.submitSuccess = true;
          this.afterSubmitDialog = true;
          this.resetValues();
          this.goToShowVoucherTablePage();
        })
        .catch(() => {
          this.afterSubmitDialog = true;
          this.submitSuccess = false;
          this.loadingOverlay = false;
        });
    },
    getVoucherDataForEdit() {
      myApi
        .getVoucherData(this.$route.params.voucherId)
        .then((response) => {
          var voucherId = response.data.data.voucherTypeId;
          this.voucherTypeData.forEach((voucherType) => {
            if (voucherType.id == voucherId) {
              this.voucherType = voucherType.name;
            } else if(voucherId == null || voucherId == ''){
              this.voucherType = "LOYALTY";
            }
          });
          this.voucher = response.data.data;
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOverlay = false));
    },
    resetAction() {
      this.loadingOverlay = true;
      window.location.reload();
    },
    resetValues() {
      this.loadingOverlay = false;
      this.resetValidation();
    },
    goToShowVoucherTablePage() {
      console.log("Entered Show Voucher");
      this.$router.push({
        name: "Show Voucher Table",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    goToVoucherViewPage() {
      console.log("Entered View Voucher Page");
      this.$router.push({
        name: "View Voucher",
        params: {
          voucherId: this.$route.params.voucherId,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    getVoucherType() {
      myApi
        .getVoucherType()
        .then((response) => {
          console.log("Response from getVoucherType =>", response.data.data)
          var voucherTypeList = response.data.data;
          this.voucherTypeData = response.data.data;
          this.voucherList = voucherTypeList.filter(
            (voucher) => voucher.id != ""
          ).map((voucher) => voucher.name);
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
};
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: lightgray;
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
