<template>
  <v-container>
    <b
      ><h3>
        {{ $t("news.view.title") }} :
        <u>{{ this.$route.params.newsId }}</u>
      </h3></b
    >

    <br />

    <v-row justify="end" class="mr-8">
      <v-btn
        color="primary"
        outlined
        class="mr-4"
        dark
        @click="goToShowNewsTablePage"
      >
        {{ $t("common.btn.back") }}
      </v-btn>

      <v-btn
        v-if="showAdminVision"
        color="primary"
        class="mr-4"
        dark
        @click="goToEditNewsDetailPage"
      >
        {{ $t("common.btn.edit") }}
        <v-icon dark class="ml-1"> mdi-pencil </v-icon>
      </v-btn>
    </v-row>

    <br />
    <br />
    <v-card color="#CFD8DC">
      <v-img
        v-if="news.imageURL !== null"
        height="250"
        :src="news.imageURL"
      ></v-img>

      <v-card-text class="ml-4">
        
        <v-row v-if="news.active" class="h5 black--text mt-3">
          {{ $t("news.view.content.main.active") }} :
          <tr class="ml-1" :style="{ color: ' #00C853' }">
            <u>Active</u>
          </tr>
        </v-row>

        <v-row
          v-if="!news.active"
          :style="{ color: ' #d61515' }"
          class="h5 black--text mt-3"
        >
          {{ $t("news.view.content.main.active") }} :
          <tr class="ml-1" :style="{ color: ' #d61515' }">
            <u>Inactive</u>
          </tr>
        </v-row>
      </v-card-text>
    </v-card>

    <br />

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";

export default {
  components: {},
  props: [],
  data() {
    return {
      loadingOverlay: false,
      news: {},
      errMsg: this.$t("common.msg.error.edit.msg"),
      showAdminVision: false,
    };
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
    userRole() {
      return this.$store.state.userInfo.userRole;
    },
  },
  created() {
    if(this.userRole.includes("admin")){
      this.showAdminVision = true
    }
  },
  mounted: async function () {
    this.getNewsData();
    this.loadingOverlay = true;
  },
  methods: {
    getColor(newsStatus) {
      if (newsStatus === true) return "#d61515";
      else return "#00C853";
    },
    getNewsData() {
      console.log("Start Getting News Data");
      myApi
        .getNewsData(this.$route.params.newsId)
        .then((response) => {
          this.news = response.data.data;
          console.log("Selected News: ", this.news);
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.runLoading = false));
      console.log("End Getting News Data");
    },
    goToShowNewsTablePage() {
      console.log("Entered Show News");
      this.$router.push({
        name: "Show News Table",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    goToEditNewsDetailPage() {
      console.log("Entered Edit News Detail Page");
      console.log(
        "Selected News ID For Edit: ",
        this.$route.params.newsId
      );
      this.$router.push({
        name: "Edit News",
        params: {
          newsId: this.$route.params.newsId,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
  },
};
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: rgb(32, 27, 27);
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
