<template>
  <div>
    <NewsCreate />
  </div>
</template>

<script>
// @ is an alias to /src
import NewsCreate from "@/components/News/NewsCreate.vue";

export default {
  name: "CreateNewsForm",
  components: {
    NewsCreate,
  },
  data() {
    return {};
  },
};
</script>
 