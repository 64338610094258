<template>
  <v-card>
    <v-card-title>
      <b>{{ $t("post.listingTable.title") }}</b>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="postTableData"
        :items-per-page="20"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :expanded.sync="expanded"
        :single-expand="singleExpand"
        show-expand
        style="cursor: pointer"
        item-key="id"
        :loading="runLoading"
        loading-text="Loading... Please wait"
        :search="search"
        :custom-filter="filterText"
        show-group-by
        group-desc
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50, -1],
        }"
        class="elevation-1 my-data-table"
        @click:row="myRowClickHandler"
      >
        <template v-slot:top>
          <v-text-field
            append-icon="mdi-magnify"
            v-model="search"
            :label="$t('post.listingTable.search')"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            {{ $t("post.listingTable.expandLine") }}: {{ item.caption }}
          </td>
        </template>

        <template v-slot:no-data> {{ $t("common.msg.emtpyTable") }} </template>

        <template v-slot:no-results>
          {{ $t("common.msg.noDataFound") }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import myApi from "../../api/api.js";
import enJson from "../../locales/en.json";
import zhJson from "../../locales/zh.json";

export default {
  props: {},
  data() {
    return {
      search: "",
      postTableData: [],
      headers: [],
      sortBy: "id",
      sortDesc: true,
      expanded: [],
      singleExpand: true,
      runLoading: true,
      searchParam: "",
    };
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
  },
  mounted() {
    console.log("Start to getAllPosts");
    this.getColumnHeader();
    this.getAllPosts();
  },
  methods: {
    getColumnHeader() {
      if (this.$root.$i18n.locale === "en") {
        this.headers = [
          {
            text: enJson.post.listingTable.column.headers.id,
            value: "id",
            groupable: false,
          },
          {
            text: enJson.post.listingTable.column.headers.approvalStatus,
            value: "approvalStatus",
            groupable: true,
          },
          {
            text: enJson.post.listingTable.column.headers.caption,
            value: "data-table-expand",
            groupable: false,
          },
        ];
      }

      if (this.$root.$i18n.locale === "zh") {
        this.headers = [
          {
            text: zhJson.post.listingTable.column.headers.id,
            value: "id",
            groupable: false,
          },
          {
            text: zhJson.post.listingTable.column.headers.approvalStatus,
            value: "approvalStatus",
            groupable: true,
          },
          {
            text: zhJson.post.listingTable.column.headers.caption,
            value: "data-table-expand",
            groupable: false,
          },
        ];
      }
    },
    myRowClickHandler(record) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      // console.log(index, record); // This will be the item data for the row
      console.log("Entered Edit Post Table");
      this.$router.push({
        name: "View Post",
        params: {
          postId: record.id,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    getAllPosts() {
      myApi
        .getAllPosts()
        .then((response) => {
          this.postsResponseDataList = response.data.data;
          // console.log(
          //   "this.postsResponseDataList: ",
          //   this.postsResponseDataList
          // );
          this.postTableData = this.postsResponseDataList;
          this.runLoading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.runLoading = false));
    },
    filterText(value, search, item) {
      // console.log({ value, search, item });
      return (
        value !== null &&
        search !== null &&
        item !== null &&
        typeof value === "number" &&
        item.id.toString().includes(search) &&
        value.toString().indexOf(search) !== -1
      );
    },
    getColor(productType) {
      if (productType === "PICKUP") return "red";
      else return "green";
    },
  },
};
</script>
