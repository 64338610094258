<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-img
          v-if="news.imageURL !== null"
          height="250"
          :src="news.imageURL"
        ></v-img>

        <v-card-title>
          <b>{{ $t("news.edit.title") }} </b>
        </v-card-title>

        <v-card-text class="ml-4">
          <v-row justify="end" class="mr-8">
            <v-btn
              color="primary"
              outlined
              class="mr-4"
              dark
              @click="goToNewsViewPage"
            >
              {{ $t("common.btn.back") }}
            </v-btn>
          </v-row>

          <br />

          <!-- Starting Content of the Edit Form  -->
          <v-file-input
            :label="$t('news.edit.input.general.imageURL')"
            filled
            show-size
            counter
            clearable
            v-model="news.image"
          ></v-file-input>

          <v-text-field
            shaped
            outlined
            v-model="news.sortingOrder"
            :label="$t('news.create.input.general.sortingOrder')"
            required
          ></v-text-field>

          <v-checkbox label="Active" v-model="news.active"> </v-checkbox>
          <!-- End Content of the Edit Form  -->
          <br />
        </v-card-text>
      </v-card>
      <br />

      <br />

      <v-row justify="end" class="mr-8">
        <v-btn color="primary" outlined dark @click="resetAction" class="mx-2">
          {{ $t("common.btn.reset") }}
        </v-btn>
        <v-btn color="primary" dark @click.stop="submitDialog = true">
          {{ $t("common.btn.save") }}
        </v-btn>

        <ConfirmSubmitDialog v-model="submitDialog" @submitevent="submit" />
      </v-row>
    </v-form>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <AfterSubmitDialog
      v-model="afterSubmitDialog"
      :submitSuccess="submitSuccess"
      :errMsg="errMsg"
    />
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";
import AfterSubmitDialog from "../AfterSubmitDialog.vue";
import ConfirmSubmitDialog from "../ConfirmSubmitDialogNonBlockchain.vue";

export default {
  components: {
    AfterSubmitDialog,
    ConfirmSubmitDialog,
  },
  props: [],
  data() {
    return {
      loadingOverlay: false,
      afterSubmitDialog: false,
      submitSuccess: false,
      submitDialog: false,
      news: {},
      selectedContentType: "",
      validationRules: {
        active: [(v) => !!v || this.$t("news.edit.error.active")],
      },
      errMsg: this.$t("common.msg.error.edit.msg"),
    };
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
  },
  mounted: async function () {
    this.getNewsDataForEdit();
    this.loadingOverlay = true;
  },
  methods: {
    isFormValid() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    submit() {
      if (this.isFormValid()) {
        this.loadingOverlay = !this.loadingOverlay;
        this.submitDialog = false;
        this.updateNews();
      } else {
        this.submitDialog = false;
        this.afterSubmitDialog = true;
      }
    },
    updateNews() {
      let newsFormData = new FormData();
      newsFormData.append("active", this.news.active);
      newsFormData.append("sortingOrder", this.news.sortingOrder);
      if (this.news.image != null && this.news.image !== undefined) {
        newsFormData.append("image", this.news.image);
      }
      console.log("Altered this.news, ", this.news);

      myApi
        .updateNews(this.$route.params.newsId, newsFormData)
        .then(() => {
          this.submitSuccess = true;
          this.afterSubmitDialog = true;
          this.resetValues();
          this.goToShowNewsTablePage();
        })
        .catch(() => {
          this.afterSubmitDialog = true;
          this.submitSuccess = false;
          this.loadingOverlay = false;
        });
    },
    getNewsDataForEdit() {
      myApi
        .getNewsData(this.$route.params.newsId)
        .then((response) => {
          this.news = response.data.data;
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOverlay = false));
    },
    resetAction() {
      this.loadingOverlay = true;
      window.location.reload();
    },
    resetValues() {
      this.loadingOverlay = false;
      this.resetValidation();
    },
    goToShowNewsTablePage() {
      console.log("Entered Show News");
      this.$router.push({
        name: "Show News Table",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    goToNewsViewPage() {
      console.log("Entered View News Page");
      this.$router.replace({
        name: "View News",
        params: {
          newsId: this.$route.params.newsId,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
  },
};
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: lightgray;
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
