<template>
  <v-card>
    <v-card-title>
      <b>{{ $t("rewardHistory.listingTable.title") }}</b>
    </v-card-title>
    
    <v-card-actions class="mx-2">
      Last Update Date:  {{ dateTime }}
    </v-card-actions>

  <div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :disabled="userType === 'USER'"
        @click="refreshRewardHistoryTable()"
        class="mx-4"
        v-if="isProgress == false"
      >
        Refresh
      </v-btn>

      <v-progress-circular class="mx-14" v-else model-value="20" indeterminate></v-progress-circular>
    </v-card-actions>
  </div>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="rewardHistoryTableData"
        :items-per-page="20"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :expanded.sync="expanded"
        :single-expand="singleExpand"
        show-expand
        style="cursor: pointer"
        item-key="transactionId"
        :loading="runLoading"
        loading-text="Loading... Please wait"
        :search="search"
        :custom-filter="filterText"
        show-group-by
        group-desc
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50, -1],
        }"
        class="elevation-1 my-data-table"
      >
        <template v-slot:top>
          <v-text-field
            append-icon="mdi-magnify"
            v-model="search"
            :label="$t('rewardHistory.listingTable.search')"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:[`item.senderDisplayName`]="{ item }">
          <tr v-if="item.senderDisplayName === null">
            NukCafe
          </tr>
          <tr>
            {{
              item.senderDisplayName
            }}
          </tr>
        </template>

        <template v-slot:[`item.receiverDisplayName`]="{ item }">
          <tr v-if="item.receiverDisplayName === null">
            NukCafe
          </tr>
          <tr>
            {{
              item.receiverDisplayName
            }}
          </tr>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" :style="{ background: ' #E0F2F1' }">
            <v-row>
              <v-col>
                {{
                  $t(
                    "rewardHistory.listingTable.column.details.senderAccountRef"
                  )
                }}: {{ item.senderAccountRef }}
              </v-col>
              <v-col>
                {{
                  $t(
                    "rewardHistory.listingTable.column.details.receiverAccountRef"
                  )
                }}: {{ item.receiverAccountRef }}
              </v-col>
            </v-row>
          </td>
        </template>

        <template v-slot:[`item.transactionDate`]="{ item }">
          {{ createdDate(item.transactionDate) }}
        </template>

        <template v-slot:no-data> {{ $t("common.msg.emtpyTable") }} </template>

        <template v-slot:no-results>
          {{ $t("common.msg.noDataFound") }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import myApi from "../../api/api.js";
import enJson from "../../locales/en.json";
import zhJson from "../../locales/zh.json";
import moment from "moment";

export default {
  props: {},
  data() {
    return {
      search: "",
      rewardHistoryTableData: [],
      headers: [],
      sortBy: "id",
      sortDesc: true,
      expanded: [],
      singleExpand: false,
      runLoading: true,
      searchParam: "",
      dateTime: "",
      isProgress: false,
    };
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
  },
  mounted() {
    console.log("Start to getAllRewardHistorys");
    this.getColumnHeader();
    this.getAllRewardsHistory();
    this.getSetting();
  },
  methods: {
    createdDate(target) {
      // Indicate the Correct Time Under Current Time Zone
      // console.log("Converted TO Moment TO Locale String", moment(target).toLocaleString())
      // console.log("Converted TO Moment TRY TRY ", moment(target).format("YYYY-MM-DD HH:mm:ss"))
      // return target.slice(0, 10) + " " + target.slice(11, 19);
      return moment(target).format("YYYY-MM-DD HH:mm:ss");
    },
    getColumnHeader() {
      if (this.$root.$i18n.locale === "en") {
        this.headers = [
          {
            text: enJson.rewardHistory.listingTable.column.headers
              .transactionId,
            value: "transactionId",
            groupable: false,
          },
          {
            text: enJson.rewardHistory.listingTable.column.headers
              .senderDisplayName,
            value: "senderDisplayName",
            groupable: false,
          },
          {
            text: enJson.rewardHistory.listingTable.column.headers
              .receiverDisplayName,
            value: "receiverDisplayName",
            groupable: false,
          },
          {
            text: enJson.rewardHistory.listingTable.column.headers.amount,
            value: "amount",
            groupable: false,
          },
          {
            text: enJson.rewardHistory.listingTable.column.headers
              .transactionDate,
            value: "transactionDate",
            groupable: false,
          },
          {
            text: enJson.rewardHistory.listingTable.column.headers.details,
            value: "data-table-expand",
            groupable: false,
          },
        ];
      }

      if (this.$root.$i18n.locale === "zh") {
        this.headers = [
          {
            text: zhJson.rewardHistory.listingTable.column.headers
              .transactionId,
            value: "transactionId",
            groupable: false,
          },
          {
            text: zhJson.rewardHistory.listingTable.column.headers
              .senderAddressHash,
            value: "senderAddressHash",
            groupable: false,
          },
          {
            text: zhJson.rewardHistory.listingTable.column.headers
              .receiverAddressHash,
            value: "receiverAddressHash",
            groupable: false,
          },
          {
            text: zhJson.rewardHistory.listingTable.column.headers.amount,
            value: "amount",
            groupable: false,
          },
          {
            text: zhJson.rewardHistory.listingTable.column.headers
              .transactionDate,
            value: "transactionDate",
            groupable: false,
          },
          {
            text: zhJson.rewardHistory.listingTable.column.headers.details,
            value: "data-table-expand",
            groupable: false,
          },
        ];
      }
    },
    getAllRewardsHistory() {
      this.isProgress = true;
      myApi
        .getAllRewardsHistory()
        .then((response) => {
          // this.rewardHistorysResponseDataList = response.data.data.content;
          this.rewardHistorysResponseDataList = response.data.data;
          // this.rewardHistorysResponseDataList.forEach((rewardHistory) => {
          //   let convertedDate = new Date(rewardHistory.transactionDate);
          //   rewardHistory.transactionDate = `${convertedDate
          //     .toISOString()
          //     .slice(0, 10)} ${convertedDate.toISOString().slice(11, 19)}`;
          //   this.rewardHistoryTableData.push(rewardHistory);
          // });
          if (response.data.success == true) {
            this.isProgress = false;
            if (this.rewardHistorysResponseDataList.length > 1) {
              this.rewardHistoryTableData = this.rewardHistorysResponseDataList
            }
            this.runLoading = false;
          }
          //this.rewardHistoryTableData = this.rewardHistorysResponseDataList;
          //this.runLoading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.runLoading = false));
    },
    filterText(value, search, item) {
      console.log({ value, search, item });
      return (
        value !== null &&
        search !== null &&
        item !== null &&
        typeof value === "string" &&
        item.transactionId.toString().includes(search) &&
        value.toString().indexOf(search) !== -1
      );
    },
    refreshRewardHistoryTable() {
      this.getAllRewardsHistory();
      // myApi.refreshRewardHistory().then((response) => {
      //   if (response.data.success == true) {
      //     this.getAllRewardsHistory();
      //     this.getSetting();
      //   }
      // });
    },
    getSetting() {
      this.isProgress = true;
      myApi.publicSetting().then((response) => {
        if (response.data.success == true) {
          console.log("Setting", response.data.data);
          this.isProgress = false;
          this.dateTime = response.data.data["nukcafe.etherium.timeStamp"];
        }
      });
    },
  },
};
</script>
