<template>
  <div>
    <div>
      <VoucherTable />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import VoucherTable from "@/components/Voucher/VoucherTableComponent.vue";

export default {
  name: "ViewVoucherList",
  components: {
    VoucherTable,
  },
  data() {
    return {};
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
  },
};
</script>
