<template>
  <div>
    <NewsViewDetail />
  </div>
</template>

<script>
// @ is an alias to /src
import NewsViewDetail from "@/components/News/NewsViewDetail.vue";

export default {
  props: {},
  name: "ViewNewsForm",
  components: {
    NewsViewDetail,
  },
  data() {
    return {};
  },
};
</script>
