<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title>
          <b>{{ $t("product.create.title") }} </b>
        </v-card-title>

        <v-card-text class="ml-4">
          <v-row justify="end" class="mr-8">
            <v-btn
              color="primary"
              outlined
              class="mr-4"
              dark
              @click="goToShowProductTablePage"
            >
              {{ $t("common.btn.back") }}
            </v-btn>
          </v-row>

          <br />

          <!-- Starting Content of the Create Form  -->

          <v-row align="center">
            <v-autocomplete
              dense
              v-model="product.categoryId"
              :items="categoryDropDownList"
              :label="$t('product.create.input.general.category')"
              required
              rounded
              :deletable-chips="true"
              :rules="validationRules.category"
              clearable
              outlined
            ></v-autocomplete>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.nameEn"
              :label="$t('product.create.input.general.nameEn')"
              required
              :rules="validationRules.nameEn"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.nameZh"
              :label="$t('product.create.input.general.nameZh')"
              required
              :rules="validationRules.nameZh"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.nameKm"
              :label="$t('product.create.input.general.nameKm')"
              required
              :rules="validationRules.nameKm"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.descriptionEn"
              :label="$t('product.create.input.general.descriptionEn')"
              required
              :rules="validationRules.descriptionEn"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.descriptionZh"
              :label="$t('product.create.input.general.descriptionZh')"
              required
              :rules="validationRules.descriptionZh"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.descriptionKm"
              :label="$t('product.create.input.general.descriptionKm')"
              required
              :rules="validationRules.descriptionKm"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.price"
              :label="$t('product.create.input.general.price')"
              required
              :rules="validationRules.price"
            ></v-text-field>
          </v-row>

          <v-row align="center">
            <v-text-field
              shaped
              outlined
              v-model="product.sequence"
              :label="$t('product.create.input.general.sequence')"
              required
              :rules="validationRules.sequence"
            ></v-text-field>
          </v-row>

          <v-file-input
            :label="$t('product.create.input.general.s3Image')"
            filled
            show-size
            counter
            clearable
            v-model="product.s3Image"
          ></v-file-input>

          <br />
        </v-card-text>
      </v-card>
      <br />

      <v-checkbox
        :label="$t('product.edit.input.feature.not_required')"
        v-model="noProductFeatures"
      >
      </v-checkbox>

      <v-card color="#BCAAA4" v-if="!noProductFeatures">
        <v-card-title>
          <b>{{ $t("product.create.input.feature.title") }}</b>
        </v-card-title>
        <v-tabs light v-model="tab" background-color="transparent" grow>
          <v-tab v-for="(productFeature, i) in productFeaturesList" :key="i">
            {{ productFeature.value }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(productFeature, i) in productFeaturesList"
            :key="i"
          >
            <v-card color="#EFEBE9">
              <v-card-text>
                <v-row align="center">
                  <v-text-field
                    shaped
                    outlined
                    v-model="productFeature.value"
                    :label="$t('product.create.input.feature.value')"
                    required
                    disabled
                  ></v-text-field>
                </v-row>

                <v-row align="center">
                  <v-text-field
                    shaped
                    outlined
                    v-model="productFeature.deltaPrice"
                    :label="$t('product.create.input.feature.deltaPrice')"
                    required
                  ></v-text-field>
                </v-row>

                <v-row align="center">
                  <v-text-field
                    shaped
                    outlined
                    v-model="productFeature.sortingOrder"
                    :label="$t('product.create.input.feature.sortingOrder')"
                    required
                  ></v-text-field>
                </v-row>

                <v-checkbox
                  :label="$t('product.edit.input.feature.active')"
                  v-model="productFeature.active"
                >
                </v-checkbox>

                <!-- End Content of the Create Form  -->
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <br />

      <v-row justify="end" class="mr-8">
        <v-btn
          color="primary"
          outlined
          dark
          @click="goToShowProductTablePage"
          class="mx-2"
        >
          {{ $t("common.btn.cancel") }}
        </v-btn>
        <v-btn color="primary" dark @click.stop="submitDialog = true">
          {{ $t("common.btn.save") }}
        </v-btn>

        <ConfirmSubmitDialog v-model="submitDialog" @submitevent="submit" />
      </v-row>
    </v-form>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <AfterSubmitDialog
      v-model="afterSubmitDialog"
      :submitSuccess="submitSuccess"
      :errMsg="errMsg"
    />
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";
import AfterSubmitDialog from "../AfterSubmitDialog.vue";
import ConfirmSubmitDialog from "../ConfirmSubmitDialogNonBlockchain.vue";

export default {
  components: {
    AfterSubmitDialog,
    ConfirmSubmitDialog,
  },
  props: [],
  data() {
    return {
      loadingOverlay: false,
      afterSubmitDialog: false,
      submitSuccess: false,
      submitDialog: false,
      categoryDropDownList: [],
      product: {
        nameEn: "",
        nameZh: "",
        nameKm: "",
        descriptionEn: "",
        descriptionZh: "",
        descriptionKm: "",
        categoryId: "",
        price: "",
        sequence: "",
        s3Image: [],
        productFeaturesJSONString: "",
      },
      noProductFeatures: false,
      productFeaturesList: [
        {
          type: "SIZE",
          value: "LARGE",
          deltaPrice: "0",
          sortingOrder: "0",
          active: true,
        },
        {
          type: "SIZE",
          value: "MEDIUM",
          deltaPrice: "0",
          sortingOrder: "1",
          active: true,
        },
        {
          type: "SIZE",
          value: "SMALL",
          deltaPrice: "0",
          sortingOrder: "2",
          active: true,
        },
      ],
      tab: null,
      validationRules: {
        nameEn: [(v) => !!v || this.$t("product.create.error.nameEn")],
        nameZh: [(v) => !!v || this.$t("product.create.error.nameZh")],
        nameKm: [(v) => !!v || this.$t("product.create.error.nameKm")],
        descriptionEn: [
          (v) => !!v || this.$t("product.create.error.descriptionEn"),
        ],
        descriptionZh: [
          (v) => !!v || this.$t("product.create.error.descriptionZh"),
        ],
        descriptionKm: [
          (v) => !!v || this.$t("product.create.error.descriptionKm"),
        ],
        category: [(v) => !!v || this.$t("product.create.error.category")],
        price: [(v) => !!v || this.$t("product.create.error.price")],
        sequence: [(v) => !!v || this.$t("product.edit.error.sequence")],
        type: [(v) => !!v || this.$t("product.create.error.type")],
        display: [(v) => !!v || this.$t("product.create.error.display")],
        s3Image: [(v) => !!v || this.$t("product.create.error.s3Image")],
        deltaPrice: [(v) => !!v || this.$t("product.create.error.deltaPrice")],
        value: [(v) => !!v || this.$t("product.create.error.value")],
        sortingOrder: [
          (v) => !!v || this.$t("product.create.error.sortingOrder"),
        ],
      },
      errMsg: this.$t("common.msg.error.create.msg"),
    };
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType;
    },
  },
  mounted: async function () {
    this.loadingOverlay = true;
    await this.getAllCategories();
    this.loadingOverlay = false;
  },
  methods: {
    isFormValid() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    submit() {
      if (this.isFormValid()) {
        this.loadingOverlay = !this.loadingOverlay;
        this.submitDialog = false;
        this.createProduct();
      } else {
        this.submitDialog = false;
        this.afterSubmitDialog = true;
      }
    },
    getAllCategories() {
      console.log("Start Getting Category List");
      myApi
        .getAllCategories()
        .then((response) => {
          // console.log("getAllCateResponse", response);
          this.categoryList = response.data.data;
          this.categoryDropDownList = this.categoryList
            .filter((cat) => cat.id !== -1)
            .map((category) => category.name);
          // console.log("categoryDropDownList", this.categoryDropDownList);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.runLoading = false));
    },
    createProduct() {
      this.product.productFeaturesJSONString = this.noProductFeatures ? "[]" : JSON.stringify(
        this.productFeaturesList
      );
      let category = this.categoryList.find(
        (category) => category.name === this.product.categoryId
      );
      this.product.categoryId = category.id;
      // console.log("Product Targted: ", this.product);

      let productFormData = new FormData();
      productFormData.append("nameEn", this.product.nameEn);
      productFormData.append("nameZh", this.product.nameZh);
      productFormData.append("nameKm", this.product.nameKm);
      productFormData.append("descriptionEn", this.product.descriptionEn);
      productFormData.append("descriptionZh", this.product.descriptionZh);
      productFormData.append("descriptionKm", this.product.descriptionKm);
      productFormData.append("categoryId", this.product.categoryId);
      productFormData.append("price", this.product.price);
      productFormData.append("sequence", this.product.sequence);
      productFormData.append("s3Image", this.product.s3Image);
      productFormData.append(
        "productFeaturesJSONString",
        this.product.productFeaturesJSONString
      );

      myApi
        .createProduct(productFormData)
        .then(() => {
          this.submitSuccess = true;
          this.afterSubmitDialog = true;
          this.resetValues();
          this.goToShowProductTablePage();
        })
        .catch(() => {
          this.afterSubmitDialog = true;
          this.submitSuccess = false;
          this.loadingOverlay = false;
        });
    },
    resetValues() {
      this.loadingOverlay = false;
      this.resetValidation();
    },
    goToShowProductTablePage() {
      console.log("Entered Show Product");
      this.$router.push({
        name: "Show Product Table",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
  },
};
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: lightgray;
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
