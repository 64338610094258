<template>
  <div>
    <OrderChangeStatusEdit />
  </div>
</template>

<script>
// @ is an alias to /src
import OrderChangeStatusEdit from "@/components/Order/OrderChangeStatusEdit.vue";

export default {
  name: "ChangeOrderStatus",
  components: {
    OrderChangeStatusEdit,
  },
  data() {
    return {};
  },
};
</script>
