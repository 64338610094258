<template>
  <div>
    <div>
      <ProductTable />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ProductTable from "@/components/Product/ProductTableComponent.vue";

export default {
  name: "ViewProductList",
  components: {
    ProductTable,
  },
  data() {
    return {};
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
  },
};
</script>
