<template>
  <v-card>
    <v-card-title>
      <b>{{ $t("news.listingTable.title") }}</b>
      <v-row justify="end" class="mr-8">
        <v-btn color="primary" dark @click="goToCreateNewsPage">
          {{ $t("news.listingTable.addNews") }}
          <v-icon dark class="ml-1"> mdi-plus </v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="newsTableData"
        :items-per-page="20"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :expanded.sync="expanded"
        style="cursor: pointer"
        :single-expand="singleExpand"
        item-key="id"
        :loading="runLoading"
        loading-text="Loading... Please wait"
        :search="search"
        :custom-filter="filterText"
        show-group-by
        group-desc
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50, -1],
        }"
        class="elevation-1 my-data-table"
        @click:row="myRowClickHandler"
      >
        <template v-slot:top>
          <v-text-field
            append-icon="mdi-magnify"
            v-model="search"
            :label="$t('news.listingTable.search')"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:no-data> {{ $t("common.msg.emtpyTable") }} </template>

        <template v-slot:no-results>
          {{ $t("common.msg.noDataFound") }}
        </template>

        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.imageURL="{ item }">
          <img :src="item.imageURL" style="max-height: 200px">
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import myApi from "../../api/api.js";
import enJson from "../../locales/en.json";
import zhJson from "../../locales/zh.json";

export default {
  props: {},
  data() {
    return {
      search: "",
      newsTableData: [],
      itemHistory: [],
      headers: [],
      sortBy: "id",
      sortDesc: true,
      expanded: [],
      singleExpand: true,
      runLoading: true,
      searchParam: "",
    };
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
  },
  mounted() {
    console.log("Start to getAllNews");
    this.getColumnHeader();
    this.getAllNews();
  },
  methods: {
    getColumnHeader() {
      if (this.$root.$i18n.locale === "en") {
        this.headers = [
          {
            text: enJson.news.listingTable.column.headers.id,
            value: "id",
            groupable: false,
          },
          {
            text: enJson.news.listingTable.column.headers.imageURL,
            value: "imageURL",
            groupable: false,
            sortable: false,
          },
          {
            text: enJson.news.listingTable.column.headers.sortingOrder,
            value: "sortingOrder",
            groupable: false,
          },
        ];
      }

      if (this.$root.$i18n.locale === "zh") {
        this.headers = [
          {
            text: zhJson.news.listingTable.column.headers.id,
            value: "id",
            groupable: false,
          },
          {
            text: zhJson.news.listingTable.column.headers.imageURL,
            value: "imageURL",
            groupable: false,
          },
          {
            text: enJson.news.listingTable.column.headers.sortingOrder,
            value: "sortingOrder",
            groupable: false,
          },
        ];
      }
    },
    myRowClickHandler(record) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      // console.log(index, record); // This will be the item data for the row
      console.log("Entered Edit News Table");
      this.$router.push({
        name: "View News",
        params: {
          newsId: record.id,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    getAllNews() {
      myApi
        .getAllNews()
        .then((response) => {
          this.newsResponseDataList = response.data.data;
          console.log("this.newsResponseDataList: ");
          this.newsTableData = this.newsResponseDataList;
          this.runLoading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.runLoading = false));
    },
    goToCreateNewsPage() {
      console.log("Entered Create News");
      this.$router.push({
        name: "Create News",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    filterText(value, search, item) {
      console.log({ value, search, item });
      return (
        value !== null &&
        search !== null &&
        item !== null &&
        typeof value === "number" &&
        item.id.toString().includes(search) &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
};
</script>
