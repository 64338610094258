import axios from "axios"
import store from "../store"

const MOCK_LOGIN = process.env["VUE_APP_MOCK_LOGIN"] === "true"
const BASE_URL = process.env["VUE_APP_BASE_URL"]

const myAxios = axios.create({
  baseURL: MOCK_LOGIN ? BASE_URL : "/nukcafe/api/v1",
  headers: MOCK_LOGIN
    ? {
        Authorization: "Bearer mock/a0a25e04-51e9-42c7-851d-7606ffc59e7f",
      }
    : undefined,
  validateStatus: false,
})

myAxios.interceptors.response.use((response) => {
  if (!response.data.success) {
    switch (response.data.msg) {
      case "NC0000":
        // Unknown exception
        store.commit(
          "openErrorAlertDialog",
          `(${response.data.msg}) Unknown system error. Please contact system maintainer: ${response.data.reason}`
        )
        break
      case "NC0001":
        // Bad request
        store.commit(
          "openErrorAlertDialog",
          `(${response.data.msg}) Cannot process your request. Please check your input and try again: ${response.data.reason}`
        )
        break
      case "NC1001":
        // Permission denied
        // TODO: Just logging out for permission denied is not good, better to have "not authorized" error.
        // window.location.href = "/nukcafe/api/v1/oauth2/logout"
        break
      case "RW0002":
        // Reward Insufficient funds
        store.commit(
          "openErrorAlertDialog",
          `(${response.data.msg}) ${response.data.reason}`
        )
        break
      default:
        store.commit(
          "openErrorAlertDialog",
          `(${response.data.msg}) ${response.data.reason}`
        )
        break
    }
    store.subscribeAction
  }

  return response
})

export default {
  // ---------------------------- NukCafe User Management ------------------------------------
  // -----------------------------------------------------------------------------------------
  login: function(loginObj) {
    return myAxios.post("/login", loginObj)
  },

  getCurrentUser: function() {
    return myAxios.get("/staff/current")
  },
  // ---------------------------- Order Management ------------------------------------
  // ----------------------------------------------------------------------------------
  getAllOrders: async function(daysBack = 365 * 5) {
    return await myAxios.get("/staff/order/getAll?daysBack=" + daysBack)
  },
  getOrderData: async function(orderId) {
    return await myAxios({
      method: "get",
      url: `/staff/order/get/${orderId}`,
    })
  },
  paymentManualRefund: async function(
    paymentRef,
    refundedAmount,
    refundRemarks
  ) {
    const data = new FormData()
    data.append("paymentRef", paymentRef)
    data.append("refundedAmount", refundedAmount)
    data.append("refundRemarks", refundRemarks)
    return await myAxios({
      method: "post",
      url: `/staff/payment/manualRefund`,
      data,
    })
  },
  paymentManualPay: async function(paymentRef) {
    const data = new FormData()
    data.append("paymentRef", paymentRef)
    return await myAxios({
      method: "post",
      url: `/staff/payment/manualPay`,
      data,
    })
  },
  verifyPickupCode: async function(code) {
    return await myAxios({
      method: "get",
      url: `/staff/order/verifyPickupCode?code=${encodeURIComponent(code)}`,
    })
  },
  changeOrderStatus: async function(orderId, orderStatusForm) {
    return await myAxios.post(
      `/staff/order/${orderId}/changeStatus`,
      orderStatusForm
    )
  },
  // ----------------------------- Product Management --------------------------
  // ---------------------------------------------------------------------------
  getAllProducts: function() {
    return myAxios.get("/staff/product/getAll")
  },
  getProductData: function(productId) {
    return myAxios({
      method: "get",
      url: `/product/get/${productId}`,
    })
  },
  getProductDataForEdit: function(productId) {
    return myAxios({
      method: "get",
      url: `/staff/product/getForEdit/${productId}`,
    })
  },
  createProduct: async function(productFormData) {
    return await myAxios({
      method: "post",
      url: "/staff/product/create",
      data: productFormData,
      headers: {
        "Content-Type":
          "multipart/form-data;boundary = " + new Date().getTime(),
      },
    })
  },
  updateProductWithPic: async function(
    productId,
    editedProductData,
    contentType
  ) {
    return await myAxios({
      method: "post",
      url: `/staff/product/update/${productId}`,
      data: editedProductData,
      headers: {
        "Content-Type": contentType,
      },
    })
  },
  updateProduct: async function(productId, editedProductData) {
    return await myAxios({
      method: "post",
      url: `/staff/product/update/${productId}`,
      data: editedProductData,
    })
  },
  deleteProduct: async function(productId) {
    var bodyFormData = new FormData()
    bodyFormData.append("id", productId)
    return await myAxios({
      method: "post",
      url: "/staff/product/delete",
      data: bodyFormData,
    })
  },
  toggleProductStatus: async function(productId, productStatusForm) {
    return await myAxios.post(
      `/staff/product/toggleActivation/${productId}`,
      productStatusForm
    )
  },

  // ----------------------------- Category Management ---------------------------
  // -----------------------------------------------------------------------------
  getAllCategories: function() {
    let result = myAxios.get("/category/getAll")
    return result
  },

  getSubCategoryDetail: function(categoryId) {
    return myAxios({
      method: "get",
      url: `/category/get/${categoryId}`,
    })
  },

  createSubCategory: async function(categoryDTO) {
    return await myAxios({
      method: "post",
      url: `/category/create`,
      data: categoryDTO,
    })
  },

  updateSubCategory: async function(id, dto) {
    return await myAxios({
      method: "post",
      url: `/category/update/${id}`,
      data: dto,
    })
  },

  deleteSubCategory: async function(id) {
    return await myAxios.post(`/category/delete?id=${id}`)
  },

  // ----------------------------- Post Management --------------------------
  // ------------------------------------------------------------------------
  getAllPosts: function() {
    return myAxios.get("/staff/post/getAll")
  },

  getPostData: function(postId) {
    return myAxios({
      method: "get",
      url: `/staff/post/get/${postId}`,
    })
  },

  screenPost: async function(postId, postFormData) {
    // var bodyFormData = new FormData();
    // bodyFormData.append("id", productId);
    return await myAxios({
      method: "post",
      url: `/staff/post/screen/${postId}`,
      data: postFormData,
    })
  },

  // ----------------------------- News Management --------------------------
  // ---------------------------------------------------------------------------
  getAllNews: function() {
    return myAxios.get("/staff/news/getAll")
  },

  createNews: async function(data) {
    return await myAxios({
      method: "post",
      url: `/staff/news/add`,
      data: data,
      headers: {
        "Content-Type":
          "multipart/form-data;boundary = " + new Date().getTime(),
      },
    })
  },

  getNewsData: function(newsId) {
    return myAxios({
      method: "get",
      url: `/staff/news/getForEdit/${newsId}`,
    })
  },

  updateNews: async function(newsId, editedNewsData) {
    return await myAxios({
      method: "post",
      url: `/staff/news/update/${newsId}`,
      data: editedNewsData,
      headers: {
        "Content-Type":
          "multipart/form-data;boundary = " + new Date().getTime(),
      },
    })
  },

  // ----------------------------- Voucher Management --------------------------
  // ---------------------------------------------------------------------------
  getAllVouchers: function() {
    return myAxios.get("/staff/vouchers/getAll")
  },

  createVoucher: async function(voucherData) {
    return await myAxios({
      method: "post",
      url: `/staff/vouchers/add`,
      data: voucherData,
      headers: {
        "Content-Type":
          "multipart/form-data;boundary = " + new Date().getTime(),
      },
    })
  },

  getVoucherData: function(voucherId) {
    return myAxios({
      method: "get",
      url: `/staff/vouchers/getForEdit/${voucherId}`,
    })
  },

  updateVoucher: async function(voucherId, editedVoucherData) {
    return await myAxios({
      method: "post",
      url: `/staff/vouchers/edit/${voucherId}`,
      data: editedVoucherData,
      headers: {
        "Content-Type":
          "multipart/form-data;boundary = " + new Date().getTime(),
      },
    })
  },

  // ----------------------------- Loyalty Management --------------------------
  // ---------------------------------------------------------------------------
  getAllRewardsHistory: function() {
    return myAxios.get("/staff/rewardHistory")
  },

  refreshRewardHistory: async function() {
    return myAxios.get("/staff/rewardHistory/reload")
  },

  // ----------------------------- Customer Management --------------------------
  // ---------------------------------------------------------------------------
  getAllCustomerRewardAccounts: function(isRemote) {
    return myAxios.get(`/staff/reward/accounts?isRemote=${isRemote}`)
  },

  addRewards: async function(customerId, pointsDTO) {
    return await myAxios({
      method: "post",
      url: `/staff/reward/addPoints/${customerId}`,
      data: pointsDTO,
    })
  },

  subtractRewards: async function(customerId, pointsDTO) {
    return await myAxios({
      method: "post",
      url: `/staff/reward/removePoints/${customerId}`,
      data: pointsDTO,
    })
  },

  // ----------------------------- Setting Management --------------------------
  // ---------------------------------------------------------------------------
  getAllSettings: function() {
    return myAxios.get("/staff/settings/getAll")
  },

  updateSetting: async function(settingEditForm) {
    return await myAxios.post(`/staff/settings/bulk-edit`, settingEditForm)
  },

  // ----------------------------- remove voucher --------------------------
  removeVoucher: async function(voucherIds) {
    return await myAxios({
      method: "delete",
      url: `/staff/vouchers/remove`,
      data: voucherIds,
    })
  },

  voucherCustomer: async function(id) {
    return await myAxios.get(`/staff/customers/${id}/vouchers`)
  },

  disableVoucher: async function(customerVoucherId) {
    return await myAxios.patch(
      `/staff/customers/vouchers/${customerVoucherId}/disable`
    )
  },

  enableVoucher: async function(customerVoucherId) {
    return await myAxios.patch(
      `/staff/customers/vouchers/${customerVoucherId}/enable`
    )
  },

  publicSetting: async function() {
    return await myAxios.get("/public/schedules/settings")
  },

  getMainCategory: async function() {
    return await myAxios.get("/main-category/getAll")
  },

  getMainCategoryById: async function(mainCategoryId) {
    return await myAxios.get(`/main-category/get/${mainCategoryId}`)
  },

  createMainCategory: async function(mainCategoryDTO) {
    return await myAxios({
      method: "post",
      url: `/main-category/create`,
      data: mainCategoryDTO,
    })
  },

  deleteMainCategory: async function(id) {
    return await myAxios.delete(`/main-category/delete?id=${id}`)
  },

  updateMainCategory: async function(id, mainCategoryCreationDTO) {
    // return await myAxios.post(
    //   `/main-category/update/${id}`,
    //   mainCategoryCreationDTO
    // )
    return await myAxios({
      method: "post",
      url: `/main-category/update/${id}`,
      data: mainCategoryCreationDTO,
    })
  },

  getVoucherType: async function() {
    return await myAxios.get("/vouchers/getTypes")
  },

  tokenFirebase: async function(data) {
    return await myAxios.post("/notification/refreshRegistrationToken", data)
  },
  
  registerStaffDeviceUsingPOST: async function(data) {
    return await myAxios.post(
      `/notification/registerStaffDevice?notificationType=${data.type}`,
      data
    );
  },

  getPopUpStatus: async function() {
    return (await myAxios.get("/popUp")).data
  },

  updatePopUpStatus: async function(active) {
    return await myAxios.put(`/popUp/${active	}`);
  },
}
