<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title>
          <b>{{ $t("order.edit.title") }}</b>
        </v-card-title>

        <v-card-text class="ml-4">
          <v-row justify="end" class="mr-8">
            <v-btn
              color="primary"
              outlined
              class="mr-4"
              dark
              @click="goToShowOrderDetailTablePage"
            >
              {{ $t("common.btn.back") }}
            </v-btn>
          </v-row>
          <br />
          <!-- Starting Content of the Edit Form  -->

          <v-row align="center">
            <v-autocomplete
              v-model="order.status"
              :items="orderStatusDropDownList"
              item-text="name"
              :label="$t('order.edit.input.orderStatus')"
              required
              :rules="validationRules.orderStatus"
              shaped
              chips
              outlined
            ></v-autocomplete>
          </v-row>

          <!-- Ending Content of the Edit Form  -->

          <v-row justify="end" class="mr-8">
            <v-btn color="primary" dark @click.stop="submitDialog = true">
              {{ $t("common.btn.save") }}
            </v-btn>
            <ConfirmSubmitDialog v-model="submitDialog" @submitevent="submit" />
          </v-row>
        </v-card-text>
      </v-card>
      <br />
    </v-form>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <AfterSubmitDialog
      v-model="afterSubmitDialog"
      :submitSuccess="submitSuccess"
      :errMsg="errMsg"
    />
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";
import AfterSubmitDialog from "../AfterSubmitDialog.vue";
import ConfirmSubmitDialog from "../ConfirmSubmitDialogNonBlockchain.vue";

export default {
  components: {
    AfterSubmitDialog,
    ConfirmSubmitDialog,
  },
  props: [],
  data() {
    return {
      loadingOverlay: false,
      afterSubmitDialog: false,
      validationRules: {
        orderStatus: [(v) => !!v || this.$t("order.edit.error.orderStatus")],
      },
      submitSuccess: false,
      order: {},
      submitDialog: false,
      errMsg: this.$t("common.msg.error.edit.msg"),
      orderStatusDropDownList: [
        { name: "PENDING", disabled: false },
        { name: "RECEIVED", disabled: false },
        { name: "MAKING", disabled: false },
        { name: "READY", disabled: false },
        { name: "COMPLETED", disabled: false },
        { name: "FAIL", disabled: false },
      ],
    };
  },
  computed: {},
  mounted() {
    console.log("Start getting Order Detailed Data");
    this.loadingOverlay = true;
    this.getOrderData();
  },
  methods: {
    isFormValid() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    submit() {
      if (this.isFormValid()) {
        this.loadingOverlay = !this.loadingOverlay;
        this.submitDialog = false;
        this.updateOrderStatus();
      } else {
        this.submitDialog = false;
        this.afterSubmitDialog = true;
      }
    },
    updateOrderStatus() {
      var bodyFormData = new FormData();
      bodyFormData.append("status", this.order.status);
      myApi
        .changeOrderStatus(this.$route.params.orderId, bodyFormData)
        .then(() => {
          this.submitSuccess = true;
          this.afterSubmitDialog = true;
          this.resetValues();
          this.goToShowOrderDetailTablePage();
        })
        .catch(() => {
          this.afterSubmitDialog = true;
          this.submitSuccess = false;
          this.loadingOverlay = false;
        });
    },
    getOrderData() {
      myApi
        .getOrderData(this.$route.params.orderId)
        .then((response) => {
          this.order = response.data.data;
          // console.log("this.order", this.order);
          // console.log("Allowed Status", this.order.allowedStatuses);
          this.orderStatusDropDownList.forEach((orderStatusObj) => {
            if (!this.order.allowedStatuses.includes(orderStatusObj.name)) {
              orderStatusObj.disabled = true;
            }
          });
          // console.log(
          //   "Latest this.orderStatusDropDownList",
          //   this.orderStatusDropDownList
          // );
          this.loadingOverlay = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loadingOverlay = false));
    },
    resetValues() {
      this.loadingOverlay = false;
      this.resetValidation();
    },
    goToShowOrderDetailTablePage() {
      console.log("Entered Show Order Detail Page");
      this.$router.push({
        name: "View Order",
        params: {
          orderId: this.$route.params.orderId,
          locale: this.$root.$i18n.locale,
          userType: this.$store.state.userInfo.userType,
        },
      });
    },
  },
};
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: lightgray;
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
