<template>
  <div>
    <ProductViewDetail />
  </div>
</template>

<script>
// @ is an alias to /src
import ProductViewDetail from "@/components/Product/ProductViewDetail.vue";

export default {
  props: {},
  name: "ViewProductForm",
  components: {
    ProductViewDetail,
  },
  data() {
    return {};
  },
};
</script>
