<template>
  <v-container>
    <div>
      <v-alert
        v-if="alert !== null && alert.value === 'success'"
        type="success"
        dismissible
      >
        Submission Success !
      </v-alert>

      <v-alert
        v-if="alert !== null && alert.value === 'error'"
        type="error"
        dismissible
      >
        Submission Failed : {{ alert.msg }} !
      </v-alert>
    </div>

    <v-card>
      <v-card-title>
        <b>{{ $t("customer.rewardAccount.listingTable.title") }}</b>
      </v-card-title>

      <v-card-actions class="mx-2">
      Last Update Date:  {{ dateTime }}
    </v-card-actions>

      <div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="userType === 'USER'"
          @click="refreshUser()"
          class="mx-4"
          v-if="isProgress == false"
        >
          Refresh
        </v-btn>

        <v-progress-circular
          class="mx-14"
          v-else
          model-value="20"
          indeterminate
        ></v-progress-circular>
      </v-card-actions>
    </div>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="customerRewardAccountTableData"
          :items-per-page="20"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :expanded.sync="expanded"
          style="cursor: pointer"
          :single-expand="singleExpand"
          item-key="customerId"
          :loading="runLoading"
          loading-text="Loading... Please wait"
          :search="search"
          :custom-filter="filterText"
          show-group-by
          group-desc
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50, -1],
          }"
          class="elevation-1 my-data-table"
          @click:row="voucherCustomers"
        >
          <template v-slot:top>
            <v-text-field
              append-icon="mdi-magnify"
              v-model="search"
              :label="$t('customer.rewardAccount.listingTable.search')"
              class="mx-4"
            ></v-text-field>

            <v-dialog v-model="dialogForAddition" max-width="500px">
              <v-card flat color="#FFF3E0">
                <v-card-title>
                  <span class="text-h5">
                    Add Points To Customer {{ editedItem.customerId }}
                  </span>
                </v-card-title>
                <br />
                <v-card-text>
                  <v-row class="abc">
                    <v-text-field
                      prepend-inner-icon="mdi-plus"
                      v-model="addLPoints"
                      label="Amount"
                      round
                      type="number"
                      step="1"
                      class="v-card-text-field"
                      shaped
                      outlined
                      dense
                      clearable
                      :rules="[validationRules.min, validationRules.amount]"
                    ></v-text-field>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="cancelAction">
                    {{ $t("common.btn.cancel") }}
                  </v-btn>

                  <v-btn
                    color="primary"
                    dark
                    @click.stop="submitAdd(addLPoints, editedItem.customerId)"
                  >
                    {{ $t("common.btn.submit") }}
                  </v-btn>

                  <!-- <AddLoyaltyPointsConfirmSubmit
                    v-model="submitDialog"
                    :points="addLPoints"
                    :customerId="editedItem.customerId"
                    @submitevent="submitAdd(addLPoints, editedItem.customerId)"
                  /> -->
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogForSubtraction" max-width="500px">
              <v-card flat color="#FFF3E0">
                <v-card-title>
                  <span class="text-h5">
                    Subtract Points From Customer {{ editedItem.customerId }}
                  </span>
                </v-card-title>
                <br />
                <v-card-text>
                  <v-row class="abc">
                    <v-text-field
                      prepend-inner-icon="mdi-minus"
                      v-model="subtractLPoints"
                      label="Amount"
                      round
                      type="number"
                      step="1"
                      class="v-card-text-field"
                      shaped
                      outlined
                      dense
                      clearable
                      :rules="[validationRules.min, validationRules.amount]"
                    ></v-text-field>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="blue darken-1" text @click="cancelAction">
                    {{ $t("common.btn.cancel") }}
                  </v-btn>

                  <v-btn
                    color="primary"
                    dark
                    @click.stop="
                      submitSubtract(subtractLPoints, editedItem.customerId)
                      "
                  >
                    {{ $t("common.btn.submit") }}
                  </v-btn>

                  <!-- <SubtractLoyaltyPointsConfirmSubmit
                    v-model="submitDialog"
                    :points="subtractLPoints"
                    :customerId="editedItem.customerId"
                    @submitevent="
                      submitSubtract(subtractLPoints, editedItem.customerId)
                    "
                  /> -->
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog max-width="500px" v-model="isDialog">
              <v-card>
                <v-card-title>
                  <span class="text-h5">
                    The point will be update around 5 minutes
                  </span>
                </v-card-title>
                <br />

                <div class="py-4">
                  <v-btn color="primary" dark @click="closeDialog">
                    {{ $t("common.btn.ok") }}
                  </v-btn>
                </div>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:[`item.displayName`]="{ item }">
            <tr v-if="item.displayName === null">
              N/A
            </tr>
            <tr>
              {{
                item.displayName
              }}
            </tr>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <tr v-if="item.email === null">
              N/A
            </tr>
            <tr>
              {{
                item.email
              }}
            </tr>
          </template>

          <template v-slot:[`item.phone`]="{ item }">
            <tr v-if="item.phone === null">
              N/A
            </tr>
            <tr>
              {{
                item.phone
              }}
            </tr>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="addLoyaltyPoints(item)">
              mdi-plus-outline
            </v-icon>
            <v-icon small @click="subtractLoyaltyPoints(item)"
              >mdi-minus-box-outline
            </v-icon>
          </template>

          <template v-slot:no-data>
            {{ $t("common.msg.emtpyTable") }}
          </template>

          <template v-slot:no-results>
            {{ $t("common.msg.noDataFound") }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-overlay :value="loadingOverlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <!-- <AfterSubmitDialog
        v-model="afterSubmitDialog"
        :submitSuccess="submitSuccess"
        :errMsg="errMsg"
        @input="getAlertMessage($event)"
      /> -->
    </v-card>
  </v-container>
</template>

<script>
import myApi from "../../api/api.js"
import enJson from "../../locales/en.json"
import zhJson from "../../locales/zh.json"
// import AfterSubmitDialog from "../AfterSubmitDialog.vue";
// import AddLoyaltyPointsConfirmSubmit from "../Dialog/AddLoyaltyPointsConfirmSubmit.vue"
// import SubtractLoyaltyPointsConfirmSubmit from "../Dialog/SubtractLoyaltyPointsConfirmSubmit.vue"

export default {
  // components: {
  //   // AfterSubmitDialog,
  //   // AddLoyaltyPointsConfirmSubmit,
  //   // SubtractLoyaltyPointsConfirmSubmit,
  // },
  created() {
    this.alert = this.getWithExpiry("alert")
    console.log("this alert, ", this.alert)
    // localStorage.setItem("alertMessageType", "success");
  },
  data() {
    return {
      isProgress: false,
      isDialog: false,
      isLoading: false,
      dateTime: "",
      alert: {},
      loadingOverlay: false,
      afterSubmitDialog: false,
      submitSuccess: false,
      submitDialog: false,
      dialogForAddition: false,
      dialogForSubtraction: false,
      addLPoints: "0",
      subtractLPoints: "0",
      editedItem: {
        customerId: "",
        displayName: "",
        accountRef: "",
        balance: "",
      },
      validationRules: {
        min: (v) =>
          v >= 0 || this.$t("customer.rewardAccount.adjust.error.min"),
        amount: (v) =>
          !!v || this.$t("customer.rewardAccount.adjust.error.min"),
      },
      search: "",
      customerRewardAccountTableData: [],
      itemHistory: [],
      headers: [],
      sortBy: "customerId",
      sortDesc: true,
      expanded: [],
      singleExpand: true,
      runLoading: true,
      searchParam: "",
      errMsg: this.$t("common.msg.error.submit.msg"),
    }
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName
    },
    userType() {
      return this.$store.state.userInfo.userType
    },
  },
  mounted() {
    console.log("Start to getAllCustomerRewardAccounts")
    this.getColumnHeader()
    this.getAllCustomerRewardAccounts(false);
    this.getSetting();
    // this.alert = this.getWithExpiry("alert");
    // console.log("this alert, ", this.alert);
  },
  methods: {
    setWithExpiry(key, value, ttl, msg) {
      const now = new Date()
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        msg: msg,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item))
    },
    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item
    },
    getColumnHeader() {
      if (this.$root.$i18n.locale === "en") {
        this.headers = [
          {
            text:
              enJson.customer.rewardAccount.listingTable.column.headers
                .customerId,
            value: "customerId",
            groupable: false,
          },
          {
            text:
              enJson.customer.rewardAccount.listingTable.column.headers
                .displayName,
            value: "displayName",
            groupable: false,
          },
          {
            text:
              enJson.customer.rewardAccount.listingTable.column.headers.email,
            value: "email",
            groupable: false,
          },
          {
            text:
              enJson.customer.rewardAccount.listingTable.column.headers.phone,
            value: "phone",
            groupable: false,
          },
          {
            text:
              enJson.customer.rewardAccount.listingTable.column.headers
                .accountRef,
            value: "accountRef",
            groupable: false,
          },
          {
            text:
              enJson.customer.rewardAccount.listingTable.column.headers.balance,
            value: "balance",
            groupable: false,
          },
          {
            text:
              enJson.customer.rewardAccount.listingTable.column.headers.actions,
            value: "actions",
            groupable: false,
          },
        ]
      }

      if (this.$root.$i18n.locale === "zh") {
        this.headers = [
          {
            text:
              zhJson.customer.rewardAccount.listingTable.column.headers
                .customerId,
            value: "customerId",
            groupable: false,
          },
          {
            text:
              zhJson.customer.rewardAccount.listingTable.column.headers
                .displayName,
            value: "displayName",
            groupable: false,
          },
          {
            text:
              zhJson.customer.rewardAccount.listingTable.column.headers.email,
            value: "email",
            groupable: false,
          },
          {
            text:
              zhJson.customer.rewardAccount.listingTable.column.headers.phone,
            value: "phone",
            groupable: false,
          },
          {
            text:
              zhJson.customer.rewardAccount.listingTable.column.headers
                .accountRef,
            value: "accountRef",
            groupable: false,
          },
          {
            text:
              zhJson.customer.rewardAccount.listingTable.column.headers.balance,
            value: "balance",
            groupable: false,
          },
          {
            text:
              zhJson.customer.rewardAccount.listingTable.column.headers.actions,
            value: "actions",
            groupable: false,
          },
        ]
      }
    },
    getAllCustomerRewardAccounts(isRemote) {
      myApi
        .getAllCustomerRewardAccounts(isRemote)
        .then((response) => {
          this.customerRewardAccountsResponseDataList = response.data.data
          this.customerRewardAccountTableData = this.customerRewardAccountsResponseDataList
          this.runLoading = false
          this.isProgress = false;
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => (this.runLoading = false))
    },
    submitAdd(points, customerId) {
      this.loadingOverlay = !this.loadingOverlay
      this.submitDialog = false
      this.isDialog = true
      this.addRewards(points, customerId)
    },
    submitSubtract(points, customerId) {
      this.loadingOverlay = !this.loadingOverlay
      this.submitDialog = false
      this.isDialog = true
      this.subtractRewards(points, customerId)
    },
    addRewards(points, customerId) {
      let pointsDTO = { points: points }
      myApi
        .addRewards(customerId, pointsDTO)
        .then((response) => {
          // console.log(response);
          // this.submitSuccess = true;
          this.dialogForAddition = false
          // this.afterSubmitDialog = true;
          console.log("Entered addRewards Success")
          if (response.data.success === false) {
            this.setWithExpiry("alert", "error", 3000, response.data.reason)
            this.submitSuccess = false
          } else {
            this.submitSuccess = true
            this.setWithExpiry("alert", "success", 3000)
          }
          // this.alertMessageType = "success";
          return this.getAllCustomerRewardAccounts(false)
          // this.loadingOverlay = false;
        })
        .then(() => (this.loadingOverlay = false))
        .catch((error) => {
          console.log(error)
          this.afterSubmitDialog = true
          this.submitSuccess = false
        })
    },
    subtractRewards(points, customerId) {
      let pointsDTO = { points: points }
      myApi
        .subtractRewards(customerId, pointsDTO)
        .then((response) => {
          console.log(response)
          if (response.data.success === false) {
            this.setWithExpiry("alert", "error", 3000, response.data.reason)
            this.submitSuccess = false
          } else {
            this.submitSuccess = true
            this.setWithExpiry("alert", "success", 3000)
          }
          // this.afterSubmitDialog = true;
          // this.alertMessageType = "error";
          this.dialogForSubtraction = false
          return this.getAllCustomerRewardAccounts(false)
        })
        .then(() => (this.loadingOverlay = false))
        .catch((error) => {
          console.log(error)
          this.afterSubmitDialog = true
          this.submitSuccess = false
        })
    },
    addLoyaltyPoints(customer) {
      console.log("Enter Adding Loyalty Points Function")
      this.editedItem = Object.assign({}, customer)
      this.dialogForAddition = true
    },
    subtractLoyaltyPoints(customer) {
      console.log("Enter Subtracting Loyalty Points Function")
      this.editedItem = Object.assign({}, customer)
      this.dialogForSubtraction = true
    },
    filterText(value, search, item) {
      console.log({ value, search, item })
      return (
        value !== null &&
        search !== null &&
        item !== null &&
        // typeof value === "number" &&
        // item.customerId.toString().includes(search) &&
        item.phone.toString().includes(search) &&
        value.toString().indexOf(search) !== -1
      )
    },
    cancelAction() {
      this.loadingOverlay = true
      this.dialogForAddition = false
      this.loadingOverlay = false
      this.dialogForSubtraction = false
      // window.location.reload();
    },
    voucherCustomers(record) {
      console.log("Entered voucherCustomers", record)
      // this.$router.push({
      //   name: "Customer Voucher",
      //   params: {
      //     customerId: record.customerId,
      //     locale: this.$root.$i18n.locale,
      //     userType: this.userType,
      //   },
      // });
    },
    closeDialog() {
      this.isDialog = false
      console.log("closeDialog")
    },
    refreshUser() {
      this.isProgress = true;
      this.getAllCustomerRewardAccounts(true);
      this.getSetting();
    },
    getSetting() {
      this.isProgress = true;
      myApi.publicSetting().then((response) => {
        if (response.data.success == true) {
          this.isProgress = false;
          this.dateTime = response.data.data
          ["nukcafe.ethereum.rewardAccountBalanceTimeStamp"];
        }
      });
    },
  },
}
</script>

<style scoped>
.v-card-text-field {
  font-size: 20px;
}

.abc {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
