<template>
  <v-card>
    <!-- <pre><code>{{ productTableData }}</code></pre> -->
    <v-card-title>
      <b>{{ $t("product.listingTable.title") }}</b>
      <v-row justify="end" class="mr-8">
        <v-btn v-if="showAdminVision" color="primary" dark @click="goToCreateProductPage">
          {{ $t("product.listingTable.addProduct") }}
          <v-icon dark class="ml-1"> mdi-plus </v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="productTableData"
        :items-per-page="20"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :expanded.sync="expanded"
        style="cursor: pointer"
        :single-expand="singleExpand"
        item-key="id"
        :loading="runLoading"
        loading-text="Loading... Please wait"
        :search="search"
        :custom-filter="filterText"
        show-group-by
        group-desc
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50, -1],
        }"
        class="elevation-1 my-data-table"
        @click:row="myRowClickHandler"
      >
        <template v-slot:top>
          <v-text-field
            append-icon="mdi-magnify"
            v-model="search"
            :label="$t('product.listingTable.search')"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <tr v-if="item.active" :style="{ color: ' #00C853' }">
            {{
              $t("product.listingTable.column.content.productActiveStatus")
            }}
          </tr>
          <tr v-if="!item.active" :style="{ color: ' #d61515' }">
            {{
              $t("product.listingTable.column.content.productInactiveStatus")
            }}
          </tr>
        </template>

        <template v-slot:no-data> {{ $t("common.msg.emtpyTable") }} </template>

        <template v-slot:no-results>
          {{ $t("common.msg.noDataFound") }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import myApi from "../../api/api.js";
import enJson from "../../locales/en.json";
import zhJson from "../../locales/zh.json";

export default {
  props: {},
  data() {
    return {
      search: "",
      showAdminVision: false,
      productTableData: [],
      itemHistory: [],
      productItemKvPair: {
        key: null,
        value: null,
      },
      headers: [],
      sortBy: "id",
      sortDesc: true,
      expanded: [],
      singleExpand: true,
      runLoading: true,
      searchParam: "",
      categoryList: [],
    };
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
    userRole() {
      return this.$store.state.userInfo.userRole;
    },
  },
  created() {
    if (this.userRole.includes("admin")) {
      this.showAdminVision = true;
    }
  },
  mounted() {
    console.log("Start to getAllProducts");
    this.getColumnHeader();
    this.getAllCategories();
    this.getAllProducts();
  },
  methods: {
    getColumnHeader() {
      if (this.$root.$i18n.locale === "en") {
        this.headers = [
          {
            text: enJson.product.listingTable.column.headers.id,
            value: "id",
            groupable: false,
          },
          {
            text: enJson.product.listingTable.column.headers.name,
            value: "name",
            groupable: false,
          },
          {
            text: enJson.product.listingTable.column.headers.category,
            value: "categoryId",
            groupable: true,
          },
          {
            text: enJson.product.listingTable.column.headers.price,
            value: "price",
            groupable: false,
          },
          {
            text: enJson.product.listingTable.column.headers.active,
            value: "active",
            groupable: true,
          },
        ];
      }

      if (this.$root.$i18n.locale === "zh") {
        this.headers = [
          {
            text: zhJson.product.listingTable.column.headers.id,
            value: "id",
            groupable: false,
          },
          {
            text: zhJson.product.listingTable.column.headers.name,
            value: "name",
            groupable: false,
          },
          {
            text: zhJson.product.listingTable.column.headers.category,
            value: "categoryId",
            groupable: true,
          },
          {
            text: zhJson.product.listingTable.column.headers.price,
            value: "price",
            groupable: false,
          },
          {
            text: zhJson.product.listingTable.column.headers.active,
            value: "active",
            groupable: true,
          },
        ];
      }
    },
    myRowClickHandler(record) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      // console.log(index, record); // This will be the item data for the row
      console.log("Entered Edit Product Table");
      this.$router.push({
        name: "View Product",
        params: {
          productId: record.id,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    getAllCategories() {
      myApi
        .getAllCategories()
        .then((response) => {
          this.categoryList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.runLoading = false));
    },
    getAllProducts() {
      myApi
        .getAllProducts()
        .then((response) => {
          this.productsResponseDataList = response.data.data;
          // console.log(
          //   "this.productsResponseDataList: ",
          //   this.productsResponseDataList
          // );

          this.productsResponseDataList.forEach((product) => {
            let category = this.categoryList.find(
              (target) => target.id === product.categoryId
            );
            if (category !== undefined) {
              product.categoryId = category.name;
            }
            this.productTableData.push(product);
          });
          this.runLoading = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.runLoading = false));
    },
    goToCreateProductPage() {
      console.log("Entered Create Product");
      this.$router.push({
        name: "Create Product",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    filterText(value, search, item) {
      console.log({ value, search, item });
      return (
        value !== null &&
        search !== null &&
        item !== null &&
        typeof value === "number" &&
        item.id.toString().includes(search) &&
        value.toString().indexOf(search) !== -1
      );
    },
    getColor(productType) {
      if (productType === "PICKUP") return "red";
      else return "green";
    },
  },
};
</script>
