<template>
  <v-card>
    <ConfirmSubmitDialog v-model="submitDialog" @submitevent="submit" />
    <v-card-title>
      <b>{{ $t("voucher.listingTable.title") }}</b>
      <v-row justify="end" class="mr-8">
        <button
          @click="isShowSubmitDialog"
          :disabled="!voucherIds.length"
          type="button"
          class="mx-4 v-btn v-btn--contained theme--dark v-size--default primary"
        >
          <span class="v-btn__content">
            Remove voucher
            <i
              aria-hidden="true"
              class="v-icon notranslate ml-1 mdi mdi-delete theme--dark"
            ></i
          ></span>
        </button>
        <v-btn color="primary" dark @click="goToCreateVoucherPage">
          {{ $t("voucher.listingTable.addVoucher") }}
          <v-icon dark class="ml-1"> mdi-plus </v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <!-- <pre><code>{{ voucherTableData }}</code></pre> -->
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="voucherTableData"
        :items-per-page="20"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :expanded.sync="expanded"
        style="cursor: pointer"
        :single-expand="singleExpand"
        item-key="id"
        :loading="runLoading"
        loading-text="Loading... Please wait"
        :search="search"
        :custom-filter="filterText"
        show-group-by
        group-desc
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50, -1],
        }"
        class="elevation-1 my-data-table"
        @click:row="myRowClickHandler"
      >
        <template v-slot:top>
          <v-text-field
            append-icon="mdi-magnify"
            v-model="search"
            :label="$t('voucher.listingTable.search')"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:no-data> {{ $t("common.msg.emtpyTable") }} </template>

        <template v-slot:no-results>
          {{ $t("common.msg.noDataFound") }}
        </template>

        <template v-slot:item.id="{ item }">
          {{ item.id }}
        </template>

        <template v-slot:item.label="{ item }">
          {{ item.label }}
        </template>

        <template v-slot:item.priceDecreasingType="{ item }">
          {{ item.priceDecreasingType }}
        </template>

        <template v-slot:item.validityPeriodDays="{ item }">
          {{ item.validityPeriodDays }}
        </template>

        <template v-slot:item.actions="{ item }">
          <div
            @click.stop="checkDelete(item.id)"
            style="width: 100%; margin: 0 8px;height: 100%; display:flex;align-items: center;"
          >
            {{ item.actions }}
            <input
              type="checkbox"
              :name="item.id"
              :value="item.id"
              :checked="voucherIds.includes(item.id)"
            />
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import myApi from "../../api/api.js";
import enJson from "../../locales/en.json";
import zhJson from "../../locales/zh.json";
import ConfirmSubmitDialog from "../ConfirmSubmitDialogNonBlockchain.vue";

export default {
  components: {
    ConfirmSubmitDialog,
  },
  props: {},
  data() {
    return {
      search: "",
      voucherTableData: [],
      itemHistory: [],
      headers: [],
      sortBy: "id",
      sortDesc: true,
      expanded: [],
      singleExpand: true,
      runLoading: true,
      searchParam: "",
      isCheckDelete: false,
      voucherIds: [],
      submitDialog: false,
      isRemoveVoucher: [],
    };
  },
  computed: {
    username() {
      return this.$store.state.userInfo.userName;
    },
    userType() {
      return this.$store.state.userInfo.userType;
    },
  },
  mounted() {
    console.log("Start to getAllVouchers");
    this.getColumnHeader();
    this.getAllVouchers();
  },
  methods: {
    getColumnHeader() {
      if (this.$root.$i18n.locale === "en") {
        this.headers = [
          {
            text: enJson.voucher.listingTable.column.headers.id,
            value: "id",
            groupable: false,
          },
          {
            text: enJson.voucher.listingTable.column.headers.label,
            value: "label",
            groupable: false,
            delete: "delete", // TO
          },
          {
            text: enJson.voucher.listingTable.column.headers.currencyCode,
            value: "currencyCode",
            groupable: true,
          },
          {
            text:
              enJson.voucher.listingTable.column.headers.priceDecreasingType,
            value: "priceDecreasingType",
            groupable: true,
          },
          {
            text: enJson.voucher.listingTable.column.headers.validityPeriodDays,
            value: "validityPeriodDays",
            groupable: false,
          },
          {
            text: "Redeemable",
            value: "isRedeemable",
            groupable: false,
          },
          {
            text: "Deleted",
            value: "actions",
            groupable: false,
          },
        ];
      }

      if (this.$root.$i18n.locale === "zh") {
        this.headers = [
          {
            text: zhJson.voucher.listingTable.column.headers.id,
            value: "id",
            groupable: false,
          },
          {
            text: zhJson.voucher.listingTable.column.headers.label,
            value: "label",
            groupable: false,
          },
          {
            text: zhJson.voucher.listingTable.column.headers.currencyCode,
            value: "currencyCode",
            groupable: true,
          },
          {
            text:
              zhJson.voucher.listingTable.column.headers.priceDecreasingType,
            value: "priceDecreasingType",
            groupable: true,
          },
          {
            text: zhJson.voucher.listingTable.column.headers.validityPeriodDays,
            value: "validityPeriodDays",
            groupable: false,
          },
          {
            text: "Redeemable",
            value: "isRedeemable",
            groupable: false,
          },
          {
            text: "Deleted",
            value: "actions",
            groupable: false,
          },
        ];
      }
    },
    myRowClickHandler(record) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      // console.log(index, record); // This will be the item data for the row
      console.log("Entered Edit Voucher Table", this.checkDelete);
      this.$router.push({
        name: "View Voucher",
        params: {
          voucherId: record.id,
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    getAllVouchers() {
      myApi
        .getAllVouchers()
        .then((response) => {
          this.vouchersResponseDataList = response.data.data;
          this.voucherTableData = this.vouchersResponseDataList;
          this.runLoading = false;
          this.submitDialog = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.runLoading = false));
    },
    goToCreateVoucherPage() {
      console.log("Entered Create Voucher");
      this.$router.push({
        name: "Create Voucher",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
    filterText(value, search, item) {
      console.log({ value, search, item });
      return (
        value !== null &&
        search !== null &&
        item !== null &&
        typeof value === "number" &&
        item.id.toString().includes(search) &&
        value.toString().indexOf(search) !== -1
      );
    },
    checkDelete(id) {
      const idx = this.voucherIds.findIndex((item) => item == id);
      idx !== -1 ? this.voucherIds.splice(idx, 1) : this.voucherIds.push(id);
    },
    async removeVoucher() {
      const newArr = [...new Set(this.voucherIds)];
      await myApi.removeVoucher(newArr).then(() => {
        this.voucherIds = [];
        this.getAllVouchers();
      });
    },
    submit() {
      this.removeVoucher();
    },
    isShowSubmitDialog() {
      if (this.isCheckDelete == true) {
        this.submitDialog = false;
      } else {
        this.submitDialog = true;
      }
    },
  },
};
</script>
<style>
button:disabled {
  opacity: 60%;
}
</style>
