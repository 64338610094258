import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ],
  state: {
    userInfo: {},
    errorAlertDialog: {
      open: false,
      errMsg: '',
    },
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    openErrorAlertDialog(state, errMsg) {
      state.errorAlertDialog = {
        open: true, errMsg,
      };
    },
    closeErrorAlertDialog(state) {
      state.errorAlertDialog = {
        open: false, errMsg: '',
      };
    },
  },
  actions: {},
  modules: {}
});
