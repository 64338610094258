<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-title>
          <b>{{ $t("news.create.title") }} </b>
        </v-card-title>

        <v-card-text class="ml-4">
          <v-row justify="end" class="mr-8">
            <v-btn
              color="primary"
              outlined
              class="mr-4"
              dark
              @click="goToShowNewsTablePage"
            >
              {{ $t("common.btn.back") }}
            </v-btn>
          </v-row>

          <br />

          <!-- Starting Content of the Create Form  -->
          <v-file-input
            :label="$t('news.create.input.general.imageURL')"
            filled
            show-size
            counter
            clearable
            v-model="news.image"
          ></v-file-input>

          <v-text-field
            shaped
            outlined
            v-model="news.sortingOrder"
            :label="$t('news.create.input.general.sortingOrder')"
            required
          ></v-text-field>

          <v-checkbox label="Active" v-model="news.active"> </v-checkbox>
          <br />

        </v-card-text>
      </v-card>
      <br />

      <v-row justify="end" class="mr-8">
        <v-btn
          color="primary"
          outlined
          dark
          @click="goToShowNewsTablePage"
          class="mx-2"
        >
          {{ $t("common.btn.cancel") }}
        </v-btn>
        <v-btn color="primary" dark @click.stop="submitDialog = true">
          {{ $t("common.btn.save") }}
        </v-btn>

        <ConfirmSubmitDialog v-model="submitDialog" @submitevent="submit" />
      </v-row>
    </v-form>

    <v-overlay :value="loadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <AfterSubmitDialog
      v-model="afterSubmitDialog"
      :submitSuccess="submitSuccess"
      :errMsg="errMsg"
    />
  </v-container>
</template>

<script>
import myApi from "../../api/api.js";
import AfterSubmitDialog from "../AfterSubmitDialog.vue";
import ConfirmSubmitDialog from "../ConfirmSubmitDialogNonBlockchain.vue";

export default {
  components: {
    AfterSubmitDialog,
    ConfirmSubmitDialog,
  },
  props: [],
  data() {
    return {
      loadingOverlay: false,
      afterSubmitDialog: false,
      submitSuccess: false,
      submitDialog: false,
      news: {
        image: "",
        active: true,
        sortingOrder: 0,
      },
      validationRules: {
        active: [(v) => !!v || this.$t("news.edit.error.active")],
      },
      errMsg: this.$t("common.msg.error.create.msg"),
    };
  },
  computed: {
    userType() {
      return this.$store.state.userInfo.userType;
    },
  },
  mounted: {},
  methods: {
    isFormValid() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    submit() {
      if (this.isFormValid()) {
        this.loadingOverlay = !this.loadingOverlay;
        this.submitDialog = false;
        this.createNews();
      } else {
        this.submitDialog = false;
        this.afterSubmitDialog = true;
      }
    },
    createNews() {
      let newsFormData = new FormData();
      newsFormData.append("active", this.news.active);
      newsFormData.append("sortingOrder", this.news.sortingOrder);
      if (this.news.image != null && this.news.image !== undefined) {
        newsFormData.append("image", this.news.image);
      }

      console.log("Created this.news, ", this.news);

      myApi
        .createNews(newsFormData)
        .then(() => {
          this.submitSuccess = true;
          this.afterSubmitDialog = true;
          this.resetValues();
          this.goToShowNewsTablePage();
        })
        .catch(() => {
          this.afterSubmitDialog = true;
          this.submitSuccess = false;
          this.loadingOverlay = false;
        });
    },
    resetAction() {
      this.loadingOverlay = true;
      window.location.reload();
    },
    resetValues() {
      this.loadingOverlay = false;
      this.resetValidation();
    },
    goToShowNewsTablePage() {
      console.log("Entered Show News");
      this.$router.replace({
        name: "Show News Table",
        params: {
          locale: this.$root.$i18n.locale,
          userType: this.userType,
        },
      });
    },
  },
};
</script>

<style scoped>
.detailsTitle {
  text-align: left;
  margin-top: 20px;
  font-size: 30px;
  background-color: lightgray;
}

.form {
  margin-top: 20px;
}

.submitBtn {
  margin-top: 20px;
}

::v-deep .v-text-field {
  max-width: 95%;
}
</style>
